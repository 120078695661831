.awssld__wrapper {
    height: 400px!important;
}

        .slider_caption{
            @include position-absolute(auto,auto,0,0);
            width: 100%; 
            height: 55px;
            overflow: hidden;
            background-color: rgba(0,0,0,.6);
            color: $white;
            padding: 5px;
            p{
                margin: 0;
            }
            h3{
                margin: 0;
            }
        }

.slider_wrapper {
    .slick-slider{
        margin-bottom: 0;
    }
    .slick-prev, .slick-next{
        @include slick-arrow(40px,40px,transparent, 40%);
    }
    .slick-prev::before {
        content: '\f104';
    }
    .slick-next::before {
        content: '\f105';
    }
    .slick-item {
        height: 400px!important;
        @include flex-center(300px);
        background-color: $white;
        position: relative;
        img {
            width: 100%;
            height: 100%;
        }
        .slider_caption{
            @include position-absolute(auto,auto,0,0);
            width: 100%; 
            height: 55px;
            overflow: hidden;
            background-color: rgba(0,0,0,.6);
            color: $white;
            padding: 5px;
            p{
                margin: 0;
            }
            h3{
                margin: 0;
            }
        }
        &:nth-child(even){
            .slider_caption{
                text-align: right;
            }
        }
    }
    .slider_header h3 {
        margin: 0;
        padding: 15px;
    }
    .slick-prev {
        left: 0;
    }
    .slick-next {
        right: 0
    }
    
    .slider_body {
        position: relative;
        &:after {
            content: "";
            display: block;
            width: 300px;
            height: 100%;
            @include position-absolute(1%,0,auto,0);
            z-index: 9;
            margin: 0 auto;
            /*background-image: url('../assets/images/watermark1.png');*/
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center center;
        }
    } 
}

.list_row .slick-initialized .slick-list .slick-slide {
    display: block;
    border: 6px solid #dedede;
    border-radius: 6px;
    margin-right: 5px !important;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.16);
    overflow: hidden;
}

.slick-slider .slick-track, .slick-slider .slick-list {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}
.slider_wrapper .slick-prev, .slider_wrapper .slick-next{
    z-index:3;
}

@media (max-width:767px){
    .slider_wrapper .slick-item {
        height: 180px!important;
    }

}