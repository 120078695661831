.segment_container {
    width: 25% !important;
}
.cityIcon {
    height: auto;
    -webkit-text-fill-color: #21E1E1;
    flex-direction: column;
}
.segmentHead {
    padding-bottom: 56px!important;
}
.seg_back {
    display: flex;
    align-items: center;
    padding-top: 46px;
}
.segmentLogo {
    margin: 14px 0 -75px 20%;
}
.dart {
    max-width: 14%;
    margin: 0px 24px 75px -66px;
}
.dartt {
    max-width: 15%;
    margin: 0px 24px 75px -67px;
}

.numberCity {
    color: white;
    font-size: 22px;
}
.cityIconDart {
    height: auto;
    -webkit-text-fill-color: #21E1E1;
    flex-direction: column;
    padding-bottom: 10px;
}
.heartIcon {
    color: #21E1E1 !important;
    font-size: 100px;
    margin-top: -12px;

}
.dartIcon {
    padding-top: 30px;
}
.boldHeading {
    font-weight: bold;
    font-size: 27px;
}
.segment_box {
    background-color: #84644c;
    padding: 7px;
}

@media (max-width: 767px){

.segment_container {
    width: 50% !important;
    margin-left: 4px;
    margin-right: -12px;
}
.segmentHead {
    padding-bottom: 15px!important;
}
.segmnt-icon{
    height: 65px;
    font-size: 35px !important;
}
.market_seg {
    display: block;
    margin: 15px 0 0 0;
}
.seg_back {
    padding-top: 0px!important;
}
.segmntBackBtn {
    margin: 41px 0 0 -14px;
}
}



@media (min-width:1224px) and (max-width:1980px){
 .mrgin_top{
 margin-top:-45px !important;
 }   
 

}