

.react-multi-carousel-item{
    width: 181px!important;
    max-height: 310px!important;
    align-items: center!important;
    height: 230px!important;
    padding: 7px!important;
}

.react-multi-carousel-item div img{
    height: 230px!important;
    max-height: 310px!important;
}