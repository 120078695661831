@media (max-width: 767px){
	.featuredMenu .navbar-header{
		top: -110px;
	}
	.whiteSpacefeatured{
		height:110px;
	}
	.featuredSearch {
		top: -202px;
	}
}