.filter_barfixed {
    position: fixed;
    left: 0;
    right: 0;
    top: 146px;
    z-index:12;
}
.Protop_padd {
    margin-top: 220px;
}
.quantityIpp {
    font-size: 25px !important;
    font-weight: bolder !important;

}
.ReadMoreLessBtn {
    font-weight: bold!important;
    border: none !important;
}
.ReadMoreLessBtn:focus {
    box-shadow: none!important;
    border: none;
}
.AdditionalInfobody {
    overflow: auto !important;
}
.input-group {
    cursor: pointer;
}
.no_wrap {
    cursor: pointer;
}
.tax_class {
    height: 14px;
}
.readMoreText {
    font-weight: bolder;
    color: #232f3e;
}

.tax_classNew {
    font-weight: 1000;
    text-transform: uppercase;
    color: #232f3e;
    letter-spacing: .7px;
    text-align: center;
    margin: 24px 0 25px;
}

.tax_classNew {
    font-size: 11px;
}

.textRight {
    text-align: right!important;
}

.fade:not(.show) {
    opacity: 1!important;
}

.modal-backdrop {
    position: relative!important;
}

.modal-content{
    top: 104px!important;
     font-family: 'Trebuchet MS', sans-serif;
}

.heading_txt {
    width: 100px;
    height: 100px;
    background-color: #bbb;
    border-radius: 50%;
    padding: 1% 1% 1% 1%;
}


.input-group-addon {
    padding: 6px 12px;
    font-size: 14px;
    font-weight: 400;
    line-height: 1;
    color: #555;
    text-align: center;
    background-color: #eee;
    border: 1px solid #ccc;
    border-radius: 4px;
}
.goBackBtn {
    color: #232f3e!important;
    font-weight: bold!important;
}

.catText {
    color: #03a9f5!important;
    font-weight: bold!important;
}
.subCatText {
    color: #30a9f5!important;
}

.breadcrumb li a {
    font-weight: 600;
    color: #03a9f5;
    text-transform: uppercase;
    vertical-align: middle;
    display: inline-block;
    transition: .5s ease-in-out;
}
.breadcrumb.padd_15{
    padding: 0px;
    margin-bottom: 0px !important;
}
.breadcrumb {

    margin: 0 0 10px;
	background-color: #f5f5f5;
    border-radius: 4px;
}
.breadcrumb li a {
    font-weight: 600;
    color: #03a9f5;
    text-transform: uppercase;
    vertical-align: middle;
    display: inline-block;
    transition: ease-in-out .5s;
	
}
.breadcrumb li {
    text-transform: uppercase;
    font-weight: 600;
    color: #272424!important;
    display: inline-block;
	font-size:14px;
}
.breadcrumb li a:hover{
  color: #07326c;
}
.breadcrumb li{
  text-transform: uppercase;
  font-weight: 600;
  color: #272424;
}
.breadcrumb li a i {
    font-size: 18px;
    display: inline-block;
    vertical-align: middle;
}
.breadcrumb>li+li:before{
    padding: 0 5px;
    color: #272424;
}
.breadcrumb .breadcrumb{
  display: inline-block;
      margin: 0;
}
.pro_imgbox, .pro_sidebar {
    background: #fff;
    border-radius: 5px;
}
.pro_main_img {
    overflow:hidden;
}

.pro_head {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    font-size: 15px;
    text-transform: uppercase;
    color: #232f3e;
    font-weight: bold;
    padding: 20px 15px;
    margin: 0;
}
.tooltipIPPVendor{
    bottom : 34px !important;
}
.pro_detail_sec .produt_active>a {
    background: linear-gradient(#0382bc,#1eb5fa);
    background: -moz-linear-gradient(#0382bc,#1eb5fa);
    color: #fff;
    margin: 0;
}

.pro_thumb li {
    width: 14.2857143%;
    float: left;
    padding: 0 3px;
        display: table;
}
.pro_thumb li a {
    border-radius: 6px;
    border: 6px solid #f9f9f9;
    display: table-cell;
    height: 98px;
    overflow: hidden;
    vertical-align: middle;
    background: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
}
.pro_thumb li a:hover{
border-color: #17a7e9;
}
.pro_thumb .active{
  border-color: #17a7e9;
}
.pro_thumb {
    margin: 15px 0;
}
.pro_thumb .list-unstyled {
    margin-left: -3px;
    margin-right: -3px;
}
.des_head {
    text-transform: uppercase;
    color: #232f3e;
    font-size: 15px;
    font-weight: bolder;
    letter-spacing: .5px;
    margin: 0 0 5px;
}

.des_txt {
    color: #0c0c0c;
    font-weight: 400;
    letter-spacing: .2px;
    line-height: normal;
    margin-bottom: 0;
}
.pro_des .read {
    color: #17a7e9;
    font-weight: 500;
    text-decoration: underline !important;
}
.pro_des .read:hover{
      color: #07326c;
}
.pro_des {
    margin-bottom: 20px;
}
.pro_detail_sec {
    margin: 25px 0;
}

.pro_detail_sec .panel-title>a {
    display: block;
    padding: 18px 15px;
    color: #222;
    font-size: 16px;
    letter-spacing: 0.2px;
    width: 100%;
    text-align: left;
}
.pro_detail_sec .panel-heading {
    box-shadow: 0 0 7px rgba(0, 0, 0, 0.10);
    border-radius: 3px;
    padding: 0;
}
.rprtText {
    font-size: 16px;
    font-weight: bold;
    color: #232f3e;
}
.panel-body {
    background: #fff;
    margin-top: 3px;
    padding: 20px 15px;
    border-radius: 3px;
    box-shadow: 0 0 7px rgba(0, 0, 0, 0.10);
    border: none !important;
    position: relative;
    z-index: 5;
}
.Chart_box h2{
    color: #222;
    font-size: 16px;
    letter-spacing: 0.2px;
    margin: 0 0 20px;
}
.panel.panel-default {
    background: none !important;
}
.collapse .in {
    display: block;
    margin-bottom: 12px;
}

.new_tab{
    max-height: 454px;
    overflow: scroll;
}
.pnlbody_head{
color: #1f2223;
font-size: 16px;
margin: 0 0 10px;
font-weight: 500;
}
.measure_ment li {
    width: 50%;
    float: left;
    font-weight: 500;
    color: #32373a;
    padding: 4px 0;
}
.measure_ment .semi{
  color: #32373a;
}
.panel-body {
    min-height: 160px;
}
.orng_txt {
    color: #f57a00;
}
.orng_txt i{
  font-size: 16px;
  margin-left:-3px;
}
.orng_txt .grey{
  color: #c2bdb9;
}
.reviews_sec .pnlbody_head{
  font-size: 16px;
  color: #232f3e;
  font-weight: bold;
}
.reviews_sec .pnlbody_head .orng_txt {
    margin-left: 6px;
}
.review_head .blue_txt {
    letter-spacing: 1.5px;
}
.review_head {
    margin-top: -5px;
    padding-bottom: 5px;
}
.review_row1 .orng_txt {
    margin: 0 3px 0 0 !important;
}
.review_row1 .pnlbody_head {
  text-transform: uppercase;
  color: #1f2223;
  font-weight: 600;
  padding: 10px 4px 6px;
  margin: 0;
}
.text_grey {
    color: #5a5a5a;
    text-transform: none;
    font-weight: 500;
    font-size: 13px;
}
.ltr_spacing{
  letter-spacing: 1px;
}
.review_txt{
  font-size: 13px;
  color: #1f2223;
}
.review_row1 {
    border-top: 1px solid #eef1f6;
}

.vendor_detail .list-unstyled li {
    display: block;
    font-size: 15px;
    color: #1f2223;
    letter-spacing: .3px;
    padding: 4px 0;
}

.vendor_detail .list-unstyled li span {
    font-weight: 500;
}
.rating {
    letter-spacing: -1px;
}
.rating i{
  color: #f57a00;
}
.rating .grey{
  color: #c2bdb9;
}
.related_product {
    background: #f9fcff;
    padding:23px 15px;
    border-radius: 4px;
    box-shadow: 0 0 13px rgba(0, 0, 0, 0.06);
    margin: 35px 0;
}
.related_heading {
    margin: 0;
    color: #222222;
    font-weight: 500;
    font-size: 16px;
    margin-bottom: 15px;
}

.rlatd_pro {
    background: #ececf6;
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.18);
    border-radius: 4px;
    overflow: hidden;
    height: 189px;
    width: 100%;
    display: table;
    text-align: center;
        position: relative;
}
.rlatd_pro .rlted_pro {
    max-height: 138px;
    transition: ease-in-out .5s;
    -webkit-transition: ease-in-out .5s;
    -moz-transition: ease-in-out .5s;
    -ms-transition: ease-in-out .5s;
}
.rlatd_pro {
    margin-bottom: 12px;
}
.cell:hover .rlted_pro{
  transform:scale(1.1,1.1);
  -webkit-transform:scale(1.1,1.1);
  -moz-transform:scale(1.1,1.1);
  -m-transform:scale(1.1,1.1);

}


/*============= Product Sidebar css start here  =============*/

.padd_15{
  padding: 15px;
}
.sidebar_head{
  font-weight: bolder;
  font-size: 16px;
  margin: 0px 0 5px;
}
.sidebar_head:hover{
   color: #232f3e !important;
}
.lowest_price{
  font-size: 15px;
}
.lowest_price {
    font-size: 15px;
    margin: 15px 0 10px;
}
table {
    width: 100%;
}
.table_box  .first >td {
    padding: 3px 2px;
    color: #181b1c;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0.7px;
}
.table_box .first > td:first-child {
    max-width: 15px;
}
.check_boxbtbn {
    background: linear-gradient(#fefefe,#e5e7e9);
    padding: 11px 10px;
    border-radius: 4px;
    letter-spacing: 1px;
    font-size: 14px;
    color: #272829;
    font-weight: 600;
    text-shadow: 1px 1px 1px #fff;
    display: block;
    text-align: center;
    text-transform: uppercase;
    box-shadow: 0 1px 3px rgba(0,0,0,.3);
}
.check3:checked ~.check_boxbtbn{
  background: -webkit-linear-gradient(#1bb0f4, #017fb9 );
  color: #fff;
  text-shadow: 1px 1px 1px #00567d;
}
.custom_check .check3{
    display: none;
}
.info i {
    font-size: 22px;
    color: #1bb0f4;
}
.info_box {
    display: table;
    width: 100%;
    padding: 5px 0;
    border-bottom: 1px solid #f0f3f7;
}
.info_Last_box{
    border-bottom:none;
}
.info_box>div{
    display: table-cell;
    vertical-align:middle;
    float:none;
}
.info_txt {
    font-size: 13px;
    color: #101213 !important;
    font-weight: 500;
    margin:0;
}

.dark_ntn {
    font-size: 16px;
    padding:16px;
}
.dark_ntn i{
    font-size: 20px;
    display: inline-block;
    vertical-align: middle;
}
.darkcyan{
    color:#fff;
    background: linear-gradient(#21E1E1, #232f3e);
    background: -webkit-linear-gradient(#21E1E1, #232f3e);
    background: -ms-linear-gradient(#21E1E1, #232f3e);
    background: -moz-linear-gradient(#21E1E1, #232f3e);

}
.darkcyan:hover{
    color:#fff;
    background: linear-gradient(#232f3e, #21E1E1);
    background: -webkit-linear-gradient(#232f3e, #21E1E1);
    background: -ms-linear-gradient(#232f3e, #21E1E1);
    background: -moz-linear-gradient(#232f3e, #21E1E1);
}
.add_section {
    margin-top:15px;
    min-height:300px;
    background: #f2f2f2;
}
.input-group-addon i {
    color: #fff;
}
.input_group{
   margin: 6px 0;
  position: relative;
    display: table;
    border-collapse: separate;
}

.check_boxbtbn.active {
    background: linear-gradient(#232f3e, #21E1E1);
    background: -moz-linear-gradient(#232f3e, #21E1E1);
    color: #fff;
    text-shadow: none;
} 


.produt_active{
    
}
.tooltiptext_box.tooltip-top {
width: max-content;
}

.produt_active a.collapsed {
    background: #098ec9;
    color: #fff;
}
.pro_detail_sec .panel-heading {
    padding: 0px;
}


.pro_detail_sec .panel-title > a {
    background: none;
    color: #232f3e;
    border-left: none;
    text-transform:uppercase;
    font-weight:bolder;
    font-size:15px;
    letter-spacing:.5px;
}
.pro_detail_sec  .produt_active >a {
    background: #1bb0f4;
    color: #fff;
    margin: 0px;
}
.panel-default > .panel-heading {
    padding: 0 !important;
}
.rlatd_pro .vendor_product {
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
	padding: 5px;
    background: hsl(0deg 0% 0% / 63%);
    z-index:1;
	font-size: 12px;
    line-height: 16px;
    color: #fff;
    white-space: nowrap;
}

.rlatd_pro .cell {
    display: table-cell;
    vertical-align: middle;
    position:relative;
}

span.close_icon {
    position: absolute;
    right: 13px;
    top: 11px;
}

.overflow_hidden {
    overflow: hidden;
}
.pro_detail_sec .produt_active > a.collapsed:before {
    color: #fff;
}
.button{
    background: #00ff00;
}
.Header_add .main_header {

}

.list_row .section_img_product .slick-initialized .slick-list .slick-slide {
    display: table-cell !important;
    vertical-align: middle !important;
    float: none;
}
.list_row .section_img_product .main_slider .slick-track {
    display: table;
}
.list_row slick.main_slider.main_ .slick-item {
    border: none;
}

.pro_sidebar .info_box1 .blue_btn {
    display: flex;
    align-items: center;
    justify-content: center;
}

.pro_main_img .box{
	display: flex;
	margin-top: 0 !important;
}
.pro_main_img .box div:first-child {
    width: 100%;
	margin:0;
}
.pro_main_img .box .thumbnails {
order: -1;
display: block;
width: 110px;
margin-top:0;
padding: 5px;
}
body {
    background : url('data:image/jpeg;base64,/9j/4QAYRXhpZgAASUkqAAgAAAAAAAAAAAAAAP/sABFEdWNreQABAAQAAAA8AAD/7gAOQWRvYmUAZMAAAAAB/9sAhAAGBAQEBQQGBQUGCQYFBgkLCAYGCAsMCgoLCgoMEAwMDAwMDBAMDg8QDw4MExMUFBMTHBsbGxwfHx8fHx8fHx8fAQcHBw0MDRgQEBgaFREVGh8fHx8fHx8fHx8fHx8fHx8fHx8fHx8fHx8fHx8fHx8fHx8fHx8fHx8fHx8fHx8fHx//wAARCAAsACwDAREAAhEBAxEB/8QAbAABAQEBAQAAAAAAAAAAAAAAAgMBAAgBAQEBAAAAAAAAAAAAAAAAAAEAAhAAAQMDBAECBAcAAAAAAAAAAQARAiExEkEiAxNRYZHwcYFSoTJCcjMEFBEBAQEBAQAAAAAAAAAAAAAAAAERMRL/2gAMAwEAAhEDEQA/APTcwRzAmLsS0vCQ3vJkGDg0k/lVilbxglyARFiAFIIS65SJji9yNQkF25ZCQeNDFBbjLret3UXQ5Mtp3teQt8gqiHjC930F0FOXMRLAHACtfiq1ILSjITqQQPu8+qE3GMbB/QWQU/8ATJ30dsdfZaxnVZSBjsYAFiPCCEpHrBDA1Z/oqJQSiYAziHFTqykPJJ6isdGURnKQ5Ri1bi3soK7HyYfvZBRjw08Alz4TowpHhkOv8dFIDxFgH22I0IVqwo8cYsZFgBQILZR4uXcLg0SB6p5XLfbqnR5MmMpmEjuLsyCI/ryAYz2elCfmrVhxkz4mjIIYQ5QQJNIGuvung63qx3cknawFArVhZSxZ6eEFM/zVa5xe7pCh7M4tZt7qQxbI+MaNZSZw/nlbLVrsqqFv3ZNg+11IP0au6k//2Q==')
!important;
}


.noti_spn.drop_value{
	min-width: 20px !important;
    display: inline-block;
	margin: 0 5px;
	width: 40px;
    height: 21px;
    padding: 2px 5px;
}
.thumbnail{
	margin: 5px 0px !important;
}

div#exampleModalCountry {
    z-index: 99999999999 !important;
    padding: 50px 0;
}


.pro_detail_sec.wht_box .panel.panel-default {
    margin-bottom: 7px;
}
.pro_detail_sec.wht_box .panel.panel-default .panel-body {
    max-height: 320px;
    overflow: hidden;
}

.contectClass{
    text-align: right;
}

.pro_main_img .carousel .slider-wrapper.axis-horizontal .slider .slide img {
    max-height: 400px !important;
    width: auto;
    height: auto;
    max-width: 100% !important;
}
.pro_main_img .carousel .slider-wrapper.axis-horizontal .slider .slide >div {
    max-height: 400px;
}


@media (max-width:1199px){
    .pro_thumb li a{
        height:75px;
    }   
    .tax_class{
        height: 0[x];
    }
}
@media (max-width:991px){
    .check_boxbtbn{
        font-size: 13px;
        font-weight: 500;
    }
    .btn_dflt {
        font-size: 13px;
    }
    .pro_thumb li a{
        height:60px;
    }

   .ProductContent .tooltiptext_box.tooltip-top {
        left: 23px !important;
    }
}

@media (max-width:767px){

.Header_add .navbar-header {    
    top: -63px;
}
.input-group-addon i {
    margin-top: 0px;
}

.newHtml table td{
    max-width: 20%!important;
    width: 35px;
    word-break: break-all;
}

.Top_Bar {
    padding: 5px 0px;
    margin-bottom: 35px;
}

.main_content.ProductContent {
    margin-top: 20px !important;
}
    .pro_thumb li a {
        height: 80px;
    }
    .pro_thumb li {
        width: 20%;
    }
    .pro_thumb .list-unstyled li:nth-child(6), .pro_thumb .list-unstyled li:nth-child(7){
        display:none;
    }
    .Chart_box {
        padding-top: 15px;
    }
    .review_head>div>div {
        text-align: left;
    }
    .filter_barfixed {
        position: static;
        margin-top: 220px;
    }
    .Protop_padd {
        margin-top: 10px;
    }
    .rlatd_pro{
        height:225px;
    }
}

@media (max-width:425px){
    .pro_thumb .list-unstyled li:nth-child(5), .pro_thumb .list-unstyled li:nth-child(6), .pro_thumb .list-unstyled li:nth-child(7){
        display:none;
    }
    .pro_thumb li {
        width: 25%;
    }
    .pro_thumb li a {
        height: 70px;
    }
    .measure_ment li {
        width: 100%;
    }
    .breadcrumb li {
        font-weight:500;
        font-size: 12px;
    }
    .filter_barfixed {
        margin-top: 280px;
    }
}