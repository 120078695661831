/* ===== Home Page CSS Start here ===== */
.banner_box{
    height: 447px;
    background-size: cover;
    background-position: center;
    padding: 35px 0 0;
}
.img-magnifier-container {
  position:relative;
}
.refresh_icon{
    align-items: center;
    padding-top: 12px;
}
.img-magnifier-glass {
  position: absolute;
  border: 3px solid #000;
  cursor: none;
  /*Set the size of the magnifier glass:*/
  width: 100px;
  height: 100px;
  }
  .Grid_Description .vendor_txt {
    color: #232f3e;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-transform: uppercase;
    display: block;
    font-weight: bolder;
  }
.loader_Ad {
    width: 50px;
    height: 20px;
    align-content: center;
    margin: 0px;
    padding: 0px;
}
.thumb_hidden{
	position:relative;
    overflow: hidden;
    opacity: 0.99999;
}
.out_stock {
    display: table;
    width: 100%;
    height: 100%;
}

.voice_serch {
    font-size: 25px;
    padding: 5px 10px;
}

.Form_box .navbar-form .fa-microphone {
    font-size: 29px;
    padding: 0 8px;
}

.voice_serch i.fa-microphone {
    background: linear-gradient(#21E1E1,#232f3e);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.navbar-form .input-group {
    position: static;
    width: 77%;
}

.input-group {
    position: relative;
    display: table;
    border-collapse: separate;
}

.tax_class .tax_classNew {
    font-weight: 1000;
    text-transform: uppercase;
    color: #0e98d7;
    letter-spacing: .7px;
    line-height: 19.24px;
    text-align: center;
    margin: 24px 0 25px;
}

.tax_class {
    font-size: 17px;
}

.input-group:not(.has-validation)>.dropdown-toggle:nth-last-child(n+3), .input-group:not(.has-validation)>:not(:last-child):not(.dropdown-toggle):not(.dropdown-menu) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.dataWidth{
	width: 45px;
}


.dataBorder{
	border: 3px solid #FFA500!important;
}

.Form_box .navbar-form, .grid_icon {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.input-group .form-control {
    position: relative;
    z-index: 2;
    float: left;
    width: 100%;
    margin-bottom: 0;
}

.Form_holder .form-control {
    border: 2px solid #232f3e;
    border-radius: 0 0 2px 2px;
    width: 100%;
}

select.form-control {
    background: #f8fafc url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAGCAYAAAD68A/GAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAA3FpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNS1jMDE0IDc5LjE1MTQ4MSwgMjAxMy8wMy8xMy0xMjowOToxNSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wTU09Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9tbS8iIHhtbG5zOnN0UmVmPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvc1R5cGUvUmVzb3VyY2VSZWYjIiB4bWxuczp4bXA9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC8iIHhtcE1NOk9yaWdpbmFsRG9jdW1lbnRJRD0ieG1wLmRpZDpjZDUwZTcwMS00YTAxLTlhNGEtYjcyZi1kYTRiZmMzZGM4MDMiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6NEFBODMzMjVGM0QwMTFFOEJERkE4MjI5REIyRjNGMjEiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6NEFBODMzMjRGM0QwMTFFOEJERkE4MjI5REIyRjNGMjEiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIChXaW5kb3dzKSI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuaWlkOjVmZTg5NzVlLTNkZTEtYTY0ZS05MmMwLWY5ZWU5Njg4NjA3YSIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDpjZDUwZTcwMS00YTAxLTlhNGEtYjcyZi1kYTRiZmMzZGM4MDMiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz5XddKSAAAAW0lEQVR42mKUVVCaxsDAkMmAH0xnAhK5QLwPjyKQXB5I4V8gDgLiO1gUgcSCgfgPE1TgIxB7QmkGJDEvIP4A4jCh6Q6C2vAXatJtmCQLFveA3MwIxHuRJQACDAC+FRNF9xw3QAAAAABJRU5ErkJggg==) no-repeat;
    background-size: 10px;
    background-position: right 10px center;
}


.Form_box {
    width: 100%;
    background: #d5dce1;
    padding: 10px 10px 10px 10px;
    box-shadow: 0 0 11px rgb(0 0 0 / 30%);
    border-radius: 4px;
}

.stock_secll {
    display: table-cell;
    vertical-align: middle;
}
.stock_con {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    position: absolute;
    background: hsla(0,0%,48%,.56);
}

.grid_icon {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding-top: 12px;
}
.out_stockbtn {
    background: #fff;
    color: #dc1010;
    font-weight: 700;
    font-size: 13pt;
    border: 1px solid #00a0e3;
    padding: 9px;
    border-radius: 0 0 4px 4px;
    display: block;
    text-align: center;
}

.Grid_Description>.Ptxt_box span.Direct_txt.membersin {
    font-size: 13px!important;
    font-weight: 600!important;
    color: #373737!important;
}

.Grid_Description>.Ptxt_box {
    padding: 5px 12px!important;
    border-top: 1px solid #ccc!important;
    margin-top: 5px!important;
    background: #ececec!important;
    margin-left: -1px!important;
    margin-right: -1px!important;
    text-align: center!important;
    font-weight: 800!important;
}

.Grid_Description .Ptxt_box {
    margin: 0;
    font-size: 14px;
    color: #484c4e;
    letter-spacing: .6px;
    line-height: 20.24px;
}

.featured_secll {
	position: absolute;
    top: 21px;
    left: -44px;
	z-index:9;
    transform: rotate(-45deg);
}
.featured{
    background: #dc1010;
    background: -webkit-linear-gradient(#af0202, #dc1010);
    background: linear-gradient(#af0202, #dc1010);
    background: -ms-linear-gradient(#af0202, #dc1010);
    background: -moz-linear-gradient(#af0202, #dc1010);
    color: #fff !important;
    width: 165px;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.8px;
    padding: 9px;
    display: block;
    text-align: center;
    box-shadow: 0 1px 4px rgba(154, 11, 11, 0.50);
}
.product_sale {
    bottom: 5px;
    left: 0;
    right: 0;
    z-index: 9;
    position: absolute;
}
.product_out {
    background: #dc1010;
    background: -webkit-linear-gradient(#af0202, #dc1010);
    background: linear-gradient(#af0202, #dc1010);
    background: -ms-linear-gradient(#af0202, #dc1010);
    background: -moz-linear-gradient(#af0202, #dc1010);
    color: #fff !important;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.8px;
	padding: 5px 9px;
    display: block;
	position:relative;
    box-shadow: 0 1px 4px rgba(154, 11, 11, 0.50);
}
.product_out:after {
	content: '25%';
    position: absolute;
    top: -7px;
    background: #dc1010;
    background: -webkit-linear-gradient(#af0202, #dc1010);
    background: linear-gradient(#af0202, #dc1010);
    background: -ms-linear-gradient(#af0202, #dc1010);
    background: -moz-linear-gradient(#af0202, #dc1010);
    padding: 5px 15px;
    right: 50px;
    font-size: 22px;
    font-weight: 500;
    letter-spacing: 3px;
}
.product_out .sale_off {
    float: right;
}
/* ===== Home Page CSS Start here ===== */

.main_heading {
    font-size: 36px;
    font-weight: 600;
    color: #232f3e;
    letter-spacing: .7px;
    line-height: 19.24px;
    text-align: center;
    margin: -11px 0 25px;
    text-shadow: 2px 2px 2px rgba(0,0,0,.5);
    text-transform: uppercase;
}
.banner_txt {
    background: #d5dce1;
    border-radius: 5px;
    text-align: center;
    padding: 14px 15px;
    box-shadow: 0 0 11px rgba(0,0,0,0.22);
}
.banner_txt h2 {
    font-size: 24px;
    font-weight: 600;
    text-transform: uppercase;
    color: #232f3e;
    letter-spacing: 0.7px;
    line-height: 19.24px;
    text-align: center;
    margin:10px 0 25px;
    cursor:pointer;
}
.white_area {
    background: #fff;
    border-radius: 5px;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15);
    height:238px;
    display: table-cell;
    width: 100%;
    vertical-align: middle;
}
/* images Slider Css Start*/
.Images_slide .slide_table {
    min-width: 860px;
}
.Images_slide {
    overflow: auto;
    padding-bottom: 10px;
}
.Images_slide .slide_table .slide_img{
	padding: 3px;
    width: 16.33333%;
    float: left;
}
/* images Slider Css Start*/

/* ===== Grid View CSS Start here ===== */
.Grid_Boxes {
    padding: 10px 0;
}
.Grid_Box {
	background: #fff;
    box-shadow: 0 0 13px rgba(0, 0, 0, 0.05);
    border-radius: 3px 3px 10px 10px;
    margin: 8px 0;
    border: 2px solid #232f3e;
}
.Grid_Boxes .Grid_col{
    padding: 0 8px;
}
.Grid_Box .Grid_txt {
	padding: 0 12px;
    height: 275px;
    overflow: hidden;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
.MainGrid_txt {
    font-size: 15px;
    font-weight: bold;
    text-transform: uppercase;
    color: #181b1c;
    letter-spacing: 0.7px;
    line-height: 20.24px;
    margin: 0;
    padding: 20px 12px;
    box-shadow: 0 1px 0 rgba(0, 0, 0, 0.16);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.Gloves_img img{
	max-width:195px;
}
.Sofa_img, .Gloves_img{
    display: block;
    text-align: center;
}
.Grid_Description {
    background: #f7faff;
    padding: 15px 0 0;
    border-radius: 0 0 7px 7px;
}
.desc_section .Descript_row {
    margin: 0;
}
.Grid_Description .Descript_row {
    margin: 0 7px;
    min-height: 105px;
}
.Grid_Description .Ptxt_box{
    margin: 0;
    font-size: 14px;
    color: #484c4e;
    letter-spacing: 0.6px;
    line-height: 20.24px;
}
.Grid_Description .Direct_txt{
    color: #232f3e;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-transform: uppercase;
    display: block;
}
.Direct_txt:hover {
    color: #232f3e;
    font-weight: bold;
    transition-duration: 1s;
     transform: scale(1.06);
}
.Grid_Description h3{
    margin: 0;
    font-size: 15px;
    color: #181b1c;
    letter-spacing: 0.6px;
    line-height: 20.24px;
	text-transform: uppercase;
}
.table_box td {
    padding: 3px 2px;
	font-size:15px;
	font-weight:600;
	letter-spacing:0.7px;
}
.blue {
    font-size: 16px;
    color: #2C2891;
}
.table_box .red{
	color:#ff0600;
}
.table_box .green{
	color:#21E1E1;
}

.pagination_box .pagination .active a {
    color: #fff;
    font-weight: 700;
    border-radius: 19px;
    background: #03a9f5;
    width: 34px;
    text-align: center;
}

.pagination li.page {
    margin-right: 10px!important;
}

.pagination_box .pagination li a {
     color: #2c3128; 
     font-size: 18px; 
     font-weight: bold; 
     padding: 17px; 
     line-height: normal; 
}

.pagination>li>a, .pagination>li>span {
    position: relative;
    float: left;
    padding: 6px 12px;
    line-height: 1.428571429;
    text-decoration: none;
    color: #337ab7;
    background-color: #fff;
    border: 1px solid #ddd;
    margin-left: -1px;
}

.close {
    float: right;
    font-size: 21px;
    font-weight: 700;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    opacity: .2;
    filter: alpha(opacity=20);
}

.modal-body {
    text-align: center;
    padding: 22px 15px;
}

.pagination .page a.page-link {
    width: 34px;
    border: 1px solid #9bddfa !important;
    border-radius: 50%;
}
.pagination .page.redborder a {
    border: 1px solid #f68080 !important;
}
.pagination li.page {
    margin-right: 10px;
}
.pagination li.next {
    margin-left: -10px;
}
.pagination_box .pagination li.active a:hover {
   color: #fff;
}

.pagination {
    display: block!important;
}

.Grid_Box:hover {
/*    -webkit-transform: scale(1.04)!important;
    transform: scale(1.04);
    position: relative;
    z-index: 7;
    box-shadow: 0 0 10px rgb(5 33 72 / 49%);*/
}

:focus {
    outline: none;
}

.OutStock {
    display: block;
    min-width: 70px;
}
.OutStock_check{
    padding: 5px !important;
    vertical-align: middle !important;
}
.OutStock_check .lable_con {
    padding-left: 28px;
    margin: 0;
    text-align: left;
    vertical-align: middle;
    padding-top:0px !important;
    padding-bottom:0px !important;
}
.OutStock_check .checkmark {
    top: 3px;
    height: 24px;
    width: 24px;
}
.OutStock_check .checkbox_txt {
    color: #07326c;
    font-size:11px;
    font-weight: 600;
    white-space: normal;
}
.OutStock_check .lable_con .checkmark:after {
    left: 9px;
    top: 4px;
}
.ReadMore_box .green {
    color: #232f3e;
    font-size: 13px;
    font-weight: 600;
    min-width: 75px !important;
    display: inline-block;
}
.ReadMore_box .green i{
	padding:0 3px;
}
.dark_green {
    color: #7db945;
}
.ReadMore_box td i{
	padding: 0 5px;
}
.ReadMore_box .table td {
    border-right: 1px solid #ddd;
    padding: 15px 5px;
    font-size: 13px;
    font-weight: 600;
    letter-spacing: 0.7px;
    text-transform: uppercase;
}
.ReadMore_box .table {
    margin: 0;
	text-align: center;
    background: #f7faff;
	border-radius: 0 0 7px 7px;
}
.ReadMore_box .table td:last-child{
	border-right:none;
}
/* =====Grid View CSS Closed here ===== */

/* ===== View CSS Start here ===== */
.list_Box {
    background: #fff;
    box-shadow: 0 0 13px rgba(0, 0, 0, 0.05);
    border-radius: 4px;
	margin: 18px 0;
}
.MainGrid_sec{
    margin: 0 0 1px;
    padding: 15px 12px;
    box-shadow:0 1px 0 rgba(0, 0, 0, 0.08);
}
.MainGrid_txt_head {
    font-size: 15px;
    font-weight: 600;
    text-transform: uppercase;
    color: #181b1c;
    letter-spacing: 0.7px;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 453px;
    overflow: hidden;
    margin:0 10px 0 0;
}
.product_rate{
    font-size: 13px;
    font-weight: 500;
    color: #181b1c;
    letter-spacing: 0.4px;
    margin: 0;
    padding: 0 3px;
    padding-left: 0;
    display: inline-block;
}
.active_star{
	color: #ff9933;
    font-size: 14px;
}
.section_img_slide .slide_row{
    margin-right: -5px;
    margin-left: -5px;
}
.section_img_slide .slide_row .slide_col {
    padding: 0 5px;
}
.no-active_star{
	color: #c2bdb9;
    font-size: 14px;
}
.no-active_star:hover{
	color:#ff9933;
}
.desc_section{
	background:#f7faff;
	padding:20px 8px;
	box-shadow: 0 1px 0 rgba(0, 0, 0, 0.08);
	margin-bottom: 1px;
}
.Descript_col .Grid_Description {
    padding: 5px 0 0;
	height: auto;
}
.desc_heading{
	font-size:14px;
	font-weight:600;
	color:#101213;
	text-transform: uppercase;
	margin: 0 0 3px;
}
.desc_product{
	font-size: 13px;
    color: #181b1c;
    letter-spacing: 0.6px;
    margin: 0;
    height: 120px;
    overflow-y: auto;


}
.desc_section_read{
	background: #f7faff;
}
.ReadMore_desc li{
	display: inline-block!Important;
    padding: 16px 24px 17px!Important;
    border-left: 1px solid #e6eaf1;
}
.like_dislike_sec li {
    padding: 15px 24px !important;
}
.bor_sec{
	border-right: 1px solid #e6eaf1;
}
.ReadMore_desc ul li a {
    font-size: 13px;
    font-weight: 600;
    letter-spacing: 0.7px;
    text-transform: uppercase;
}
.ReadMore_desc ul li a:hover{
	color:#03a9f5;
}
.cart_icon_blu{
	color:#03a9f5;
}
.cart_icon{
	color:#7db945;
}
.like_dislike_sec {
    float: right;
}
.like_icon{
	color:#03a9f5;
	font-size:14px;
	font-weight:600;
}
.dislike_icon{
	color:#ff0000;
	font-size:14px;
	font-weight:600;
}
.like_icon i, .dislike_icon i{
	font-size: 21px;
    vertical-align:text-top;
}
.list_Box .list_row {
    margin: 0;
}
.ReadMore_desc .far.fa-thumbs-up{
	margin-top:-5px;
}
.long_arrow{
    font-size: 20px;
    vertical-align: middle;
    line-height: 13px;
}
.section_img_product{
	text-align: center;
    vertical-align: middle;
    padding-top:45px;
}
.section_main_img {
   
    vertical-align: middle;
    overflow: hidden;
    
}
.slide_sec_img {
    border: 5px solid #fff;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.20);
    border-radius: 5px;
    overflow: hidden;
    height: 95px;
}
.section_img_slide {
    padding-left: 12px;
}
.desc_section .Descript_col {
    padding: 0 5px;
}
.desc_section .Descript_row {
    margin: 0;
}
.MainGrid_row {
    margin: 0;
    display: table;
    width: 100%;
}
.MainGrid_row .MainGrid_col {
    padding: 0 2px;
    display: table-cell;
    vertical-align: middle;
    float: none;
    text-align: left;
}
.Grid_column{
    padding: 0 5px;
}
/* ===== List View CSS Closed here ===== */





a#loadMore {
    margin: 0 auto;
    max-width: 150px;
    display: block;
}

.Images_slide .slider_secMidd {
    background: #fff;
    border: none !important;
    border-radius: 4px;
    cursor: pointer;
    box-shadow: 0 2px 2px rgba(51, 51, 51, 0.13);
    width: 176px;
}
.slick-initialized .slick-slide{
	    padding: 0 4px;
}
.slider_secMidd {
    padding: 4px;
    border: 1px solid #ccc;
    width: 185.5px;
    height: 235.5px !important;
    vertical-align: middle !important;
    display: table;
}

.modal-backdrop.show{
	display:none !important;
}
/* ===== Home Page CSS Closed here ===== */

/* ===== Media CSS Start here ===== */
@media (min-width:1440px) and (max-width:1600px){
}

@media (max-width:1199px){
	.Grid_Box {
		max-width: 360px;
		margin: 10px auto;
	}
	.OutStock_check .checkbox_txt {
		font-size: 10px;
	}
	.white_area{
		height:190px;
	}
	.banner_box{
		height: 455px;
	}
	.ReadMore_desc li {
		padding: 15px;
	}
	.MainGrid_txt_head{
		max-width: 360px;
	}
	.table_box td{
		line-height:normal;
	}
	.slide_sec_img{
		height:85px;
	}
}

@media (max-width:991px){
	.banner_box {
		height: 420px;
	}
	.main_heading {
		font-size: 32px;
	}
	.banner_txt h2 {
		font-size: 22px;
		margin: 5px 0 20px;
	}
	.banner_txt {
		padding: 25px 15px;
	}
    .tax_class{
        margin: 61px 0 25px;
    }
	.table_box td, .Grid_Description .Ptxt_box, .ReadMore_box .green, .ReadMore_box .table td{
		font-size: 13px;
	}
	.Grid_Description h3 {
		font-size: 14px;
	}
	.long_arrow {
		font-size: 17px;
	}
	.white_area {
		height: 178px;
	}
	.product_rate{
		display:block;
	}
	.MainGrid_txt_head {
		max-width: 300px;
	}
	.like_dislike_sec {
		float: none;
	}
	.like_dislike_sec li{
		border: none !important;;
	}
	.ReadMore_desc li {
		border-bottom: 1px solid #e6eaf1;
	}
	.slide_sec_img {
		height: 57px;
		border: 3px solid #fff;
	}
	.ReadMore_desc li:last-child {
		width: 40%;
		border-right: none;
	}
	.ReadMore_desc li {
		width: 29.1111%;
	}
}
@media (max-width: 769px){
    .right_user .nav li .dropdown-toggle{
        min-width: 122px;
    }
}

@media (max-width:767px){
	.navbar-form .input-group {
    	display: inline-table;
   		vertical-align: middle;
        width: 90%;
	}
    .grid_icon {
        padding-top: 0px;
    }
    .grid_icon.productResultGrid {
        padding-top: 8px !important;
    }
    .Form_box {
        margin-left: -5px;
    }
    /*.dataWidth {
        width: 63px;
        max-height: 25px;
    }*/
    .searchBtn {
        top: -401px;
    }
    .productResultMenu .navbar-header {
        top: -314px;
        width: 100%;
    }
	.Grid_Description {
		height: auto;
	}
    .tax_classNew {
    margin: 8px 0 0 0;
    }
	.ReadMore_desc li {
		width: 29%;
		padding: 15px 10px;
	}
	.main_heading {
		font-size: 23px;
		margin: 0 0 10px;
		line-height: 30px;
	}
	.banner_txt h2 {
		font-size: 17px;
		margin: 0 0 8px;
	}
	.banner_txt {
		padding: 0px 10px 15px;
	}
    .tax_class{
        margin: 4px 0 25px;
    }
	.banner_box {
		height: 395px;
		padding: 20px 0 0;
	}
	.table_box td, .Grid_Description .Ptxt_box, .ReadMore_box .green, .ReadMore_box .table td {
		font-size: 11px;
	}
	.Grid_Description h3 {
		font-size: 13px;
	}
	.Grid_Box {
		margin: 20px auto;
	}
	.Grid_Boxes {
		padding: 0;
	}
	.desc_section .Descript_row, .desc_section .Descript_row .Grid_column{
		margin: 0 !important;
		padding: 0;
	}
	.desc_section .Grid_Description {
		padding: 15px 0 0;
	}
	.list_Box .list_column {
		padding: 0;
	}
	.MainGrid_row, .MainGrid_row .MainGrid_col{
		display: block;
	}
	.product_rate {
		display: inline-block;
	}
	.MainGrid_txt_head {
		padding: 0 0 10px;
		max-width: none;
		margin: 0;
	}
	.section_img_product {
		padding: 40px 0 30px;
	}
	.MainGrid_sec {
		padding: 12px;
		box-shadow: 0 1px 8px rgba(0, 0, 0, 0.08);
	}
	.section_img_slide {
		padding-left: 0;
	}
	.slide_sec_img {
		height: 95px;
		width: 95px;
		margin: 0 auto;
	}
	.slide_col:first-child .slide_sec_img {
		margin: 0 0 0 auto;
	}
	.slide_col:last-child .slide_sec_img {
		margin: 0 auto 0 0;
	}
	.Grid_Description .Descript_row {
		margin: 0 5px;
	}
	.ReadMore_desc ul li a {
		font-size: 12px;
	}
}

@media (max-width:425px){
	.slide_sec_img {
		height: 70px;
		width: 70px;
	}
	.ReadMore_desc li {
		display: block;
		border-left: none;
		width: 100%;
	}
    .productResultBar {
        width: 85%;
    }
    .productResultGrid {
        padding-top: 8px !important;
    }
	.ReadMore_desc li:last-child {
		width: 100%;
	}
	.like_dislike_sec li {
		display: inline-block !important;
		width: 49% !important;
	}
	.like_dislike_sec li:last-child{
		width: 49%;
		text-align: right;
	}
}


/* ===== Media CSS Closed here ===== */
