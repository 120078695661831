h1.online_Store {
    line-height: 45px;
}
@media (max-width:767px) {
    .vendorStoreMenu .main_header .navbar-header{
        top: -360px;
        width: 103%;
    }
    .vendorSearchBtn {
        top: -449px;
    }
    .vendorStoreBanner .banner_box.indianflagClass {
          margin-top: 110px !important;
    }
}