.cate_sec.fixed {
    position: fixed;
    right: auto;
    width: 100%;
    top: 116px;
    z-index: 999;
}


.totl_result {
    font-size: 18pt;
    margin-bottom: 15px;
    color: #2b2a29;
}
.col-sm-8.vendorStore {
    margin-top: -21px;
}
.advancedSearchBar {
    width: 83.8%!important;
}
.cate_sec {
    background: #e2f441;
    border: 1px solid #3d6a91;
    border-radius: 0 0 8px 8px;
    margin: 0px 0px 0px 0px;
}


 
.advace_form .btn_dflt:hover {
    background: linear-gradient(#1bb0f4, #017fb9);
    background: -ms-linear-gradient(#1bb0f4, #017fb9);
}
  
.advace_form .advance_link {
    line-height: 15px;
    padding: 10px;
}
  
.advace_form {
    margin: 15px 0 0;
}
  
.advace_form .form-control {
   text-transform: uppercase;
   font-size: 14px;
}
  
.advace_form .advance_link.active {
    background: linear-gradient(#1bb0f4, #017fb9);
    background: -ms-linear-gradient(#1bb0f4, #017fb9);
}
 
.advace_form .buttonActive.active {
    background: linear-gradient(#1bb0f4, #017fb9);
    background: -ms-linear-gradient(#1bb0f4, #017fb9);
}
  
.advace_form .advance_link.active:after {
   content: '2';
   font-size: 0px;
}

.advace_form .advance_link span {
   display: block;
   max-width: 160px;
}


.companyText.clearfix {
    padding-top: 6px;
    color: darkblue;
}

.col-sm-2.logo_width {
    width: 14%;
}

.logo_imgAsc{
    max-width: 81px;
    max-height: 39px;
}

.logoASC{
    padding-left: 10px;
}
.check_boxbtbn {
    background: linear-gradient(#e5e7e9, #abbbc4);
    padding: 11px 10px;
    border-radius: 4px;
    letter-spacing: 1px;
    font-size: 14px;
    color: #272829;
    font-weight: bolder;
    text-shadow: 1px 1px 1px #fff;
    display: block;
    text-align: center;
    box-shadow: 0 1px 3px rgba(0,0,0,.3);
	margin-bottom: 5px;
	height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 15px;
}
.check3:checked ~.check_boxbtbn{
  background:linear-gradient(#232f3e, #21E1E1);;
  color: #fff;
  text-shadow: 1px 1px 1px #00567d;
}


.prodcut_optn .form-control {
    height: 42px;
}
.map_box iframe {
    width: 100%;
    height: 508px;
}
.map_box {
    border-radius: 5px;
    box-shadow: 0 2px 13px rgba(0, 27, 63, 0.14);
    overflow: hidden;
    margin-top: 17px;
}
.mainCatAdvanceSrch:focus {
    border: 3px solid orange !important;
}

.botm_btn .blue_btn:focus .form-control{
  color:#fff
}
.botm_btn .grey_btn:focus{
  color:rgb(30, 32, 33);
}
.advace_form .btn_dflt {
    border-radius: 3px;
    display: block;
    text-align: center;
    width: 100%;
    color: #1e2021;
    font-weight: 600;
	min-width: 100px;
	height: 48px;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 15px;
}
.advace_form .btn_dflt:hover{
  background: linear-gradient(#21E1E1, #232f3e);
    background: -webkit-linear-gradient( #1bb0f4, #017fb9);
    background: -moz-linear-gradient( #1bb0f4, #017fb9);
    background: -ms-linear-gradient( #1bb0f4, #017fb9);
    color: #fff;
    text-shadow: none;
}

.advace_form .advance_link {
    padding: 5px;
    position: relative;
}
.advace_form {
    margin: 15px 0 0;
}
.advace_form .form-control {
    text-transform: uppercase;
    font-size: 14px;
}
.advace_form .advance_link.active {
  background: linear-gradient(#232f3e, #21E1E1);
    background: -webkit-linear-gradient( #1bb0f4, #017fb9);
    background: -moz-linear-gradient( #1bb0f4, #017fb9);
    background: -ms-linear-gradient( #1bb0f4, #017fb9);
    color: #fff;
    text-shadow: none;
}

.advace_form .buttonActive.active {
  background: linear-gradient(#232f3e, #21E1E1);
    background: -webkit-linear-gradient( #1bb0f4, #017fb9);
    background: -moz-linear-gradient( #1bb0f4, #017fb9);
    background: -ms-linear-gradient( #1bb0f4, #017fb9);
    color: #fff;
    text-shadow: none;
}
.advace_form .advance_link.active:after{
  content: '2';
  font-size: 0px;
  display: inline-block;
    width: 0;
    height: 0;
    margin-left: 2px;
    vertical-align: middle;
    border-top: 6px solid;
    border-right: 8px solid transparent;
    border-left: 8px solid transparent;
    color: #017fb9;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    margin: 0 auto;
}
.advace_form .advance_link span {
    display: block;
    max-width: 250px;
    margin: 0 auto;
    font-weight: 600;
} 
.resat {
    margin-top: -50px;
}

a#loadMore {
    margin: 0 auto;
    max-width: 150px;
    display: block;
}

.custom_box label.cstm_radio2 {
    padding: 0px;
}
.dataadv {
    order: -1;
}

body .btn_dflt {
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 600;
    color: #fff;
    text-shadow: rgb(0 0 0 / 28%) 1px 1px 1px;
    letter-spacing: 1px;
    min-width: 183px;
    padding: 15px;
    border: none;
    line-height: normal;
}
body .btn_dflt:hover{
	color: #fff !important;
}
body .grey_btn {
    box-shadow: 1px 1px 3px rgb(0 0 0 / 22%);
    color: #232f3e !important;
    background: linear-gradient(#e5e7e9, #abbbc4);
    border: none;
}
body .grey_btn:hover{
	color:#fff !important;
}
.srb_fixed.srb_bxinr{
 position: fixed;
 display: flex;
 left: 26px;
 right: 30px;
 top: 40px;
 z-index: 13;
}
.advanceKeyword {
    border: 3px solid orange!important;
}
.advance_link.active {
    color: #fff !important;
}
.cstm_radio2 input:checked ~ .check_mark, .cstm_radio2 input:not(:checked) ~ .check_mark{
	height: 22px;
}


.srch_sec table.table.table-bordered {
    border: 1px #eee;
    border-style: groove;
    font-family: 'Font Awesome 5 Pro';
    font-weight: bold;
}
.srch_sec table.table.table-bordered td {
    vertical-align: top;
	border: 1px solid #ccc;
}
.srch_sec table.table.table-bordered td:first-child {
    vertical-align: middle;
    
}
.srch_sec table.table.table-bordered tr:nth-child(even) td:nth-child(even) {
    background: #98AFC7;
}
.srch_sec table.table.table-bordered tr:nth-child(odd) td:nth-child(odd) {
    background: #98AFC7;
}
.srch_sec span.sm_txt {
    font-size: 12px;
    display: block;
    text-align: center;
    padding: 0;
	color:#666 !important;
}
.srch_sec .table {
    margin: 0;
}

.srb_bxinr {
    position: relative;
    max-width: 500px;
    margin: 20px auto 0;
}
.srb_fixed .srb_bxinr{
    position: fixed;

}
.srb_bxinr button.btn.blue_btn.search_btn {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    color: #fff;
    width: 80px;
}
.srch_sec span.vl_txt {
    display: block;
    text-align: center;
    font-size: 15px;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 180px;
    white-space: nowrap;
    margin: 0 auto;
}
.srch_sec table.table.table-bordered td {
    
    min-width: 150px;
    max-width: 155px;
}

@media (min-width:992px) and (max-width:1199px){
  .check_boxbtbn{
    padding: 11px 9px;
    letter-spacing: 0;
    font-size: 14px;
  }

}

@media (max-width:991px){
  .advace_form .btn_dflt, .advace_form .form-control {
    margin: 3px 0;
	font-size: 12px;
  }
  .map_box iframe {
      width: 100%;
      height: 438px;
  }
  .ship_optn {
		margin-top: 15px !important;
	}
	.shp_bx .custom_check {
		width: 33.33%;
		padding: 0 4px;
	}
	.shp_bx {
		display: flex;
		justify-content: space-between;
		margin-left: -4px;
		margin-right: -4px;
	}

	
}

@media (min-width:768px) and (max-width:991px){
  .check_boxbtbn{
    padding: 11px 4px;
    letter-spacing: 0;
    font-size: 13px;
  }


}

@media (max-width:767px) {
  .botm_btn .btn_dflt {
    min-width: 125px;
}
body .btn_dflt {
min-width: 142px;
margin-top: 1px;
margin-right: 9px;
}
.advanceSearchMenu  .navbar-header{
    top: -64px;
}
.advancedSearchBar {
    width: 74.5%!important;
}
.srb_fixed.srb_bxinr{
 position: fixed;
 display: flex;
 left: 26px;
 right: 30px;
 z-index: 13;
}
.three_boxes .form_subhading {
    margin: 10px 0;
}
.map_box iframe {
    width: 100%;
    height: 338px;
}
.form-group{
	margin-bottom: 15px;
}
.main_content.advanc_page {
    padding-top: 63px;
}
.main_content.advanc_page .form_toptxt {
    margin-top: 25px;
}


}

@media (max-width:575px) {
	.shp_bx .custom_check {
		width: 100%;
		padding: 0 4px;
	}
	.shp_bx {
		display: block;
	}
	.advace_form >div>div {
		width: 100%;
	}
	.dataadv {
    order: 0;
}
}


@media (max-width:450px){
  .advace_form .row.margin_4 .col-xs-6 {
    float: none;
    display: block;
    width: 100%;
}


}
