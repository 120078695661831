.dealsBox {
	min-height: 210px;
	background-color: #84644c;
	padding: 15px 0px 0;
	width: 220px;
	height: 210px;
}
.dealsContainer {
	width: 25% !important;
	padding-top: 25px;
}
.dealsBackBtn {
    padding: 116px 0px 0px 109px!important;
}
.dealsLogo {
	margin: 14px 0 -75px 20%;
}
.supportIcon {
	height: 103px;
}
.dealsBody {
	padding: 20px 0 0px;
}
.megaBrandBox {
	display: none;
}
.deals_head {
	padding-top: 37px;
}
@media (max-width: 767px){
.dealsContainer {
	width: 50% !important;
	padding-top: 0px;
}
.dealsBackBtn {
    padding: 94px 0px 0px 0px!important;
}
.deals-icon {
	font-size: 56px;
	height: 100px;
}
.deals_head {
    margin-bottom: 38px !important;
    padding-top: 0px;
}
.dealsLogo {
	margin: 10px auto;
}
.dealsBox {
	min-height: 210px;
	width: auto;
	padding-top: 20px;
	margin-left: -6px;
	margin-right: -6px;
}
}