@media (max-width: 767px){
	.dealsMenu .navbar-header {
		top: -110px;
	}
	.whiteSpaceDeals {
		height: 108px;
	}
	.dealsSearch {
		top: -202px;
	}
}