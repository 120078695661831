span#siteseal {
    position: fixed;
    bottom: 0;
    left: 0;
}
.modal-backdrop {
    position: fixed !important;
}
.VendorResultPlaceholder {
    margin: 0 20px 0 0 !important;
}
.loader_Ad {
    width: 50px;
    height: 20px;
    align-content: center;
    margin: 0px;
    padding: 0px;
}
.vendorSearchBar {
    width: 77.5% !important;
}
.modal-content{
	top: 1px !important;
}

.tltp_inline a.tooltip_box {
    line-height: normal;
	margin-right: 5px;
}
.filter_box{
display: flex;
justify-content: center;
}

.vndr_row .Ptxt_box.no_bg {
    text-align: left !important;
    background: none !important;
    border: none !important;
    border-bottom: 1px solid #eee !important;
    margin-bottom: 7px;
	margin-top: 7px !important;
}
.Grid_Box .Grid_Description.vndr_row .Descript_row {
    margin: 0;
}
.Grid_Description.vndr_row span.vlu_txt {
    display: block;
    margin-bottom: 10px;
	font-size: 14px;
}
.Grid_Description.vndr_row {
    padding-top: 0;
}
.prdct_name {
    font-weight: 500;
}
.Ptxt_box h4 {
    font-size: 14px;
    text-transform: uppercase;
    color: #222;
    margin-top: 9px;
    margin-bottom:2px;
}
.ReadMore_box {
    border-top: 1px solid #ddd;
}
.vndr_row .Ptxt_box {
    margin-top: 0 !important;
}
.ratings i.fas {
    color: #fdd511;
}
.Grid_Boxes.vndr_page {
    margin-top: 25px;
}
@media (max-width:767px){
	.banner_box.indianflagClass {
		background-size: cover !important;
        margin-top: 114px;
	}
    .refresh_icon{
        padding-top: 12px;
        margin-left: -10px;
        margin-right: -10px;
    }
    .Grid_Boxes.vndr_page {
        margin-top: 0px;
    }
    .filter_box{
      display: contents;
}
.vendorResultBox {
      padding: 0px !important;
      margin-top: 23px !important;
}
.vendorSearchBar {
    width: 88% !important;
}
.vendorFilterBar {
    padding-top: 2px !important;
}
.shrinkBox .input-group-btn .input-group-addon .dataWidth {
    max-height:28px !important;
}
.vendorResultMenu .navbar-header {
    top: -368px;
    width: 100%;
}
.vendorSortBar {
    padding-top: 3px !important;
}
.vendorResultSearch {
    top:  -411px;
    background-color: #fff;
    left: -10px!important;
    width: 107%!important;
}
}