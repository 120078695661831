.form_toptxt {
    margin-top: 29px;
}
.border-error {
    border: 2px solid #E85445!important;
}

.border-warning {
    border: 2px solid #FFBF00!important;
}

.border-success {
    border: 2px solid #15bd0f!important;
}

.title_check:focus{
    border: 2px solid #FFBF00!important;
}

.respaffred{
    color:#E85445;
}

.respaffgreen{
    color:#15bd0f;
}

ul.dropdown-menu.form_cont_menu {
    max-height: 269px;
    overflow-y: scroll;
}

.cap_block{
    text-shadow: center;
}

.col-xs-1, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9, .col-xs-10, .col-xs-11, .col-xs-12 {
    float: left;
}

.Register_form .form-group {
	margin: 0;
    margin-bottom: 15px;
	padding: 0;
}
.Register_form  .form-control {
    font-size: 13px;
}
.Register_form  .btn_dflt {
    border: none;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 600;
    color: #fff;
    text-shadow: rgb(0 0 0 / 28%) 1px 1px 1px;
    letter-spacing: 1px;
    min-width: 185px;
    padding: 14px;
}
.gap_10>div {
    padding: 0 5px !important;
}
.gap_10 {
    margin-left: -5px !important;
    margin-right: -5px !important;
}
.Register_form .form-control{
	background: #f8fafc;
}

.Register_form .form-control::placeholder {
  color: #5a585c !important;  
}


@media (min-width: 1200px){
    .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12 {
        float: left;
    }
}


@media (max-width:767px){
	.phn_div >div:first-child img {
		width: 25px;
		margin-right: 2px;
	}
	.phn_div >div:first-child {
		width: 100px;
	}
.phn_div input.form-control.title_check.border-error{
	width: calc(100vw - 155px);
}
.cap_block iframe {
    transform: scale(0.9);
    transform-origin: 0;
}
.phn_div {
    display: flex !important;
    flex-wrap: nowrap !important;
}
.form_toptxt {
    margin-top: 0;
}

}