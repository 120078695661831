.wht_bx {
    background: #f8fafc;
    box-shadow: 0 0 75px rgb(0 0 0 / 27%);
    padding: 15px;
    border-radius: 15px;
}
.playBtn {
    margin-left: 6px;
}
.HomeContainer {
    width: 1148px !important;
    max-width: 100% !important;
    padding-left: 122px !important;
    padding-right: 122px !important;
    margin: auto;
}
.homeBody {
    padding-top: 12px;
}
.partner_footer_label {
    position: fixed;
    left: 0;
    opacity: 0.9;
    z-index: 9999;
    bottom: 6%;
    width: 100%;
    background-color: #232f3e;
    color: white;
    text-align: center;
    height: 30px;
}
.homeContainerHead {
    padding-top: 0;
    height: 42px!important;
    margin-top: -58px!important;
    margin-bottom: 24px!important;
    text-align: center;
}
.partner_contentCookies {
    margin-bottom: 0;
    margin-top: 4px ;
    font-weight: bold;
    letter-spacing: 9px;
}
.categories_search_bar .wht_bx {
    padding: 6px 9px;
    max-width: 1050px;
    margin: 0 auto;
    height: 89px;
}
.landingBox {
        padding-right: calc(var(--bs-gutter-x) * 0.3) !important;
        padding-left: calc(var(--bs-gutter-x) * 0.3) !important;

    }
.categories_search_bar {
    position: relative;
    margin-bottom: 7px;
}
.categories_search_bar .voice_serch {
    color: #11a5ea;
    font-size: 46px;
    margin-bottom: 13px;
    display: inline-flex;
    margin-right: 9px;
    align-items: center;
}
.categories_search_bar .form-control {
    border: 4px solid rgb(255, 165, 0);
    border-radius: 10px;
    height: 72px;
    margin-bottom: 9px;
    font-size: 18px;
    padding: 5px 15px;
    width: 100%;
    outline: none !important;
    box-shadow: none !important;
}
.flex_div {
    display: flex;
    align-items: center;
}
.flex_div form.navbar-form {
    display: flex;
    align-self: center;
    padding: 0;
    margin: 0;
    width: 100%;
}
.btns_div .btn {
    padding: 11px 55px 11px 23px;
    font-size: 14px;
    color: #fff;
    text-transform: none;
    font-weight: bolder;
    letter-spacing: 3px;
    height: 28px;
    width: auto;
    border: none !important;
    transition: .5s ease-in-out;
    -webkit-transition: .5s ease-in-out;
    -moz-transition: ease-in-out .5s;
    border-radius: 6px;
    margin: 9px 0;
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
    box-shadow: 0 2px 5px rgb(0 27 63 / 30%) !important;
    outline: none !important;
    position: relative;
    min-width: 170px;
}
.blueBtn, .blueBtn:hover, .blueBtn:focus, .blueBtn .play_icon {
    text-transform: uppercase !important;
    font-weight: bolder;
    background: linear-gradient(#21E1E1,#232f3e) !important;
    
    /*background: linear-gradient(#21E1E1,#232f3e);
    background: -moz-linear-gradient(#232f3e,#21E1E1);*/
}

.light_green_btn, .light_green_btn:hover, .light_green_btn:focus, .light_green_btn .play_icon{
    text-transform: uppercase !important;
    font-weight: bolder;
    background: linear-gradient(#232f3e,#21E1E1) !important;
}
.btns_div .play_icon {
    position: absolute;
    right: 15px;
}
.btns_div {
    margin: 0 0 8px 14px;
    width: auto;
    max-width: 177px;
}
.shadowDiv {
    position: relative;
    height: 35px;
    margin: 30px 0 0;
}
.shadowDiv:before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    box-shadow: 0 23px 10px rgb(0 0 0 / 35%);
    height: 5px;
    max-width: 310px;
    background: transparent;
}




.shop_head {
    font-size: 28px;
    text-align: center;
    color: #fff;
    font-weight: 600;
    padding-bottom: 30px;
    text-transform:uppercase;
}

.shopby.shopby_fix {
    overflow: auto;
    display: block;
}


.keyword_sec{
    top: 50%;
    left: 8%;
}

.shop_moh_box {
    /*background: url(../img/3.png);*/
    background-size: 260px;
    background-repeat: no-repeat;
    height: 100%;
    background-position: center;
}
.shop_box{
    background:#20477b;
    width:100%;
    box-shadow: 8px 8px 7px grey;
    display:block;
    border-radius:5px;
    text-align:center;
    min-height: 210px;
    padding: 50px 30px 0;
    margin-bottom: 10px;
    transition: all 0.7s ease-in 0s;
}
.shop_box:hover{
    text-decoration:none;
}
.shop_box:hover .shop-below-title{
    color:#21E1E1;
}

.shop-border {
    display: block;
    width: 70px;
    background: #fff;
    height: 2px;
    margin: 0 auto 25px;
}
.shop_box:hover .shop-border {
    background: #015f58;
}
.shop-below-title {
    font-size: 15px;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    width: 100%;
    display: block;
    margin: 0 auto;
    font-weight: bold;
    color: white;
    max-width: 235px;
}
.homeNumber {
    color: white;
    font-size: 20px;
}
.homeBox {
    background-color: #84644c;
    padding: 0px !important;
    width: auto;
}
.second_landing {
    max-width: 890px;
    margin: 0 auto;
}

.shop-icon {
    font-size: 85px;
    height: 130px;
    display: block;
    -webkit-background-clip: text;
    -webkit-text-fill-color: #7c8ca3;
}

/* Recommendations   */
.mendations_shpby {
    max-width: 890px;
    margin: 0 auto;
}
.sub_back {
    display: flex;
    align-items: center;
}
.keyword_sec .sub_back {
    padding-top: 0;
}
a.land_back {
    padding: 6px 14px;
    border-radius: 5px;
    font-size: 16px;
    font-weight: 600;
    position: fixed;
    color: #000;
    z-index: 999;
    transition:ease-in all 0.5s;
}
a.land_back:hover{
    color:#21E1E1;
    transition:ease-in all 0.5s;
    text-decoration:none;
}
.shopby_fix {
    padding: 40px 0 90px;
    width: 100%;
    height: 100%;
    display: table;
}
.shop_head {
    font-size: 36px;
    text-align: center;
    letter-spacing:2px;
    color: #232f3e;
    font-weight: bold;
    padding-bottom: 30px;
    letter-spacing: 1px;
}
.second_landing {
    max-width: 890px;
    margin: 0 auto;
}

.recommend_box {
    background: #84644c;
    width: 270px;
    display: block;
    border-radius: 7px;
    text-align: center;
    height: 230px;
    padding:50px 30px 0;
    margin-bottom: 12px;
    transition: all 0.7s ease-in 0s;
}
.recommend_box:hover {
    text-decoration:none;

}
.recommend_box:hover .recommend-below-title {
    color: #21E1E1;
}
.recommend-icon {
    font-size:75px;
    height: 130px;
    display: block;
    -webkit-text-fill-color: #7c8ca3;
}
.segment_box {
    padding-top: 25px;
}
.recommend-border {
    background: #b2b2b2;
    display: none;
    width: 70px;
    height: 1px;
    margin: 0 auto 25px;
}
.recommend-below-title {
    font-size: 15px;
    font-weight: bold;
    color: white;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    width: 100%;
    display: block;
    margin: 0 auto;
    max-width: 235px;
}
.segmnt-icon {
    height: 63px;
    font-size: 37px;
}
.segment_box:hover {
    background: #232f3e;
}
.segment_box:hover .recommend-below-title {
    color: #21E1E1;
}
.recommend-border {
    background: #b2b2b2;
    display: block;
    width: 70px;
    height: 2px;
    margin: 6px auto 11px;
}

/* Market segment css Start  */
.market_seg {
    max-width:910px;
    display: block;
}
.segment_box {
    padding-top: 25px;
    height: 210px;
    width: 220px;
}
.title_box {
    background: #f5fcff;
    width: 94px;
    margin: 0 auto 3px;
    padding: 9px 0px 10px;
    border-radius: 5px;
    font-weight: bold;
    font-size: 15px;
    letter-spacing: 2px;
    color: #2fa2db;
}
/* Market segment css end  */
.keyword_search{
    max-width:920px;
    margin:0 auto;
}
.land_search_input{
  height: 60px;
  float: left;
  border: 0;
  font-size: 16px;
  z-index: 2;
  box-shadow: none;
  width:100%;
  max-width: 650px;
  padding: 0 15px;
  margin: 0 -1px;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
}
.land_search_input:focus, .keyword_src-btn:focus{
    outline:none;
}
.keyword_src-btn{
  color: #fff;
  border: 1px solid transparent;
  background: linear-gradient(#119ddd, #1baff2);
  text-align: center;
  transition: 250ms all ease-in-out;
  margin-left: 6px;
  width:65px;
  height:65px;
  border-radius:50px;
}
.keyword_search .search-dropdown {
  position: relative;
  top: 0;
  display: inline-block;
  float: left;
}
.keyword_search .search-dropdown.open .dropdown-menu {
  display: block;
}
.keyword_search .dropdown-toggle {
  height: 60px;
  font-size: 15px;
  line-height: 28px;
  border-radius: 2px;
  z-index: 3;
  width:100%;
  font-weight:500;
  border-bottom-left-radius: 30px;
  border-top-left-radius: 30px;
  max-width:200px;
  min-width: 196px;
  position:relative;
}
.dropdown_keyword{
    background: #c1f3ff;
    border: 0;
    padding: 0 30px;
}
.keyword_search .dropdown-menu {
  position: absolute;
  top: calc(100% - 1px);
  display: none;
  margin: 0;
  padding: 5px;
  list-style: none;
  background-color: #fff;
  border: 1px solid #999;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
  z-index: 3;
  min-width: 195px;
}
.keyword_search .dropdown-menu > li > a {
  display: block;
  padding: 4px 12px;
  color: #333;
  font-size: 14px;
  line-height: 20px;
  text-decoration: none;
  border-radius: 2px;
}
.keyword_src-btn .icon {
    font-size: 25px;
}
.sea_caret{
    margin-left:12px;
}
.keyword_srch{
    background:#01132c;
}
.shop_head {
    font-size: 22px;
    margin: 0;
    width: 85%;
    padding-bottom: 0;
}
.shopby_head {
    width: 100%;
    padding: 20px 0 30px;
}
.totalHeightDiv .shop_head {
    width: 85%;
}
.totalHeightDiv .keyword_sec .sub_back {
   margin-bottom: 90px !important;
   padding:0 0 5px !important;
}
.Government_sec .third_market-box{
    max-width: 786px;
    margin: 0 auto; 
}
.totalHeightDiv .shopby .container{
    display:table-cell;
    vertical-align:top;
    padding-top: 130px;
}

.shopby .row >div:hover a{
     -ms-transform: scale(1.06);
    -webkit-transform: scale(1.06);
     transform: scale(1.06);
}
body>section{
    position:relative;
}
.homeLogo {
    margin: 0 15%;
}
a.GBX_logo {
    display: block;
    max-width: 120px;
}
.shopby {
    padding-bottom: 0px;
}
.Shop_heading.container {
    padding-top: 0;
    height: 52px;
    margin-top: -10px;
    margin-bottom: 8px;
}

.Shop_heading, .Filter_heading, .Our_heading, .shopIn_heading {
    padding-top:85px;
}
    
.categories_search_bar .btns_div .btn {
    padding: 7px 36px 7px 20px !important;
    text-align: center !important;
    height: auto;   
    justify-content: center;
    margin: 2px 0;
}
	
/* Media sectionsection start  */





@media (max-width:1223px){
    .homeTotalDiv .shopby, .MarketHeightDiv .shopby, .totalHeightDiv .shopby{
        position: static;
    }
    .homeTotalDiv .shopby .container, .MarketHeightDiv .shopby .container {
        display: block;
        padding-top: 0;
    }   
}

@media (min-width:1224px) and (max-width:1980px){
    .frst_sec img{
        max-width:80px;
    }
    .frst_sec {
        padding: 8px 0;
    }
    .totalHeightDiv .shopby .container{
        display:table-cell;
        vertical-align:top;
        padding-top: 130px;
    }
    .third_market-box >div {
        margin-top: -200px;
    }   
    .keyword_sub {
        margin-bottom: 40px;
    }
    
}
@media (min-width:1440px) and (max-width:1690px){
	.shop_box{
		padding: 10px 10px 0;
	}
	.shop-icon{
		height: 100px;
		font-size: 65px;
	}
	.shop-icon img {
		max-height: 80px;
	}
    .shop-icon-segment img {
        max-height: 70px;
    }
	.shop_box{
		min-height: 210px;
	}
	.shopby_head{
		padding: 10px 0 15px;
	}
    .homeHead {
        padding-top: 0px;
        text-align: center;
    }
	
	
}
@media (min-width:992px) and (max-width:1430px){
	.shop-border{
		margin: 0 auto 14px;
	}
	.shop-icon img {
		max-height: 55px !important;
	}
	.shop-icon{
		font-size: 45px;
		height: 71px;
	}
	.shop_box{
		min-height: 165px;
		padding: 10px 10px 0;
		margin-bottom: 8px;
	}
	.shopby_head{
		padding: 7px 0 10px;
	}
	a.GBX_logo{
		padding: 5px 0 0;
		max-width: 130px;
	}
	
}


@media (max-width:1199px){
    .shopby .mendations_shpby .row > div{
        padding-right: 0;
        padding-left: 0;
    }

}

@media (min-width:992px) and (max-width:1199px){
	.shop_box {
		min-height: 205px;
		padding: 20px 10px 0;
	}
	a.GBX_logo {
		padding: 11px 0;
	}
}


@media (max-width: 991px){
    .second_landing {
        max-width: 100%;
    }
    .shopby {
        padding: 0;
    }
    .shop-border {
        margin: 0 auto 20px;
    }
    .shop-icon img {
        max-width: 54px;
    }
    .shop-below-title {
        font-size: 12px;
        max-width: 110px
    }
    .mendations_txt {
        font-size: 12px;
        max-width: 118px;
    }
    
    .recommend-below-title {
        font-size: 13px;
    }
    .Shop_heading, .Filter_heading, .Our_heading, .shopIn_heading {
        padding-top:60px;
    }
    
    .shopby_head {
        width: 100%;
    }
    .shop-icon {
        font-size: 50px;
        height: 80px;
    }
    .shop_box {
        min-height: 230px;
        padding: 30px 10px 0;
    }
    .homeContainerHead {
    padding-top: 0;
    text-align: center;
}

}

@media (max-width: 769px){
    .categories_search_bar .wht_bx {
        padding: 15px 17px;
    }
    .homeContainerHead {
    padding-top: 0;
    height: 22px!important;
    margin-top: 0px!important;
    text-align: center;
}
    .HomeContainer {
    width: 1148px !important;
    max-width: 100% !important;
    padding-left: 15px !important;
    padding-right: 15px !important;
    margin: auto;
}
    .shop_box {
        min-height: 200px;
        padding: 30px 10px 0;
    }
    .Form_box .Form_row {
        width: 100%;
    }
    .landingBox {
        width: 50% !important;
        padding-right: calc(var(--bs-gutter-x) * 0.3) !important;
        padding-left: calc(var(--bs-gutter-x) * 0.3) !important;

    }
    .filterBar {
        padding-top: 3px !important;
        font-size: 12px;
        width: 100% !important ;
    }
    .sortBar {
        padding-top: 3px !important;
        width: 100% !important;
    }
    .categories_search_bar .voice_serch {
        font-size: 38px;
        margin-right: 12px;

    }
    .Shop_heading.container{
        margin-top:-17px;
    }
    .MicIcon {
      font-size: 36px;
      margin-left: -24px;
    }
    .categories_search_bar .form-control {
        border: 3px solid rgb(255, 165, 0);
        height: 55px;
        font-size: 15px;
        padding: 5px 12px;
    }
    .btns_div {
        margin: 15px 0 3px;
        max-width: 100%;
        display: flex;
        justify-content: space-between;
        width: 100%;
        padding-right: 6px;
    }
    .btns_div .play_icon {
        height: 32px;
        width: 32px;
        font-size: 16px;
        padding: 5px;
        right: 6px;
    }
    .btns_div .btn {
        padding: 11px 40px 11px 14px;
        font-size: 13px;
        letter-spacing: 0.5px;
        height: 45px;
        margin: 0 0;
        min-width: 125px;
        border-radius: 12px;
    }
    .shadowDiv:before {
        box-shadow: 0 16px 8px rgb(0 0 0 / 20%);
        height: 3px;
        max-width: 180px;
    }
    .shadowDiv {
        height: 23px;
        margin: 23px 0 0;
    }

    .second_landing > div, .third_landing > div {
        margin-top: 0px;
    }
    /*Keyword search   */
    .keyword_search {
        background: #c1f3ff;
        overflow: hidden;
        border-radius: 25px;
    }
    .keyword_search .search-dropdown {
        display: block;
        text-align: center;
        float: none;
    }
    .land_search_input {
        display: block;
        text-align: center;
        max-width: 100%;
    }
    .keyword_src-btn {
        display: block;
        width: 100%;
        margin: 0;
        padding: 0;
        border-radius: 0;
    }
    .keyword_search .dropdown-toggle, .keyword_src-btn{
        height:50px;
    }
    

    .shop_head {
        font-size: 19px;
        margin-top: 11px !important;
    }
    .shopby_fix {   
        padding: 0px 0;
        position: static;
    }
    .first_market-box >div:first-child {
        margin-left:0px;
    }
    .third_market-box >div, .second_market-box >div  {
        margin-top:0px;
    }
    .third_market-box >div:last-child {
        margin-left:0px;
    }
    .frst_sec {
        padding: 10px 0;
    }
    .totalHeightDiv .shopby .container {
        padding-top: 50px;
    }
    .Government_sec .third_market-box >div {
        margin-top: 0;
    }
    a.land_back {
        font-size: 14px;
    }
    .shopby {
        padding: 0 0 15px !important;
    }
    .shopInTotalDiv .shopby, .ourTotalDiv .shopby {
        position: static;
    }
    .shopInTotalDiv .shopby .container, .ourTotalDiv .shopby .container {
        display: block;
        padding-top: 0;
    }
    .MarketHeightDiv .Government_sec .third_market-box >div {
        margin-top: 0;
    }
    .MarketHeightDiv .third_market-box >div:last-child {
        float: none;
    }

    a.GBX_logo {
        top: 44px;
        max-width:110px;
        margin: 0 33%;
        padding: 0;
    }
    body>section {
        padding-top: 35px;
    }
    .shop-below-title {
        font-size: 14px;
        max-width: 224px;
    }
    .recommend-icon {
        font-size: 65px;
        height: 105px;
    }
    .recommend_box {
        width: auto;
    }
    .segment_box {
        padding-top: 12px !important;
        min-height: 210px;
    }
    .Shop_heading, .Filter_heading, .Our_heading, .shopIn_heading {
        padding-top: 48px;
    }
	.categories_search_bar .flex_div {
		display: block;
	}
	h2.shopData.shop_head.shopby_head{
		margin: 0 auto !important;
	}	
	
	
}


.button_box .coman_btn {
    padding: 10px 15px;
    font-size: 12px;
    color: #fff;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 1.2px;
    border: none;
    outline: none;
    box-shadow: 0 2px 10px rgba(0, 27, 63, 0.40) !important;
    height: 36px;
    width: 100%;
    border: none;
    transition: ease-in-out .5s;
    -webkit-transition: ease-in-out .5s;
    -moz-transition: ease-in-out .5s;
    border-radius: 4px;
}
.blue_btn {
    background: #21E1E1;
    background: linear-gradient(#21E1E1,#232f3e);
    max-width: 150px;
    margin-left: 1px;
    border: none;
}
.blue_btn.btn.active, .blue_btn.btn:active {
    background: -webkit-linear-gradient(#1bb0f4, #017fb9);
    background: linear-gradient(#1bb0f4, #017fb9);
    background: -ms-linear-gradient(#1bb0f4, #017fb9);
    background: -moz-linear-gradient(#1bb0f4, #017fb9);
}
.blue_btn:hover {
    background: linear-gradient(#232f3e, #21E1E1);
    background: -webkit-linear-gradient(#0382bc, #1eb5fa);
    background: -ms-linear-gradient(#0382bc, #1eb5fa);
    background: -moz-linear-gradient(#0382bc, #1eb5fa);
    color: #fff;
}
.Darkblue_btn {
    background: -webkit-linear-gradient(#232f3e, #21E1E1);
    background: linear-gradient(#232f3e, #21E1E1);
    background: -ms-linear-gradient(#232f3e, #21E1E1);
    background: -moz-linear-gradient(#232f3e, #21E1E1);
    box-shadow: 0 2px 5px rgba(0, 27, 63, 0.17);
    border: none;
}
.Darkblue_btn:hover{
    
    background: -webkit-linear-gradient(#21E1E1, #232f3e);
    background: linear-gradient(#21E1E1, #232f3e);
    background: -ms-linear-gradient(#04afc6, #18ddf7);
    background: -moz-linear-gradient(#04afc6, #18ddf7);
    box-shadow: 0 2px 5px rgba(0, 27, 63, 0.17);
}
.dropdown-menu {
    border: none;
    margin-top:0;
    transition: ease-in-out .4s;
    -webkit-transition: ease-in-out .4s;
    position: absolute;
    background-color: #fff;
    box-shadow: 0px 0px 10px 0px hsla(0, 0%, 1%, 0.19);
    height: auto;
    border-bottom: 3px solid #017fb9 !important;
    border-radius: 4px;
    padding: 0;
    min-width: 270px;
}

/* Tooltip Css Start */
.tooltip_box {
    position: relative;
    display: inline-block;
    opacity: 1;
    font-family: 'Montserrat', sans-serif !important;
}
.Ptxt_box .tooltip_box {
    display: block;
}
.tooltip_box .tooltiptext_box {
    visibility: hidden;
    width:225px;
    background-color: #212121;
    color: #03a9f5;
    font-size: 13.17px;
    text-align: center;
    font-weight: 500;
    border-radius: 4px;
    padding: 12px;
    line-height: 17.24px;
    position: absolute;
    z-index: 15!important;
    top:21px;
    left: 0;
    margin-left: 0;
    opacity: 0;
    transition: opacity 0.3s;
    box-shadow: 0 3px 8px rgba(0, 0, 0, 0.25);

}
.tooltiptext_box.tooltip-top {
    bottom:90%;
    left: 0;
    top: auto;
}
.tooltip_box .tooltip-bottom {
    top: auto !important;
    bottom: -70px;
}
.Sub_menu .tooltip_box .tooltiptext_box {
    width: 165px;
    top: 30px;
    left: 144px;
}
.tooltiptext_box.tooltip-top:after{
    top: 100% !important;
    border-color: #212121 transparent transparent transparent !important;
}
.tooltip_box:hover .tooltiptext_box {
    visibility: visible;
    opacity: 1;
}
.tooltiptext_box p {
    font-size: 13px !important;
    color: #9fbfeb !important;
    margin: 0;
    text-transform: none;
    letter-spacing: 0.3px !important;
    line-height: 17.24px !important;
    font-weight: 500;
    white-space: normal;
}
.tooltip_table {
    margin: 5px 0;
}
.tooltip_table td {
    color: #fff;
    font-size: 11px;
    text-transform: uppercase;
    font-weight: 500;
    padding: 2px;
    border: 1px solid #515151;
    letter-spacing: 0.7px;
}
.Price_txt p img {
    float: right;
}
.tooltiptext_box h5 {
    font-size: 16.17px;
    margin: 0;
    color: #fff;
    font-weight: 600;
}
.tooltip_box .tooltiptext_box::after {
    content: "";
    position: absolute;
    top: -13px;
    left:13px;
    margin-left: 0;
    border-width:7px;
    border-style: solid;
    border-color: transparent transparent #212121 transparent;
}
.desc_section .Grid_Description .tooltip_box img {
    max-width: 30px;
}
/* Tooltip Css Closed */

/* Shopping_Cart Css */
.Shopping_Cart {
    position: fixed;
    right: -133px;
    top: 25%;
    width: 100%;
    max-width:200px;
    z-index: 999;
    transition: ease-in-out 0.7s;
}
.Shopping_Cart:hover{
    right: 0;
}
.Shopping_Cart .Cart_box a i{
    transition: ease-in-out .5s;
}
.Shopping_Cart .Cart_box a {
    position: relative;
    display: table;
    width:100%;
    color: #fff;
    vertical-align: middle;
    font-size: 25px;
    height: 67px;
    padding: 5px 5px 5px 10px;
    border-radius: 10px 1px 1px 10px;
    max-width: 200px;
    margin: 0 0 0 auto;
}
.Shopping_Cart  .First_div, .Shopping_Cart  .last_div {
    display: table-cell;
}
.Shopping_Cart  .First_div {
    vertical-align: bottom;
    padding-right:15px;
}
.Shopping_Cart .last_div {
    text-align: center;
    vertical-align: middle;
    padding-right:5px;
}
.Shopping_Cart p{
    font-size: 12px;
    font-weight: 600;
    margin: 0;
    color: #08587d;
    line-height: 16.24px;
    letter-spacing: 0.4px;
    text-transform: uppercase;
}
.Shopping_Cart  h3{
    font-size: 16px;
    margin: 0;
    color: #fff;
    font-weight: 600;
    letter-spacing: 0.5px;
}
.Shopping_Cart .noti_spn {
    position: absolute;
    top:15px;
    background: #7db945;
    height: 27px;
    width: 27px;
    color: #fff;
    text-align: center;
    border-radius: 50px;
    font-size: 13px;
    font-weight: 500;
    left: 25px;
    box-shadow: 0 0 24px rgba(0, 0, 0, 0.37);
    padding: 4px 2px;
}
.transition {
    transition: ease-in-out .5s;
    -webkit-transition: ease-in-out .5s;
    -moz-transition: ease-in-out .5s;
    -ms-transition: ease-in-out .5s;
}
.Shopping_Cart .Cart_box a:hover .noti_spn, .head_icons .Heart_icon a:hover .noti_spn {
    transform: scale(1.2,1.2);
    -webkit-transform: scale(1.2,1.2);
    -ms-transform: scale(1.2,1.2);
    -moz-transform: scale(1.2,1.2);
}
/* Shopping_Cart Css */

/* pagination Css */

.pagination_box {
    padding: 25px 0;
}
.pagination_box .pagination {
    width: 100%;
}
.pagination_box .pagination>li {
    display: inline-block;
}
.pagination_box .pagination li a {
    color:#2c3128;
    font-size: 14px;
    font-weight:500;
    padding: 8px;
    line-height: normal;
}
.pagination_box .pagination .active a {
    color: #07326c;
    font-weight: bold;
    background: none;
}
.pagination_box .pagination li a:hover{
    color:#03a9f5;
}
.pagination_box  .pagination>li>a, .pagination_box  .pagination>li>span {
    background: none;
    border: none;
}
.pagination_box .first .page-link, .pagination_box .last .page-link{
    background: #fff;
    box-shadow: 0 2px 7px rgba(0, 0, 0, 0.11);
    border-radius: 25px !important;
    padding: 10px 30px;
    color:#2c3128;
    font-size: 12px;
    font-weight: 600;
}
.pagination_box .first .page-link:hover, .pagination_box .last .page-link:hover{
    color:#03a9f5;
}
.pagination_box .prev .page-link, .pagination_box .next .page-link{
    /*background: #fff url(../img/arrow_l.png) no-repeat !important;*/
    background-position: center !important;
    font-size: 0;
    padding: 18px;
    border-radius: 50%;
    box-shadow: 0 2px 7px rgba(0, 0, 0, 0.11);
    margin: 0 15px;
}
.pagination_box .next .page-link{
    /*background: #fff url(../img/arrow_r.png) no-repeat !important;*/
    background-position: center !important;
}
/* pagination Css */
.list-unstyled li {
    vertical-align: middle;
    padding:0;
}
.Form_box {
    background: #fefefe;
    padding: 15px;
    box-shadow: 0 0 11px rgba(0, 0, 0, 0.20);
    margin: -30px 0 20px;
    border-radius: 4px;
}

.Form_box .button_box .coman_btn {
    font-size:12px;
    height: 50px;
    max-width: 100%;
    padding: 15px;
}
.Form_box .Form_row .Form_col{
    padding: 0 3px;
}
.Form_box .navbar-form{
    padding:0;
    margin:0;
    position:relative;
}
.navbar-form .input-group {
    position: static;
    width: 100%;
}
.navbar-form .search_btn{
    position: absolute;
    right: 0;
    max-width: 45px;
    top: 0;
    z-index:2 !important;
    padding: 10px 15px;
    box-shadow: none !important;
    border-radius:5px;
    height: 50px;
    border:none;
    }
.navbar-form .search_btn i{
    color:#fff;
}
.Grid{
    position:relative;
}
.Grid i {
    font-size: 23px;
    padding: 0 6px;
    color:#9b9797;
}
.Grid_active i{
    background: linear-gradient(#21E1E1,#232f3e);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.Grid i:hover{
    background: linear-gradient(#1eb5fa, #2997c9);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.Grid.List:before{
    content: '2';
    position: absolute;
    width: 1px;
    height: 30px;
    right: 0;
    top: -9px;
    left: -1px;
    font-size: 0;
    background: -webkit-linear-gradient(rgba(183, 231, 254, 0.6901960784313725), #56bdec, rgba(183, 231, 254, 0.59));
}
.box_row{
    display: flex;
    width: 100%;
    margin: 0;
}
.box_row .box_col{
    display: table-cell;
    float: none;
    padding: 0 0px;
    vertical-align: middle;
}
.txt_red{
    color:#ff0000;
    padding:0 2px;
}
.label_txt {
    color: #272829;
    font-size: 14px;
    font-weight: 600;
    line-height: 16.24px;
    letter-spacing: 0.2px;
    margin-bottom: 6px;
    display: inline-block;
}
/*newcss*/
select.form-control{
    /*background: #f8fafc url(../img/arrow.png) no-repeat;*/
    background-size: 10px;
    background-position: right 10px center;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}
.dropdown .form-control {
    text-align: left;
}
.dropdown .form-control span {
    padding-right: 5px;
}
.coman_btn.dropdown-toggle:after, .dropdown .form-control:after, .logged_btn .dropdown-toggle:after{
    content: "";
    display: inline-block;
    width: auto;
    height: auto;
    vertical-align: 0.20em;
    border-top: 5px solid;
    border-right: 5px solid transparent;
    border-bottom: 0;
    border-left: 5px solid transparent;
    right: 10px;
    position: absolute;
    top: 7px;
}
.logged_btn .dropdown-toggle:after{
    top: 15px;
    color: #fff;
}
.dropdown .form-control:after{
    top:22px;
}
.form_cont_menu>li>a {
    display: block;
    white-space: nowrap;
    font-size: 13.17px;
    color: #596068;
    line-height: 19.24px;
    letter-spacing: 0.4px;
    font-weight: 500;
    padding: 10px 15px;
    background: none !important;
    border-bottom: 1px solid #eee;
    text-transform: none;
}
.form_cont_menu>li>a {
    color: #07326c;
}

.closeBtnData {
    opacity: 1;
    color: #fff;
    box-shadow: none;
    text-shadow: none;
    font-size: 23px;
    position: absolute;
    top: 20px;
    right: 15px;
    outline: none;
    padding: 0;
    cursor: pointer;
    background: transparent;
    border: 0;
    -webkit-appearance: none;
    margin-top: -2px;
    float: right;
    font-weight: 700;
    line-height: 1;
}

/*newcss*/
.filterBar {
    font-size: 14px !important;
    width: 90% !important;

}
.sortBar {
    width: 90% !important;
    font-size: 14px !important;
    margin-left:-27px ;
}
.vendorFilterBar {
    font-size: 14px !important;
}
.vendorSortBar {
    width: 100% !important;
  
    font-size: 14px !important;
}
.form-control {
    background: #f8fafc;
    border: none;
    outline: none;
    height: 50px;
    font-size: 13px;
    color: #262728;
    font-weight: 500;
    line-height: 16.24px;
    letter-spacing: 0.2px;
    border-radius: 2px;
    box-shadow: 0 1px 2px rgba(41, 42, 43, 0.30) !important;
}
.navbar-collapse {
    padding: 0;
}
.black {
    color: #181b1c;
}



/*=======  =======*/

.loader {
    margin: 15px 0;
}
.form_sec {
    padding: 30px 0px;
}
.form_toptxt .form_head {
    color: rgb(22, 25, 26);
    font-size: 18px;
    font-weight: bolder;
    line-height: 18.24px;
    letter-spacing: 0.3px;
    margin: 0px 0px 15px;
}
.form_toptxt .form_summary {
    color: rgb(39, 44, 46);
    font-size: 13px;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.2px;
    margin: 0px;
}
.form_toptxt .Darkblue_btn {
    display: inline-block;
    height: 35px;
    width: 35px;
    text-align: center;
    color: rgb(255, 255, 255);
    margin-right: 7px;
    padding: 7px;
    border-radius: 100%;
}
.wht_box {
    box-shadow: rgba(0, 27, 63, 0.3) 0px 1px 4px;
    background: rgb(255, 255, 255);
    padding: 15px 16px;
    margin: 20px 0px;
    border-radius: 3px;
}
.margin_4 {
    margin-left: -4px;
    margin-right: -4px;
}
.margin_4 > div {
    padding-left: 4px;
    padding-right: 4px;
}
.form_subhading {
    font-weight: 600;
    font-size: 16px;
    color: rgb(39, 40, 41);
    margin: 0px 0px 5px;
}
.form_toptxt .form_subhead {
    color: rgb(24, 25, 26);
    font-size: 16px;
    font-weight: 600;
    line-height: 23px;
    letter-spacing: 0.3px;
    margin: 0px;
}
.btn_dflt {
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 600;
    color: rgb(255, 255, 255);
    text-shadow: rgba(0, 0, 0, 0.28) 1px 1px 1px;
    letter-spacing: 1px;
    min-width: 185px;
    padding: 15px;
}
.grey_btn {
    box-shadow: rgba(0, 0, 0, 0.22) 1px 1px 3px;
    color: rgb(30, 32, 33);
    text-shadow: rgb(255, 255, 255) 1px 2px 1px;
    background: linear-gradient(rgb(254, 254, 254), rgb(229, 231, 233));
    border:none;
}


/* Custom Check box Css Start */
.letter_box {
    background: #ffffff;
    box-shadow: 0 0 7px rgba(0, 0, 0, 0.15);
    border-radius: 3px;
    color: #26282a;
    font-weight: 600;
    height: 65px;
    padding: 15px;
}
.lable_con {
    width: auto;
    display: inline-block;
    position: relative;
    padding-left: 35px;
    margin: 8px 0;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.lable_con input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
    display: none;
}
.checkmark {
    position: absolute;
    top: -4px;
    left: 0;
    height: 28px;
    width: 28px;
    border-radius: 2px;
    box-shadow: 0 1px 3px rgba(0, 27, 63, 0.40);
    background: #fbfcfc;
    background: -webkit-linear-gradient(#fbfcfc, #e5e7e9);
    background: -ms-linear-gradient(#fbfcfc, #e5e7e9);
    background: -moz-linear-gradient(#fbfcfc, #e5e7e9);
}
.lable_con input:checked ~ .checkmark {
    background: #1bb0f4;
    background: -webkit-linear-gradient(#1bb0f4, #017fb9);
    background: linear-gradient(#1bb0f4, #017fb9);
    background: -ms-linear-gradient(#1bb0f4, #017fb9);
    background: -moz-linear-gradient(#1bb0f4, #017fb9);
}
.lable_con input:checked ~ .checkbox_txt {
    color:#017fb9;
}
.lable_con input:checked ~ .checkmark:after {
    display: block;
}
.lable_con .checkmark:after {
    left: 11px;
    top: 6px;
    width: 6.5px;
    height: 13px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}
.checkbox_txt {
    font-size: 15px;
    font-weight: 500;
    color: #222;
    letter-spacing: 0.3px;
    display: inline-block;
    vertical-align: middle;
}
/* Custom Check box Css Closed */
.radio_boxes input:checked,
.radio_boxes input:not(:checked) {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
    display: none;
}
.radio_boxes input:checked + label,
.radio_boxes input:not(:checked) + label
{
    position: relative;
    padding-left: 28px;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
    color: #666;
}
.radio_boxes input:checked ~ label:before,
.radio_boxes input:not(:checked) ~ label:before {
    content: '';
    position: absolute;
    left: 22px;
    top: 14px;
    width: 21px;
    height: 20px;
    border: 2px solid #5b5e5f;
    border-radius: 100%;
    background: none;
}
.radio_boxes input:checked ~ label:after,
.radio_boxes input:not(:checked) ~ label:after {
    content: '';
    position: absolute;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
    left: 29.3px;
    top: 17px;
    width: 6px;
    height: 11px;
    border: solid #5b5e5f;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}
.radio_boxes input:not(:checked) ~ label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
}
.radio_boxes input:checked ~ label:after {
    opacity: 1;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}
.radio_boxes input:checked  ~ label:after {
    border: solid #fff;
    border-width: 0 2px 2px 0;
}
.radio_boxes input:checked  ~ label:before{
    border: 2px solid #fff;
}
.radio_boxes input:checked  ~ label{
    background: -webkit-linear-gradient(#1bb0f4, #017fb9);
    background: linear-gradient(#1bb0f4, #017fb9);
    background: -ms-linear-gradient(#1bb0f4, #017fb9);
    background: -moz-linear-gradient(#1bb0f4, #017fb9);
    color: #fff;
}
.radio_boxes .btn_box label{
    height: 50px;
    max-width: 100px;
    width: 100%;
    box-shadow: 0 1px 3px rgba(0, 27, 63, 0.40);
    background: #fbfcfc;
    background: -webkit-linear-gradient(#fbfcfc, #e5e7e9);
    background: -ms-linear-gradient(#fbfcfc, #e5e7e9);
    background: -moz-linear-gradient(#fbfcfc, #e5e7e9);
    margin-right: 2px;
    border: none;
    padding: 15px 15px 15px 33px;
    text-align: center;
    color: #343637;
    font-size: 13px;
    font-weight: 600;
    border-radius: 2px;
    text-transform: uppercase;
}
.radio_boxes .btn_box span {
    padding-left: 5px;
}

.margin_10{
    margin:0 -8px;
}
.margin_10 >div{
    padding:0 8px;
}
/*======Radio closed here =====*/


.form_toptxt .Darkblue_btn {
    display: inline-block;
    padding:7px;
    border-radius: 100%;
    height: 35px;
    width: 35px;
    text-align: center;
    color: #fff;
    margin-right: 7px;
}
.form_toptxt .Darkblue_btn i {
    font-size: 14px;
}
.custom_box {
    display: inline-block;
    float: right;
}
.cstm_radio2 input:checked ~ .check_mark, .cstm_radio2 input:not(:checked) ~ .check_mark{
    position: relative;
    padding-left:26px;
    cursor: pointer;
    display: inline-block;
    padding-right: 10px;
}
.cstm_radio2 input:not(:checked) ~ .check_mark:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
}
.cstm_radio2 input:checked ~ .check_mark:after, .cstm_radio2 input:not(:checked) ~ .check_mark:after {  
    content: '';
    width: 11px;
    height: 11px;
    background: #fff;
    position: absolute;
    top: 7px;
    left: 5px;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}
.cstm_radio2 .label_txt {
    display: inline-block;
    vertical-align: middle;
    margin: 0;
}
.cstm_radio2 input {
    position: absolute;
    opacity: 0;
    height: 0;
    width: 0;
}
.cstm_radio2 input:checked  ~ .check_mark :before, .cstm_radio2 input:not(:checked) ~ .check_mark:before {
    content: '';
    position: absolute;
    left: 0;
    top: 2px;
    width: 21px;
    height: 21px;
    display: inline-block;
    border-radius: 50px;
    background: #1bb0f4;
    background: -webkit-linear-gradient(#232f3e, #21E1E1);
    background: linear-gradient(#232f3e, #21E1E1);
    background: -ms-linear-gradient(#232f3e, #21E1E1);
    background: -moz-linear-gradient(#232f3e, #21E1E1);
}

/* === Categories Popup css Start === */
.modal-open {
    padding-right: 0 !important;
}
.modal-lg {
    width: 100% !important;
    max-width: 950px;
    margin: 10px auto;
    padding: 0 10px;
}
.Categories_Popup .modal-content {
    border-radius: 7px;
}
.Categories_Popup .modal-header {
    color: #fff;
    text-align: center;
    padding: 11px 15px;
    position: relative;
    border-radius: 7px 7px 0 0;
    margin: 0;
    max-width: 100%;
}
.Categories_Popup .modal-body {
    padding: 10px 15px;
}
.Categories_Popup .modal-title {
    font-size: 22px;
    font-weight: 600;
    line-height: normal;
    padding: 9px 0;
}
.Categories_Popup .modal-header .close {
    opacity: 1;
    color: #fff;
    box-shadow: none;
    text-shadow: none;
    font-size: 23px;
    position: absolute;
    top: 20px;
    right: 15px;
    outline: none;
}
.panel-group .panel {
    border: none;
}
.collapse_menu {
    max-height: 205px;
    overflow-y: auto;
    padding: 5px;
}
.panel-body {
    background: #fff;
    box-shadow: 0 0 9px rgba(0, 0, 0, 0.20);
    margin-top: 3px;
    padding: 8px 12px;
}
.heading_control{
    max-height:260px;
    overflow-y: auto;
    padding: 5px 5px 0;
}
.panel_heading{
    background: #fff;
    padding: 0;
    border: none;
    margin:4px 0;
    border-radius:3px;
    box-shadow: 0 0 9px rgba(0, 0, 0, 0.20);
    overflow: hidden;
}

.noti_spn.drop_value {
    top: 31px;
    right: 270px;
    background: #119ddd;
    height: 27px;
    width: 27px;
    color: #fff;
    text-align: center;
    border-radius: 50px;
    font-size: 11px;
    font-weight: 500;
    padding: 6px 3px;
}





.modal-bodyData {
    text-align: left!important;
}

.panel_heading:hover{
    background: linear-gradient(to bottom,#80d0f5, #017fb9);
}
.accordion_txt a {
    font-size: 16px;
    color: #414445;
    font-weight: 500;
    padding: 13px 15px;
    display: block;
    line-height: normal;
    color: #fff;
}
.Sub_Category {
    font-size: 18px;
    margin: 5px 0;
    font-weight: 500;
    color: #1bb0f4;
    text-align: center;
}
.panel_title{
    padding: 12px 15px;
    display: table-cell;
    vertical-align: middle;
    width: 5%;
    background: linear-gradient(to bottom,#80d0f5 ,#017fb9 100%);
}
.accordion_txt {
    display: table-cell;
    vertical-align: middle;
}
.panel_title i {
    font-size: 18px;
    color: #fff;
}
.shadow_box .SubCtg {
    background: #fff;
    box-shadow: 0 0 9px rgba(0, 0, 0, 0.20);
    margin: 4px 0;
    border-radius: 4px;
    overflow: hidden;
    font-size: 14px;
    color: #414445;
    font-weight: 500;
    padding:0 0 0 15px;
    display: block;
}
.shadow_box .SubCtg .span_box {
    padding: 11px 0;
    display: inline-block;
}
.doted_icon {
    float: right;
    padding: 21px 12px;
    background: linear-gradient(to bottom,#80d0f5 ,#017fb9 100%);
}
.doted_icon i{
    font-size:18px;
    color: #fff;
    line-height: 0;
    display: block;
}
.shadow_box>.active .doted_icon i, .shadow_box .SubCtg:hover .doted_icon i{
    color:#fff;
}
.shadow_box .SubCtg:hover, .shadow_box>.active, .collapse_btn .accordion_txt a{
    color: #fff;
    background: -webkit-linear-gradient(#1bb0f4, #017fb9);
    background: linear-gradient(#1bb0f4, #017fb9);
    background: -ms-linear-gradient(#1bb0f4, #017fb9);
    background: -moz-linear-gradient(#1bb0f4, #017fb9);
}
.shadow_box .SubCtg:hover:before{
    color: #fff;
}
.panel_group{
    padding:0 5px;
}
/* === Categories Popup css Closed === */

.margin_2{
margin-left: -2px;
margin-right: -2px;
}
.margin_2>div{
padding-left: 2px;
padding-right: 2px;
}
.mrb_15{
    margin-bottom: 15px;
}
.form-group {
    margin-bottom: 10px;
}
.blu_btn_shadow{
    box-shadow: rgba(0, 0, 0, 0.22) 1px 2px 5px;
}
.grey_btn {
    box-shadow: 0 1px 3px rgba(0,0,0,.3);
}
.form-control:focus {
    border: 2.5px solid #21E1E1 !important;
    box-shadow: 0 1px 2px rgba(41, 42, 43, 0.60) !important;
}
.grey_btn:hover{
    background: linear-gradient(#21E1E1, #232f3e);
    
    color: #fff;
    text-shadow: none;
}
.form_toptxt .Darkblue_btn i {
    text-shadow: 1px 1px 1px rgba(4, 31, 35, 0.22);
}
.cstm_radio2 input:checked ~ .check_mark :before, .cstm_radio2 input:not(:checked) ~ .check_mark:before{
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.17);
}

.uprcase{
    text-transform: uppercase;
}
.blue_txt {
    color: #1A4D2E !important;
}
.blue_txt:hover{
        color: #21E1E1 !important;
}
.semi{
  font-weight: 600;
}
.medium{
    font-weight: 500;
}
.mrgn_t10{
    margin-top: 10px;
}
.red {
    color: #ff0600;
}
.blue {
    color: #03a9f5;
}
.no_wrap{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.blk_btn{
    display: block;
    width: 100%;
    max-width: 100% !important;
}
.btn_dflt{
      border-radius: 3px;
      text-align: center;
}
.mrgn_t5{
    margin-top: 5px;
}
.mrgn_t15{
        margin-top: 15px;
}
.info_box1 {
    border-bottom:2px solid #f0f3f7;
}
.box_block {
    display: inline-block;
    width: 100%;
}
.margin_10 .Number_value {
    padding-right: 5px;
}
.margin_10 .Number_value1 {
    padding-left: 5px;
}

/* ===== Media CSS Start here ===== */

@media (max-width:1199px){
    .blue_btn{
        max-width:125px;
    }
    .pagination_box {
        padding: 10px 0;
    }
    .button_box .coman_btn {
        padding:10px 5px;
        font-size: 11px;
    }
    .box_row .box_col{
        text-align:center;
    }
    .Grid.List:before{
        display:none;
    }
}

@media (max-width:991px){
    .button_box .coman_btn{
        height:34px;
        font-size: 10px;
    }
    .Sub_menu .tooltip_box .tooltiptext_box {
        left: 5px;
    }
    .Form_box .button_box .coman_btn, .Form_box .form-control {
        font-size: 12px;
    }
    .blue_btn {
        max-width: 98px;
    }
    .tooltip_box .tooltiptext_box::after {
        left: 0;
        right: 0;
        max-width: 5px;
        margin: 0 auto;
    }
    .dark_green .tooltiptext_box {
        left: auto !important;
        right: -10px;
    }
    .Red_box .tooltiptext_box.tooltip-top {
        left: auto !important;
        right: -25px;
    }
    .Ptxt_box .tooltiptext_box.tooltip-top{
        left:0 !important;
    }
    .Red_box .tooltiptext_box::after{
        left: auto;
        right: 25px;
    }
    .dark_green .tooltiptext_box::after{
        right: 10px;
        margin: 0 0 0 auto;
    }
    .Ptxt_box .tooltip_box .tooltiptext_box::after {
        left: 10px;
        margin: 0 auto 0 0;
    }
    .tooltip_box .tooltiptext_box{
        left: -75px;
    }
    .desc_section .Grid_Description .tooltip_box img {
        max-width: 30px;
    }
    .tooltiptext_box.tooltip-top {
        left: -100px !important;
    }
    .Form_box .button_box .coman_btn {
        padding: 17px 5px;
    }
    .tooltip_box .tooltip-bottom::after {
        left: auto;
        right:25px;
    }
    .tooltip_box .tooltip-bottom {
        left: auto;
        right: 0;
    }
    /*-----*/
    .modal-lg {
        max-width: 725px;
    }
}

@media (max-width:767px){
    .main_content {
        margin-top: 160px;
    }
    .partner_footer_label {
    height: 36px;
    bottom: 0%;
    font-size: 13px;
    width: 100%;
}
    .partner_contentCookies {
    letter-spacing: 0px;
    }

    .shop_box {
        width: 100%;
    }
    .top_banner {
        margin-top:44px;
    }
    .blue_btn {
        max-width: 120px;
    }
    .navbar-form .search_btn{
        top:5px;
    }
    .Form_box .button_box .coman_btn, .Form_box .form-control {
        margin: 5px 0;
    }
    .pagination_box .prev .page-link, .pagination_box .next .page-link {
        padding: 16px;
        margin: 0 8px;
        background-size: 13px !important;
    }
    .pagination_box .next .page-link{
        background-size: 13px !important;
    }
    .pagination_box {
        padding: 0 10px;
    }
    .pagination_box .pagination li a{
        font-size:13px;
        padding: 7px 8px;
    }
    .pagination_box .first .page-link, .pagination_box .last .page-link{
        padding: 9px 15px;
        font-size: 12px;
    }
    .navbar-toggle {
        margin: 13px 10px;
        border:none;
        border-radius:5px;
    }
    .navbar-toggle .icon-bar{
        background: #636363;
    }
    .tooltip_box .tooltip-bottom {
        left: 0 !important;
    }
    .tooltip_box .tooltip-bottom::after {
        left: 10px;
        right: auto;
    }
/*-----*/
    .collapse_menu {
        margin-bottom: 5px;
    }
    .Categories_Popup .modal-title {
        font-size: 20px;
        padding: 5px 0;
    }
    .Categories_Popup .modal-header .close {
        font-size: 20px;
    }
    .panel-title>a {
        padding: 20px 15px;
    }
    .panel-title {
        font-size: 14px;
    }
    .shadow_box a{
        padding: 15px;
        margin: 4px 0;
        font-size: 12px;
    }
    .panel-body{
        padding:5px;
    }
    .Categories_Popup .modal-body {
        padding: 15px;
    }
}

@media (max-width:425px){

    .categories_search_bar .wht_bx {
        padding: 15px 20px;
        height: 150px;
    }
    .btns_div .btn {
        padding: 11px 35px 11px 14px;
        font-size: 13px;
        letter-spacing: 0;
        height: 43px;
        margin: 0 0;
        min-width: 110px;
        border-radius: 11px;
    }
    .btns_div {
        margin: 15px 5px 0;
    }
    
    .pagination_box .first .page-link, .pagination_box .last .page-link {
        padding: 9px 12px;
        font-size: 10px;
    }
    .pagination_box .prev .page-link, .pagination_box .next .page-link {
        padding: 15px;
        margin: 0 2px;
    }
    .main_content {
        margin-top:215px;
    }
    .margin_10 .Number_value {
        padding-right:3px;
    }
    .margin_10 .Number_value1 {
        padding-left:3px;
    }
}

/* ===== Media CSS Closed here ===== */
