/**
 *  If you want to override some bootstrap variables, you have to change values here.
 *  The list of variables are listed here bower_components/bootstrap-sass/assets/stylesheets/bootstrap/_variables.scss
 */


a:hover {
    text-decoration: none !important;
    cursor:pointer;
}

a:focus {
    text-decoration: none !important;
}

*:focus {
    outline: none;
}

$min768: "only screen and (min-width : 768px)";

/**
 * App-Wide Variables
 */


/**
 * Mix-Ins
 */

@mixin transition($args...) {
    -webkit-transition: $args;
    -moz-transition: $args;
    -ms-transition: $args;
    -o-transition: $args;
    transition: $args;
}
@mixin scale($args...){
    -webkit-transform:scale($args);
    -moz-transform: scale($args);
    -o-transform: scale($args);
    -ms-transform: scale($args);
    transform: scale($args);
}

@mixin border-radius($radius) {
    -webkit-border-radius: $radius;
    -moz-border-radius: $radius;
    -ms-border-radius: $radius;
    border-radius: $radius;
}

.display-inline {
    display: inline-block;
    vertical-align: middle;
}

.olul-nostyle {
    list-style: none;
    margin: 0;
    padding: 0
}

@mixin position-absolute($top, $right, $bottom, $left) {
    position: absolute;
    top: $top;
    right: $right;
    bottom: $bottom;
    left: $left;
}

@mixin width-height($width, $height) {
    width: $width;
    height: $height;
}

@mixin anchor-block( $top, $right, $bottom, $left) {
    display: block;
    padding: $top $right $bottom $left;
}

@mixin anchor-inline( $top, $right, $bottom, $left) {
    display: inline-block;
    padding: $top $right $bottom $left;
}

@mixin max-width($maxWidth) {
    width: 100%;
    max-width: $maxWidth;
    text-align: center;
    margin: 0 auto;
}

@mixin flex-center($height) {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    flex-direction: column;
    -webkit-justify-content: center;
    -moz-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -moz-box-align: center;
    align-items: center;
    height: $height;
}

@mixin box-shadow($hshadow, $vshadow, $blur, $spread, $color) {
    box-shadow: $hshadow $vshadow $blur $spread $color;
}

@mixin scale($scale) {
    -webkit-transform: scale(1.5);
    -moz-transform: scale(1.5);
    -ms-transform: scale(1.5);
    transform: scale(1.5)
}

@mixin slick-arrow($width, $height, $bgColor, $top) {
    background-color: $bgColor;
    width: $width;
    height: $height;
    line-height: $height;
    border: none;
    text-align: center;
    position: absolute;
    top: $top;
    &:hover {
        background-color: transparent;
        &:before {
            color: $black;
        }
    }
    &:before {
        font-family: 'fontAWesome';
        color: $black;
        font-size: 36px;
    }
    z-index: 70;
}

@mixin media-queries($media){
    @if($media == lg){
         @media only screen and (min-width : 1200px){@content};
    }
    @if($media == md){
         @media only screen and (min-width : 992px) and (max-width : 1199px) {@content};
    }
    @if($media == sm){
         @media only screen and (min-width : 768px) and (max-width : 991px) {@content};
    }
    @if($media == xs){
         @media only screen and (max-width : 767px) {@content};
    }
}


/**
 *  Do not remove the comments below. It's the markers used by wiredep to inject
 *  sass dependencies when defined in the bower.json of your dependencies
 */

// bower:scss
// endbower

/**
 *  Do not remove the comments below. It's the markers used by gulp-inject to inject
 *  all your sass files automatically
 */

// injector
// endinjector


.mid_container {

    background-position: 0% 0;
    background-attachment: fixed;
    background-size: 990PX;
}

#root{
    /*background : url('data:image/jpeg;base64,/9j/4QAYRXhpZgAASUkqAAgAAAAAAAAAAAAAAP/sABFEdWNreQABAAQAAAA8AAD/7gAOQWRvYmUAZMAAAAAB/9sAhAAGBAQEBQQGBQUGCQYFBgkLCAYGCAsMCgoLCgoMEAwMDAwMDBAMDg8QDw4MExMUFBMTHBsbGxwfHx8fHx8fHx8fAQcHBw0MDRgQEBgaFREVGh8fHx8fHx8fHx8fHx8fHx8fHx8fHx8fHx8fHx8fHx8fHx8fHx8fHx8fHx8fHx8fHx//wAARCAAsACwDAREAAhEBAxEB/8QAbAABAQEBAQAAAAAAAAAAAAAAAgMBAAgBAQEBAAAAAAAAAAAAAAAAAAEAAhAAAQMDBAECBAcAAAAAAAAAAQARAiExEkEiAxNRYZHwcYFSoTJCcjMEFBEBAQEBAQAAAAAAAAAAAAAAAAERMRL/2gAMAwEAAhEDEQA/APTcwRzAmLsS0vCQ3vJkGDg0k/lVilbxglyARFiAFIIS65SJji9yNQkF25ZCQeNDFBbjLret3UXQ5Mtp3teQt8gqiHjC930F0FOXMRLAHACtfiq1ILSjITqQQPu8+qE3GMbB/QWQU/8ATJ30dsdfZaxnVZSBjsYAFiPCCEpHrBDA1Z/oqJQSiYAziHFTqykPJJ6isdGURnKQ5Ri1bi3soK7HyYfvZBRjw08Alz4TowpHhkOv8dFIDxFgH22I0IVqwo8cYsZFgBQILZR4uXcLg0SB6p5XLfbqnR5MmMpmEjuLsyCI/ryAYz2elCfmrVhxkz4mjIIYQ5QQJNIGuvung63qx3cknawFArVhZSxZ6eEFM/zVa5xe7pCh7M4tZt7qQxbI+MaNZSZw/nlbLVrsqqFv3ZNg+11IP0au6k//2Q==');*/
    /*background :url('data:image/webp;base64,UklGRnQAAABXRUJQVlA4TGcAAAAvn8AnAA/w+P/H/z/+f/7jAY5r21aaKe42dZlBCy4jFh1ALRrXBqySrPR2U0FWRP+vABF9CHsPXWVOSgfdKCSECV1CuvcWOnM2hhW6+CukDHRN8Bgl6H4jVPg+J9CJhdGP0P1t/xsBAA==')*/
}

.loader_sec {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index:9999999999;
    background: rgba(255, 255, 255, 0.83);
}

.loader_con {
    display: table;
    width: 100%;
    height: 100%;
}

.loader_inner {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
}
.loader_inner img{
    width:100px;
}

.md-dialog-container {
    top: 0!important;
}

.md-backdrop.md-dialog-backdrop.md-opaque {
    position: static !important;
}
.md-dialog-container {
    height: 100% !important;
    background: rgba(51, 51, 51, 0.55);
    position: fixed !important;
}

.table_box a.Red_box {
    word-break: break-all;
}

.body{
    width: 100%;
    height: 100%;
    /*font-family: 'Montserrat', sans-serif !important;
    font-weight: 400;*/
    font-family: 'Open Sans', sans-serif!important;
    font-weight: 100!important;
    background-position: center !important;
    font-size: 0;
    overflow-x: hidden;
    overflow-y: auto!important;
    background-color:transparent;

}

body button.focus, .btn:focus {
    outline: none !important;
    outline-offset: 0 !important;
}
ul, ol{
    margin:0;
    padding:0;
}
a {
    text-decoration: none !important;
    transition: ease-in-out .5s;
    -webkit-transition: ease-in-out .5s;
    -moz-transition: ease-in-out .5s;
    -ms-transition: ease-in-out .5s;
}
a {
    cursor: pointer;
}
a:focus {
    outline: 0px !important;
    text-decoration: none;
}
body, h1, h2, h3, h4, h5, h6 {
    font-weight: 400;
}
img {
    max-width: 125%;
}
::placeholder {
  color: #7f7a84 !important;
    font-weight: normal;;
}
.flex-direction-nav a:before{
    display:none;
}
.flex-direction-nav a:after{
    display:none;
}
table{
    width:100%;
}
/* ========== */
.button_box .coman_btn {
    padding: 10px 15px;
    font-size: 12px;
    color: #fff;
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 1.2px;
    border: none;
    outline: none;
    box-shadow: 0 2px 10px rgba(0, 27, 63, 0.40) !important;
    height: 36px;
    width: 100%;
    border: none;
    transition: ease-in-out .5s;
    -webkit-transition: ease-in-out .5s;
    -moz-transition: ease-in-out .5s;
    border-radius: 4px;
}
.blue_btn {
    background: #1bb0f4;
    background: -webkit-linear-gradient(#1bb0f4, #017fb9);
    background: linear-gradient(#1bb0f4, #017fb9);
    background: -ms-linear-gradient(#1bb0f4, #017fb9);
    background: -moz-linear-gradient(#1bb0f4, #017fb9);
    max-width: 150px;
    margin-left: 1px;
    border: none;
}
.blue_btn.btn.active, .blue_btn.btn:active {
    background: -webkit-linear-gradient(#1bb0f4, #017fb9);
    background: linear-gradient(#1bb0f4, #017fb9);
    background: -ms-linear-gradient(#1bb0f4, #017fb9);
    background: -moz-linear-gradient(#1bb0f4, #017fb9);
}
.blue_btn:hover {
    background: linear-gradient(#0382bc, #1eb5fa);
    background: -webkit-linear-gradient(#0382bc, #1eb5fa);
    background: -ms-linear-gradient(#0382bc, #1eb5fa);
    background: -moz-linear-gradient(#0382bc, #1eb5fa);
    color: #fff;
}
.Darkblue_btn {
    background: #18ddf7;
    background: -webkit-linear-gradient(#18ddf7, #04afc6);
    background: linear-gradient(#18ddf7, #04afc6);
    background: -ms-linear-gradient(#18ddf7, #04afc6);
    background: -moz-linear-gradient(#18ddf7, #04afc6);
    box-shadow: 0 2px 5px rgba(0, 27, 63, 0.17);
    border: none;
}
.Darkblue_btn:hover{
    background: #04afc6;
    background: -webkit-linear-gradient(#04afc6, #18ddf7);
    background: linear-gradient(#04afc6, #18ddf7);
    background: -ms-linear-gradient(#04afc6, #18ddf7);
    background: -moz-linear-gradient(#04afc6, #18ddf7);
    box-shadow: 0 2px 5px rgba(0, 27, 63, 0.17);
}
.dropdown-menu {
    border: none;
    margin-top:0;
    transition: ease-in-out .4s;
    -webkit-transition: ease-in-out .4s;
    position: absolute;
    background-color: #fff;
    box-shadow: 0px 0px 10px 0px hsla(0, 0%, 1%, 0.19);
    height: auto;
    border-bottom: 3px solid #017fb9 !important;
    border-radius: 4px;
    padding: 0;
    min-width: 270px;
}

/* Tooltip Css Start */
.tooltip_box {
    position: relative;
    display: inline-block;
    opacity: 1;
    font-family: 'Montserrat', sans-serif !important;
}
.Ptxt_box .tooltip_box {
    z-index: 1;
    display: block;
}
.tooltip_box .tooltiptext_box {
    visibility: hidden;
    width:270px;
    background-color: #212121;
    color: #03a9f5;
    font-size: 13.17px;
    text-align: center;
    font-weight: 500;
    border-radius: 4px;
    padding: 12px;
    line-height: 17.24px;
    position: absolute;
    z-index: 99 !important;
    top:21px;
    left: 0;
    margin-left: 0;
    opacity: 0;
    transition: opacity 0.3s;
    box-shadow: 0 3px 8px rgba(0, 0, 0, 0.25);
}
.tooltiptext_box.tooltip-top {
    bottom:90%;
    left: 0;
    top: auto;
}
.tooltip_box .tooltip-bottom {
    top: auto !important;
    bottom: -70px;
}
.Sub_menu .tooltip_box .tooltiptext_box {
    width: 165px;
    top: 30px;
}
.tooltiptext_box.tooltip-top:after{
    top: 100% !important;
    border-color: #212121 transparent transparent transparent !important;
}
.tooltip_box:hover .tooltiptext_box {
    visibility: visible;
    opacity: 1;
}
.tooltiptext_box p {
    font-size: 13px !important;
    color: #9fbfeb !important;
    margin: 0;
    text-transform: none;
    letter-spacing: 0.3px !important;
    line-height: 17.24px !important;
    font-weight: 500;
    white-space: normal;
}
.tooltip_table {
    margin: 5px 0;
}
.tooltip_table td {
    color: #fff;
    font-size: 11px;
    text-transform: uppercase;
    font-weight: 500;
    padding: 2px;
    border: 1px solid #515151;
    letter-spacing: 0.7px;
}
.Price_txt p img {
    float: right;
}
.tooltiptext_box h5 {
    font-size: 16.17px;
    margin: 0;
    color: #fff;
    font-weight: 600;
}
.tooltip_home {
    left: -194px !important;
}
.tooltip_home::after {
    left: 170px !important;
}
.tooltip_box .tooltiptext_box::after {
    content: "";
    position: absolute;
    top: -13px;
    left:13px;
    margin-left: 0;
    border-width:7px;
    border-style: solid;
    border-color: transparent transparent #212121 transparent;
}
.desc_section .Grid_Description .tooltip_box img {
    max-width: 30px;
}
/* Tooltip Css Closed */

/* Shopping_Cart Css */
.Shopping_Cart {
    position: fixed;
    right: -133px;
    top: 25%;
    width: 100%;
    max-width:200px;
    z-index: 9999;
    transition: ease-in-out 0.7s;
}
.Shopping_Cart:hover{
    right: 0;
}
.Shopping_Cart .Cart_box a i{
    transition: ease-in-out .5s;
}
.Shopping_Cart .Cart_box a {
    position: relative;
    display: table;
    width:100%;
    color: #fff;
    vertical-align: middle;
    font-size: 25px;
    height: 67px;
    padding: 5px 5px 5px 10px;
    border-radius: 10px 1px 1px 10px;
    max-width: 200px;
    margin: 0 0 0 auto;
}
.Shopping_Cart  .First_div, .Shopping_Cart  .last_div {
    display: table-cell;
}
.Shopping_Cart  .First_div {
    vertical-align: bottom;
    padding-right:15px;
}
.Shopping_Cart .last_div {
    text-align: center;
    vertical-align: middle;
    padding-right:5px;
}
.Shopping_Cart p{
    font-size: 12px;
    font-weight: 600;
    margin: 0;
    color: #08587d;
    line-height: 16.24px;
    letter-spacing: 0.4px;
    text-transform: uppercase;
}
.Shopping_Cart  h3{
    font-size: 16px;
    margin: 0;
    color: #fff;
    font-weight: 600;
    letter-spacing: 0.5px;
}
.Shopping_Cart .noti_spn {
    position: absolute;
    top:15px;
    background: #7db945;
    height: 27px;
    width: 27px;
    color: #fff;
    text-align: center;
    border-radius: 50px;
    font-size: 13px;
    font-weight: 500;
    left: 25px;
    box-shadow: 0 0 24px rgba(0, 0, 0, 0.37);
    padding: 4px 2px;
}
.transition {
    transition: ease-in-out .5s;
    -webkit-transition: ease-in-out .5s;
    -moz-transition: ease-in-out .5s;
    -ms-transition: ease-in-out .5s;
}
.Shopping_Cart .Cart_box a:hover .noti_spn, .head_icons .Heart_icon a:hover .noti_spn {
    transform: scale(1.2,1.2);
    -webkit-transform: scale(1.2,1.2);
    -ms-transform: scale(1.2,1.2);
    -moz-transform: scale(1.2,1.2);
}
/* Shopping_Cart Css */

/* pagination Css */

.pagination_box {
    padding: 25px 0;
}
.pagination_box .pagination {
    width: 100%;
}
.pagination_box .pagination>li {
    display: inline-block;
}
.pagination_box .pagination li a {
    color:#2c3128;
    font-size: 14px;
    font-weight:500;
    padding: 8px;
    line-height: normal;
}
.pagination_box .pagination li a {
    border-radius: 19px;
    width: 34px;
    text-align: center;
    border: 1px solid #232f3e !important;
    height: 34px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 2px;
    background: #d5dce1 !important;
}
body .pagination_box .pagination .active a{
    background: #839aa8 !important;
    color: #ffffff;
}
.pagination_box .pagination li a:hover{
    color:#03a9f5;
}
.pagination_box .pagination li.empty a {
    background: #ffdae8 !important;
    border-color: #e798b6 !important;
}
.pagination_box  .pagination>li>a, .pagination_box  .pagination>li>span {
    background: none;
    border: none;
}
.pagination_box .first .page-link, .pagination_box .last .page-link{
    background: #fff;
    box-shadow: 0 2px 7px rgba(0, 0, 0, 0.11);
    border-radius: 25px !important;
    padding: 10px 30px;
    color:#2c3128;
    font-size: 12px;
    font-weight: 600;
}
.pagination_box .first .page-link:hover, .pagination_box .last .page-link:hover{
    color:#03a9f5;
}
body .pagination_box .pagination li:nth-child(2) a, body .pagination_box .pagination li:nth-last-child(2) a{
    background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBzdGFuZGFsb25lPSJubyI/Pgo8IURPQ1RZUEUgc3ZnIFBVQkxJQyAiLS8vVzNDLy9EVEQgU1ZHIDIwMDEwOTA0Ly9FTiIKICJodHRwOi8vd3d3LnczLm9yZy9UUi8yMDAxL1JFQy1TVkctMjAwMTA5MDQvRFREL3N2ZzEwLmR0ZCI+CjxzdmcgdmVyc2lvbj0iMS4wIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciCiB3aWR0aD0iMjQuMDAwMDAwcHQiIGhlaWdodD0iMjYuMDAwMDAwcHQiIHZpZXdCb3g9IjAgMCAyNC4wMDAwMDAgMjYuMDAwMDAwIgogcHJlc2VydmVBc3BlY3RSYXRpbz0ieE1pZFlNaWQgbWVldCI+Cgo8ZyB0cmFuc2Zvcm09InRyYW5zbGF0ZSgwLjAwMDAwMCwyNi4wMDAwMDApIHNjYWxlKDAuMTAwMDAwLC0wLjEwMDAwMCkiCmZpbGw9IiMwMDAwMDAiIHN0cm9rZT0ibm9uZSI+CjxwYXRoIGQ9Ik01OCAxODEgYy0zMSAtMjAgLTU3IC00MyAtNTcgLTUxIDAgLTggMjcgLTMyIDU5IC01MiBsNjAgLTM3IDAgMjQKYzAgMjQgMyAyNSA2MCAyNSBsNjAgMCAwIDQwIDAgNDAgLTU5IDAgYy01NSAwIC02MCAyIC02MyAyMyAtMyAyMyAtMyAyMyAtNjAKLTEyeiIvPgo8L2c+Cjwvc3ZnPgo=')
     no-repeat !important;
    background-position: center !important;
    font-size: 0;
    padding: 18px;
    border-radius: 50%;
    box-shadow: 0 2px 7px rgba(0, 0, 0, 0.11);
    margin: 0 15px;
}
body .pagination_box .pagination li:nth-last-child(2) a{
    background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBzdGFuZGFsb25lPSJubyI/Pgo8IURPQ1RZUEUgc3ZnIFBVQkxJQyAiLS8vVzNDLy9EVEQgU1ZHIDIwMDEwOTA0Ly9FTiIKICJodHRwOi8vd3d3LnczLm9yZy9UUi8yMDAxL1JFQy1TVkctMjAwMTA5MDQvRFREL3N2ZzEwLmR0ZCI+CjxzdmcgdmVyc2lvbj0iMS4wIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciCiB3aWR0aD0iMjQuMDAwMDAwcHQiIGhlaWdodD0iMjYuMDAwMDAwcHQiIHZpZXdCb3g9IjAgMCAyNC4wMDAwMDAgMjYuMDAwMDAwIgogcHJlc2VydmVBc3BlY3RSYXRpbz0ieE1pZFlNaWQgbWVldCI+Cgo8ZyB0cmFuc2Zvcm09InRyYW5zbGF0ZSgwLjAwMDAwMCwyNi4wMDAwMDApIHNjYWxlKDAuMTAwMDAwLC0wLjEwMDAwMCkiCmZpbGw9IiMwMDAwMDAiIHN0cm9rZT0ibm9uZSI+CjxwYXRoIGQ9Ik0xMjAgMTk1IGMwIC0yNCAtMyAtMjUgLTYwIC0yNSBsLTYwIDAgMCAtNDAgMCAtNDAgNjAgMCBjNTcgMCA2MCAtMQo2MCAtMjUgbDAgLTI0IDYwIDM3IGMzMiAyMCA1OSA0NCA1OSA1MiAwIDggLTI3IDMyIC01OSA1MiBsLTYwIDM3IDAgLTI0eiIvPgo8L2c+Cjwvc3ZnPgo=') 
    no-repeat !important;
    background-position: center !important;
}
/* pagination Css */
.list-unstyled li {
    vertical-align: middle;
    padding:0;
    font-weight: bold;
    color: #232f3e;
}
.Form_box {
    background: #fefefe;
    padding: 15px;
    box-shadow: 0 0 11px rgba(0, 0, 0, 0.20);
    margin: -30px 0 20px;
    border-radius: 4px;
}

.modal-backdrop.show {
    opacity: 0!important;
}

.Form_fixed {
    margin:0 auto!important;
    position: fixed;
    top: 90px;
    width: 100%;
    max-width: 1320px;
    left: 0;
    right: 0;
    z-index: 99999;
}

.Form_holder .form-control {
   border: 1px solid #07326c;
   border-radius: 0 0 2px 2px;
}

.Form_box .button_box .coman_btn {
    font-size:14px;
    height: 50px;
    max-width: 100%;
    padding: 15px;
}

.Form_box .Form_row .Form_col{
    padding: 0 3px;
}
.Form_box .navbar-form{
    padding:0;
    margin:0;
    position:relative;
}
.navbar-form .input-group {
    position: static;
    width: 100%;
}
.navbar-form .search_btn{
    position: absolute;
    right: 0;
    max-width: 45px;
    top: 0;
    z-index:2 !important;
    padding: 10px 15px;
    box-shadow: none !important;
    border-radius: 0 2px 2px 0;
    height: 50px;
    border:none;
    }
.navbar-form .search_btn i{
    color:#fff;
}
.Grid{
    position:relative;
}
.Grid i {
    font-size: 23px;
    padding: 0 6px;
    color:#9b9797;
}
.Grid_active i{
    background: linear-gradient(#1eb5fa, #2997c9);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.Grid i:hover{
    background: linear-gradient(#1eb5fa, #2997c9);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.modal-open .modal {
    z-index: 31!important;
}

.modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto;
}

.fade.in {
    opacity: 1;
}

.Grid.List:before{
    content: '2';
    position: absolute;
    width: 1px;
    height: 30px;
    right: 0;
    top: -9px;
    left: -1px;
    font-size: 0;
    background: -webkit-linear-gradient(rgba(183, 231, 254, 0.6901960784313725), #56bdec, rgba(183, 231, 254, 0.59));
}
.box_row{
    display: table;
    width: 100%;
    margin: 0;
}
.box_row .box_col{
    display: table-cell;
    float: none;
    padding: 0 12px;
    vertical-align: middle;
}
.txt_red{
    color:#ff0000;
    padding:0 2px;
}
.label_txt {
    color: #272829;
    font-size: 14px;
    font-weight: 600;
    line-height: 16.24px;
    letter-spacing: 0.2px;
    margin-bottom: 6px;
    display: inline-block;
    padding:0px;
}

span #siteseal {
    position: fixed;
    bottom: 0;
    left: 0;
}
/*newcss*/
select.form-control{
    /*background: #f8fafc url(../img/arrow.png) no-repeat;*/
    background-size: 10px;
    background-position: right 10px center;
    /*-webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;*/
}
.dropdown .form-control {
    text-align: left;
}
.dropdown .form-control span {
    padding-right: 5px;
}
.coman_btn.dropdown-toggle:after, .dropdown .form-control:after, .logged_btn .dropdown-toggle:after{
    content: "";
    display: inline-block;
    width: auto;
    height: auto;
    vertical-align: 0.20em;
    border-top: 5px solid;
    border-right: 5px solid transparent;
    border-bottom: 0;
    border-left: 5px solid transparent;
    right: 10px;
    position: absolute;
    top: 7px;
}
.logged_btn .dropdown-toggle:after{
    top: 15px;
    color: #fff;
}
.dropdown .form-control:after{
    top:22px;
}
.form_cont_menu>li>a {
    display: block;
    white-space: nowrap;
    font-size: 13.17px;
    color: #596068;
    line-height: 19.24px;
    letter-spacing: 0.4px;
    font-weight: 500;
    padding: 10px 15px;
    background: none !important;
    border-bottom: 1px solid #eee;
    text-transform: none;
}
.form_cont_menu>li>a {
    color: #07326c;
}
/*newcss*/
.form-control {
    background: #f8fafc;
    border: none;
    outline: none;
    height: 50px;
    font-size: 13px;
    color: #262728;
    font-weight: bold !important;
    line-height: 16.24px;
    letter-spacing: 0.2px;
    border-radius: 2px;
    box-shadow: 0 1px 2px rgba(41, 42, 43, 0.30) !important;
}
.navbar-collapse {
    padding: 0;
}
.black {
    color: #181b1c;
}

 


/*=======  =======*/
.loader {
    margin: 15px 0;
}
.form_sec {
    padding: 30px 0px;
}
.form_toptxt .form_head {
    color: rgb(22, 25, 26);
    font-size: 18px;
    font-weight: 600;
    line-height: 18.24px;
    letter-spacing: 0.3px;
    margin: 0px 0px 15px;
}
.form_toptxt .form_summary {
    color: rgb(39, 44, 46);
    font-size: 13px;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.2px;
    margin: 0px;
}
.form_toptxt .Darkblue_btn {
    display: inline-block;
    height: 35px;
    width: 35px;
    text-align: center;
    color: rgb(255, 255, 255);
    margin-right: 7px;
    padding: 7px;
    border-radius: 100%;
}
.wht_box {
    box-shadow: rgba(0, 27, 63, 0.3) 0px 1px 4px;
    background: rgb(255, 255, 255);
    padding: 15px 16px;
    margin: 20px 0px;
    border-radius: 3px;
}
.margin_4 {
    margin-left: -4px !important;
    margin-right: -4px !important;
}
.margin_4 > div {
    padding-left: 4px !important;
    padding-right: 4px !important;
}
.form_subhading {
    font-weight: 600;
    font-size: 16px;
    color: rgb(39, 40, 41);
    margin: 0px 0px 5px;
}
.form_toptxt .form_subhead {
    color: rgb(24, 25, 26);
    font-size: 16px;
    font-weight: 600;
    line-height: 23px;
    letter-spacing: 0.3px;
    margin: 0px;
}
.btn_dflt {
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 600;
    color: rgb(255, 255, 255);
    text-shadow: rgba(0, 0, 0, 0.28) 1px 1px 1px;
    letter-spacing: 1px;
    min-width: 185px;
    padding: 15px;
}
.grey_btn {
    box-shadow: rgba(0, 0, 0, 0.22) 1px 1px 3px;
    color: rgb(30, 32, 33);
    text-shadow: rgb(255, 255, 255) 1px 2px 1px;
    background: linear-gradient(rgb(254, 254, 254), rgb(229, 231, 233));
    border:none;
}

/* .mrgn_none{
    margin: 0px !important;
}*/




/* Custom Check box Css Start */
.letter_box {
    background: #ffffff;
    box-shadow: 0 0 7px rgba(0, 0, 0, 0.15);
    border-radius: 3px;
    color: #26282a;
    font-weight: 600;
    height: 65px;
    padding: 15px;
}
.lable_con {
    width: auto;
    display: inline-block;
    position: relative;
    padding-left: 35px;
    margin: 8px 0;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.lable_con input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
    display: none;
}
.checkmark {
    position: absolute;
    top: -4px;
    left: 0;
    height: 28px;
    width: 28px;
    border-radius: 2px;
    box-shadow: 0 1px 3px rgba(0, 27, 63, 0.40);
    background: #fbfcfc;
    background: -webkit-linear-gradient(#fbfcfc, #e5e7e9);
    background: -ms-linear-gradient(#fbfcfc, #e5e7e9);
    background: -moz-linear-gradient(#fbfcfc, #e5e7e9);
}
.lable_con input:checked ~ .checkmark {
    background: #1bb0f4;
    background: -webkit-linear-gradient(#1bb0f4, #017fb9);
    background: linear-gradient(#1bb0f4, #017fb9);
    background: -ms-linear-gradient(#1bb0f4, #017fb9);
    background: -moz-linear-gradient(#1bb0f4, #017fb9);
}
.lable_con input:checked ~ .checkbox_txt {
    color:#017fb9;
}
.lable_con input:checked ~ .checkmark:after {
    display: block;
}
.lable_con .checkmark:after {
    left: 11px;
    top: 6px;
    width: 6.5px;
    height: 13px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}
.checkbox_txt {
    font-size: 15px;
    font-weight: 500;
    color: #222;
    letter-spacing: 0.3px;
    display: inline-block;
    vertical-align: middle;
}
/* Custom Check box Css Closed */
.radio_boxes input:checked,
.radio_boxes input:not(:checked) {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
    display: none;
}
.radio_boxes input:checked + label,
.radio_boxes input:not(:checked) + label
{
    position: relative;
    padding-left: 28px;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
    color: #666;
}
.radio_boxes input:checked ~ label:before,
.radio_boxes input:not(:checked) ~ label:before {
    content: '';
    position: absolute;
    left: 22px;
    top: 14px;
    width: 21px;
    height: 20px;
    border: 2px solid #5b5e5f;
    border-radius: 100%;
    background: none;
}
.radio_boxes input:checked ~ label:after,
.radio_boxes input:not(:checked) ~ label:after {
content: '';
    position: absolute;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
    left: 29.3px;
    top: 17px;
    width: 6px;
    height: 11px;
    border: solid #5b5e5f;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}
.radio_boxes input:not(:checked) ~ label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
}
.radio_boxes input:checked ~ label:after {
    opacity: 1;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}
.radio_boxes input:checked  ~ label:after {
    border: solid #fff;
    border-width: 0 2px 2px 0;
}
.radio_boxes input:checked  ~ label:before{
    border: 2px solid #fff;
}
.radio_boxes input:checked  ~ label{
    background: -webkit-linear-gradient(#1bb0f4, #017fb9);
    background: linear-gradient(#1bb0f4, #017fb9);
    background: -ms-linear-gradient(#1bb0f4, #017fb9);
    background: -moz-linear-gradient(#1bb0f4, #017fb9);
    color: #fff;
}
.radio_boxes .btn_box label{
    height: 50px;
    max-width: 100px;
    width: 100%;
    box-shadow: 0 1px 3px rgba(0, 27, 63, 0.40);
    background: #fbfcfc;
    background: -webkit-linear-gradient(#fbfcfc, #e5e7e9);
    background: -ms-linear-gradient(#fbfcfc, #e5e7e9);
    background: -moz-linear-gradient(#fbfcfc, #e5e7e9);
    margin-right: 2px;
    border: none;
    padding: 15px 15px 15px 33px;
    text-align: center;
    color: #343637;
    font-size: 13px;
    font-weight: 600;
    border-radius: 2px;
    text-transform: uppercase;
}
.radio_boxes .btn_box span {
    padding-left: 5px;
}

.margin_10{
    margin:0 -8px;
}
.margin_10 >div{
    padding:0 8px;
}
/*======Radio closed here =====*/


.form_toptxt .Darkblue_btn {
    display: inline-block;
    padding:7px;
    border-radius: 100%;
    height: 35px;
    width: 35px;
    text-align: center;
    color: #fff;
    margin-right: 7px;
}
.form_toptxt .Darkblue_btn i {
    font-size: 14px;
}

.form-group {
    margin-bottom:20px;
}
.custom_box {
    display: inline-block;
    float: right;
}
.cstm_radio2 input:checked ~ .check_mark, .cstm_radio2 input:not(:checked) ~ .check_mark{
    position: relative;
    padding-left:26px;
    cursor: pointer;
    display: inline-block;
    padding-right: 10px;
}
.cstm_radio2 input:not(:checked) ~ .check_mark:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
}
.cstm_radio2 input:checked ~ .check_mark:after, .cstm_radio2 input:not(:checked) ~ .check_mark:after {  
    content: '';
    width: 11px;
    height: 11px;
    background: #fff;
    position: absolute;
    top: 10px;
    left: 5px;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}
.cstm_radio2 .label_txt {
    display: inline-block;
    vertical-align: middle;
    margin: 0;
}
.cstm_radio2 input {
    position: absolute;
    opacity: 0;
    height: 0;
    width: 0;
}
.cstm_radio2 input:checked  ~ .check_mark :before, .cstm_radio2 input:not(:checked) ~ .check_mark:before {
    content: '';
    position: absolute;
    left: 0;
    top: 5px;
    width: 21px;
    height: 21px;
    display: inline-block;
    border-radius: 50px;
    background: #1bb0f4;
    background: -webkit-linear-gradient(#1bb0f4, #017fb9);
    background: linear-gradient(#1bb0f4, #017fb9);
    background: -ms-linear-gradient(#1bb0f4, #017fb9);
    background: -moz-linear-gradient(#1bb0f4, #017fb9);
}

/* === Home Page Popup  css Start === */
.modal-open {
    padding-right: 0 !important;
}
.modal-lg {
    width: 100% !important;
    max-width: 950px;
    margin: 10px auto;
    padding: 0 10px;
}
.Categories_Popup .modal-content {
    border-radius: 7px;
}
.Categories_Popup .modal-header {
    color: #fff;
    text-align: center;
    padding: 11px 15px;
    position: relative;
    border-radius: 7px 7px 0 0;
    margin: 0;
    max-width: 100%;
}
.shadow_box .panel-heading, .SubCtg_box{
    border-radius: 4px;
    margin: 5px 0;
    display: block;
    font-size: 14px;
    font-weight: 500;
    color: #616669;
    height: 55px;
    line-height: 16px;
}
.collapse_menu {
    max-height:300px;
    overflow-y: auto;
    padding: 6px;
}
.margin_auto{
    margin:0 -7px;
}
.margin_auto>div{
    padding:0 5px;
}
.shadow_box .panel-heading:hover, .SubCtg_box:hover{
    color: #fff;
    background: -webkit-linear-gradient(#1bb0f4, #017fb9);
    background: linear-gradient(#1bb0f4, #017fb9);
    background: -ms-linear-gradient(#1bb0f4, #017fb9);
    background: -moz-linear-gradient(#1bb0f4, #017fb9);
}
.shadow_box .panel-heading:hover :before{
    color: #fff;
}
.shadow_box .panel-heading:hover a{
    color:#fff;
}
.Categories_Popup .modal-body {
    padding: 20px 15px;
}
.panel-body {
    background: #fff;
    box-shadow: 0 0 9px rgba(0, 0, 0, 0.20);
    margin-top: 3px;
    padding: 8px 12px;
}
.Categories_Popup .modal-title {
    font-size: 22px;
    font-weight: 600;
    line-height: normal;
    padding: 9px 0;
}
.Categories_Popup .modal-header .close {
    opacity: 1;
    color: #fff;
    box-shadow: none;
    text-shadow: none;
    font-size: 23px;
    position: absolute;
    top: 20px;
    right: 15px;
    outline: none;
}
.SubCtg_box {
    padding: 20px 15px !important;
}
.panel-default>.panel-heading, .SubCtg_box{
    background: #fff;
    box-shadow: 0 0 9px rgba(0, 0, 0, 0.20);
    padding: 0 0 0 15px;
    border: none;
}
.panel-title {
    margin: 0;
    display: table;
    width: 100%;
}
.accordion_txt a {
    font-size: 16px;
    color: #414445;
    font-weight: 500;
}
.panel-title>a{
    padding: 25px 15px;
    text-align: right;
    display: table-cell;
    vertical-align: middle;
    width: 10%;
    border-left: 5px solid #EEF;
    background-color: #1bb0f4;
}
.shadow_box .panel-title a {
    padding: 20px 15px;
}
.panel-group .panel {
    border: none;
}
/*.panel-title > a i {
    float: right;
    color: white;
    font-family: "Font Awesome 5 Free";
    display: inline-block;
    padding-right: 19px;
    vertical-align: middle;
    font-weight: 900;
    content: "\f068";
    font-size: 17px;
}*/

.panel-title > a.collapsed:before {
    content: "\f067";
}

.panel-title > a:before {
    float: right;
    color: black;
    font-family: "Font Awesome 5 Free";
    display: inline-block;
    padding-right: 3px;
    vertical-align: middle;
    font-weight: 900;
    content: "\f068";
    font-size: 14px;
}

.panel_heading.active {
    background: #07326c;
}
.panel_heading.active a{
    color:#fff;
}
/*new*/
.Categories_Popup .panel-group {
    /*max-height:450px;
    overflow-y: auto;*/
    padding: 5px;
    margin-bottom: 10px;
}
.Sub_Category {
    margin-top: 0;
    font-size: 18px;
    text-align: center;
    color: #1bb0f4;
    font-weight: 600;
}
.accordion_txt {
    display: table-cell;
    vertical-align: middle;
}
/*new*/
/* === Home Page Popup  css Start === */

/*.pr_result_slider .main_slider .slick-item {
    width: 372px !important;
}

.pr_result_slider .slick-track {
    transform: translate3d(0px, 0px, 0px) !important;
    width: auto !important;
}*/

.pro_imghold .img_cellholder {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    position: relative;
}

.Images_slide .slider_secMidd span {
    display: table-cell;
    vertical-align: middle;
}

.Images_slide .slick-track {
    padding-bottom: 8px;
}


.MainGrid_txt {
    background: #d5dce1;
    position: relative;
}

.pro_imghold {
    height: 285px;
    background: #fff;
    display: table;
    width: 100%;
}

.modal-open {
    padding-right: 0 !important;
}
.modal-lg {
    width: 100% !important;
    max-width: 950px;
    margin: 10px auto;
    padding: 0 10px;
}
.Categories_Popup .modal-content {
    border-radius: 7px;
}
.Categories_Popup .modal-header {
    color: #fff;
    text-align: center;
    padding: 11px 15px;
    position: relative;
    border-radius: 7px 7px 0 0;
    margin: 0;
    max-width: 100%;
}
.Categories_Popup .modal-body {
    padding: 10px 15px;
}
.Categories_Popup .modal-title {
    font-size: 22px;
    font-weight: 600;
    line-height: normal;
    padding: 9px 0;
}
.Categories_Popup .modal-header .close {
    opacity: 1;
    color: #fff;
    box-shadow: none;
    text-shadow: none;
    font-size: 23px;
    position: absolute;
    top: 20px;
    right: 15px;
    outline: none;
}
.panel-group .panel {
    border: none;
}
.collapse_menu {
    max-height: 205px;
    overflow-y: auto;
    padding: 5px;
}
.panel-body {
    background: #fff;
    box-shadow: 0 0 9px rgba(0, 0, 0, 0.20);
    margin-top: 3px;
    padding: 8px 12px;
}
.heading_control{
    max-height:260px;
    overflow-y: auto;
    padding: 5px 5px 0;
}
.panel_heading{
    padding: 0;
    border: none;
    margin:4px 0;
    background:#fff;
    border-radius:3px;
    box-shadow: 0 0 9px rgba(0, 0, 0, 0.20);
    overflow: hidden;
}
.panel_heading:hover, .panel_heading.active {
    background: linear-gradient(to bottom, #92dafb, #0098de);
}
.accordion_txt a {
    font-size: 16px;
    color: #222;
    font-weight: 500;
    padding: 15px 15px;
    display: block;
    line-height: normal;
}
.panel_heading:hover .accordion_txt a {
    color:#fff;
}
.Sub_Category {
    font-size: 18px;
    margin: 5px 0;
    font-weight: 500;
    color: #1bb0f4;
    text-align: center;
}
.panel_title{
    padding: 12px 15px;
    display: table-cell;
    vertical-align: middle;
    width: 5%;
    background: linear-gradient(to bottom,#80d0f5 ,#017fb9 100%);
}
.accordion_txt {
    display: table-cell;
    vertical-align: middle;
}
.panel_title i {
    font-size: 18px;
    color: #fff;
}
.shadow_box .SubCtg {
    background: #fff;
    box-shadow: 0 0 9px rgba(0, 0, 0, 0.20);
    margin: 4px 0;
    border-radius: 4px;
    overflow: hidden;
    font-size: 14px;
    color: #414445;
    font-weight: 500;
    padding:0 0 0 15px;
    display: block;
}
.shadow_box .SubCtg .span_box {
    padding: 11px 0;
    display: inline-block;
}
.doted_icon {
    float: right;
    padding: 21px 12px;
    background: linear-gradient(to bottom,#80d0f5 ,#017fb9 100%);
}
.doted_icon i{
    font-size:18px;
    color: #fff;
    line-height: 0;
    display: block;
}
.shadow_box>.active .doted_icon i, .shadow_box .SubCtg:hover .doted_icon i{
    color:#fff;
}
.shadow_box .SubCtg:hover, .shadow_box>.active, .collapse_btn .accordion_txt a{
    color: #fff;
    background: -webkit-linear-gradient(#1bb0f4, #017fb9);
    background: linear-gradient(#1bb0f4, #017fb9);
    background: -ms-linear-gradient(#1bb0f4, #017fb9);
    background: -moz-linear-gradient(#1bb0f4, #017fb9);
}
.shadow_box .SubCtg:hover:before{
    color: #fff;
}
.panel_group{
    padding:0 5px;
}

.pro_imghold .pro_img {
    max-height: 277px;
}

.grid_slider.thumb_slider{
        display: none !important;
    
}
.thumb_hidden{
    overflow: hidden !important;
    position: relative;
}



.margin_2{
margin-left: -2px !important;
margin-right: -2px !important;
}
.margin_2>div{
padding-left: 2px;
padding-right: 2px;
}
.mrb_15{
    margin-bottom: 15px;
}
.form-group {
    margin-bottom: 10px;
    padding:0px !important;
}
.blu_btn_shadow{
    box-shadow: rgba(0, 0, 0, 0.22) 1px 2px 5px;
}
.grey_btn {
    box-shadow: 0 1px 3px rgba(0,0,0,.3);
}
.form-control:focus {
    box-shadow: 0 1px 2px rgba(41, 42, 43, 0.60) !important;
}
.grey_btn:hover{
    background: linear-gradient( #1bb0f4, #017fb9);
    background: -webkit-linear-gradient( #1bb0f4, #017fb9);
    background: -moz-linear-gradient( #1bb0f4, #017fb9);
    background: -ms-linear-gradient( #1bb0f4, #017fb9);
        color: #fff;
    text-shadow: none;
}
.form_toptxt .Darkblue_btn i {
    text-shadow: 1px 1px 1px rgba(4, 31, 35, 0.22);
}
.cstm_radio2 input:checked ~ .check_mark :before, .cstm_radio2 input:not(:checked) ~ .check_mark:before{
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.17);
}

.uprcase{
    text-transform: uppercase;
}
.blue_txt {
    color: #03a9f5;
}
.blue_txt:hover{
        color: #07326c;
}
.semi{
  font-weight: 600;
}
.medium{
    font-weight: 500;
}
.mrgn_t10{
    margin-top: 10px;
}
.red {
    color: #ff0600;
}
.blue {
    color: #03a9f5;
}
.no_wrap{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.blk_btn{
    display: block;
    width: 100%;
    max-width: 100% !important;
}
.btn_dflt{
      border-radius: 3px;
      text-align: center;
}
.mrgn_t5{
    margin-top: 5px;
}
.mrgn_t15{
        margin-top: 15px;
}
.info_box1 {
    border-bottom:2px solid #f0f3f7;
}


.modal-open .modal {
    z-index: 999999999999999999 !important;
}

.Register_form .form-group {
    margin-left: 0;
    margin-right: 0;
}
.Register_form .letter_box{
    display: block;
    position: relative;
    height: auto;
}
.Register_form .letter_box label.lable_con {
    padding: 0px 0px 0px 35px !important;
    margin: 0px;
    vertical-align: middle;
}
.Register_form .letter_box label.lable_con .checkmark {
    top: 0;
}
.cap_block > div {
    margin: 0 auto;
    width:  fit-content ;
}




.section_img_product .main_slider .slick-track {
    display: table;
}
.section_img_product .main_slider .slick-item {
    display: table-cell !important;
    vertical-align: middle !important;
}


.recommend_drop {
    width: 100%;
    max-width: 200px;
    text-align: left;
    vertical-align: middle;
    padding: 0;
    margin: 0;
    word-break: break-word;
    background: transparent;
}

.recommend_drop>a {
    width: 100%;
    display: block;
    padding:0 !important;
}
.inner_act{
    display:none !important;
}
.inner_act{
    display: block !important;
    color: #000;
}

.table_box a.Red_box {
    word-break: break-all;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 130px;
}


/*--- Jan 9 css start here ---*/
.right_user .user_name {
    white-space: nowrap;
    
    text-overflow: ellipsis;
    max-width: 227px;
    display: inline-block;
}
.right_user .user_name .tooltip_box .tooltiptext_box {
    width: 220px;
}

.Grid_Boxes >.container>.row {
    margin-left: -8px;
    margin-right: -8px;
}

.MainGrid_txt{
    margin:1px;
}
.Grid_Description{
    margin:1px;
}

.logged_btn {
    display: block!important;
    max-width: 235px;
    padding-right: 10px;
}

.main_header .list-inline .active a {
    color: #2d2828;
}

.activeTab{
    color: #03a9f5;
}

.sale_bar {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: #ee1d23;
    text-transform: uppercase;
    font-size: 13pt;
    font-weight: bold;
    color: #fff;
}
.red_barsale {
    display: block;
    padding: 2px 16px;
    text-align:left;
}
.sale_bar span.disount_sec {
    float: right;
    position:relative;
}
.sale_bar .disount_sec .discount_txt {
    position: absolute;
    right: 43px;
    top: -17px;
    background: #ee1d23;
    font-size: 26pt;
    padding: 2px 12px;
    border-radius: 0 0 4px 4px;
    bottom: 0px;
}

.Top_Bar .media:active .media-body h3 {
    color: #fff;
    font-weight: 500;
}

.banner_box .banner_txt .slick-prev {
    font-size: 32px;
    left: 0;
    top: 35%;
    position: absolute;
}
    
.banner_box .banner_txt .slick-next {
    right: 0;
    font-size: 32px;
    top: 35%;
    position: absolute;
}   

.logged_btn .user_txt {
    font-size: 12px;
    font-weight: 500;
    color: #fff;
    text-transform: uppercase;
    letter-spacing: 1.2px;
    padding-left: 8px;
    display: inline-block;
    width: 157px;
    vertical-align: middle;
}
.sale_bar {
    bottom: 0px;
    left: 0;
    right: 0;
    z-index:1;
    position: absolute;
}
.red_barsale {
    width: 100%;
    height: 100%;
    background:#ee1d23;
    color: #fff !important;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.8px;
    padding: 5px 9px;
    display: block;
    position: relative;
    text-align: left;
}

.main_header .list-inline .active .home_box{
    background:#03a9f5;
}

.tooltip_box .tooltiptext_box {
    width: 250px;
}
.tooltiptext_box.tooltip-top {
    left: -15px;
}

.sale_bar .disount_sec .discount_txt {
    padding: 5px 6px;
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 0;
    margin: -11px 0;
    display: inline-block;
    position: absolute;
    right: 50px;
    background: #ee1d23;
    width: 139px;
    border-radius: 4px;
    text-align: center;
}

span.left_days {
    font-size: 12px;
    text-align: center;
    display: block;
    letter-spacing: 0px;
}

.table_box tbody tr:nth-child(3) a.Red_box {
    overflow: visible;
   text-overflow: unset;
   white-space: normal;
}

.table_box tbody tr:nth-child(3) a.Red_box .green {
   overflow: hidden;
   text-overflow: ellipsis;
   white-space: nowrap;
   display: block;
}

.form-control {
    font-size: 20px;
}

.react-multi-carousel-item{
    cursor:pointer;
}


.right_user .dropdown-menu {
    width: 290px !important;
    z-index: 999999999;
}

.right_user ul.dropdown-menu.Sub_menu.show{
    transform: translate3d(0px, 60px, 0px) !important;
}

.below_hombann {
    clear: both;
    position: relative;
    margin-top: 15px;
}

.modal-open .modal {
    background: rgb(0 0 0 / 61%) !important;
}
div#myModalGeo .modal-header {
    background-color: rgb(140,158,255);
    color: rgba(0,0,0,0.87);
}
div#myModalGeo .modal-header h4.modal-title {
    font-size: 20px;
    font-weight: normal;
}
div#myModalGeo .modal-header button.close {
    color: rgba(0,0,0,0.87) !important;
    background: none;
    opacity: 1;
    text-shadow: none;
    font-size: 28px;
    line-height: normal;
    position: absolute;
    right: 10px;
    top: 0px;
}
div#myModalGeo .modal-body .md-list-item-text h3 {
    font-size: 16px;
    font-weight: normal;
    margin-bottom: 13px;
}


::-webkit-scrollbar {
    width: 8px;
    height:8px;
}
::-webkit-scrollbar-track {
    background: #c7c7c7;
}
::-webkit-scrollbar-thumb {
    background: #acabab;
}
::-webkit-scrollbar-thumb:hover {
    background: #9b9b9b;
}   

form.navbar-form.dataClass >.input-group.add-on .input-group-btn.input-group-addon.dataWidth {
    position: absolute;
    right: 4.5px !important;
    bottom: 2px;
    top: -1px;
    z-index: 9999;
}
.tooltip_box:hover .tooltiptext_box{
    z-index: 9999999 !important;
}

.intr_pges h2.shop_head {
    width: 100% !important;
    color: #232f3e;
    padding-left: 115px !important;
    padding-right: 115px !important;
    margin: 0 auto;
    max-width: 100% !important;
}

@media (max-width: 1199px){
.red_barsale {
    font-size: 12px;
}
.sale_bar .disount_sec .discount_txt {
    padding: 4px 10px;
    font-size: 20px;
    letter-spacing: 3px;
    margin: -10px 0;
}

}
div#bs-example-navbar-collapse-2 {
    display: block!important;
}
@media (max-width:767px){
    .Top_Bar .button_box .addbuttonclasshere{
        max-width: 130px;
    }
    .Shopping_Cart {
        top: 40%!important;
    }
    .intr_pges h2.shop_head {
        margin: 0 auto
    }
    .form-control {
        font-size: 11px !important ;
    }
    form.navbar-form.dataClass >.input-group.add-on .input-group-btn.input-group-addon.dataWidth {
        top: 10px;
        bottom: 0px;
    }
    div#bs-example-navbar-collapse-2 li.drop_Box {
    width: 100% !important;
    max-width: 100%;
    display:  block;
}
div#bs-example-navbar-collapse-2 {
    display: block;
    margin-left: -20px;
    margin-right: -20px;
}

    
}
@media (max-width: 767px){
    .main_header .navbar-header {
    background: #fff;
    left: 0;
    right: 0;
    z-index: 99;
    position: absolute;
    justify-content: center;
   
    }
    form.navbar-form.dataClass >.input-group.add-on .input-group-btn.input-group-addon.dataWidth {
        right: 0px !important;
    }
    .advancesearchmobile{
        display: none;
    }
    .Form_holder form.navbar-form {
        position: absolute;
        z-index: 13;
        background: #fff;
        left: -25px;
        right: -25px;
    }
    .top_banner {
        margin-top: 159px;
    }
    .companyText.clearfix h3 {
    font-size: 15px;
}
.box_row {
    display: table !important;
    width: 100% !important;
    margin: 0 !important;
}
.box_row .box_col{
    padding: 0 !important;  
}
.grid_icon{
    padding-top:0px;
}
.grid_icon a[title="Grid"] {
    padding-left: 5px;
}
.ReadMore_box .table td{
vertical-align: middle;
}

.intr_pges h2.shop_head{
    padding-left: 0 !important;
    padding-right: 0 !important;
}
.sub_back.text-left.intr_pges a.land_back {
    top: 65px;
    left: 10px;
    color: #232f3e ;
}

}

@media (max-width: 991px){
.logged_btn .user_txt {
    max-width: 124px;
    font-size: 10px;
    font-weight: normal;
    letter-spacing: 0.3px;
    padding-left: 5px;
}

}


.social_box {
    display: flex;
    justify-content: center;
}



@media (max-width:991px){
    .social_box .soacil_link{
        margin-right: 10px !important;
    }
    .social_box .soacil_link:last-child {
        margin-right: 0px;
    }
}

@media (max-width: 767px){
    .Top_Bar .navbar-toggle {
        left: 205px !important;
    }
    .button_box .coman_btn {
      text-overflow: ellipsis;
     overflow: hidden;
     padding-right: 18px !important;

    }

    .Top_Bar .button_box .coman_btn{
     max-width: 95px;
    }
    .Top_Bar .button_box .login_user_btn {
        max-width: 160px !important;
    }
    .Form_holder form.navbar-form.search_fixed {
        top: 91px !important;
    }

.right_user span.user_name{
    z-index: 4 !important;
}


.drop_Box .dropdown-backdrop{
    display:none;
}
.cate_sec.fixed {
    right: 0px;
    left: 0;
    max-width: 1154px;
    width: 100%;
    top: 104px;
}
.Form_fixed.Form_holder {
    position: static;
}
.Form_fixed.Form_holder form.navbar-form {
    top: 61px !important;
    position: fixed;
    left: 0!important;
    right: 0!important;
    width: 100% !important;
}
}

/* Home page grid box issue   */
.Grid_Box .Grid_Description .Descript_row{
    min-height: 100px;
}

/*  Faq popup issue */

md-backdrop.md-dialog-backdrop {
    z-index: 1 !important;
}



/* for page details page please find and remove this classes in media   */
@media (max-width: 767px){
.main_content {
   margin-top: 160px;
}


}
@media (max-width: 425px){
.main_content {
  margin-top: 215px; 
}
.Top_Bar .button_box .coman_btn {
        max-width:78px!important;
        padding: 10px 4px;
    }
    .form-control {
        font-size : 11px !important;
    }
    .Top_Bar .button_box .login_btn{
        max-width:78px;
    }
    .Top_Bar .navbar-toggle {
        left: 171px !important;
        padding: 9px 4px;
    }
    .navbar-toggle .icon-bar {
        width: 20px;
    }
}

.tooltip_box:hover .tooltiptext_box{
        visibility: visible;
}

.gap_10>div {
    padding: 0 5px;
}
.gap_10 {
    margin-left: -5px;
    margin-right: -5px;
}

.pagination_box .pagination>li.active {
    background: none;

}
.pagination_box .pagination li:nth-child(2) a, .pagination_box .pagination li:first-child a, .pagination_box .pagination li:nth-last-child(2) a, .pagination_box .pagination li:last-child a {
    font-size: 0px;
    border-width: 2px;
    text-align: center !important;
    border-color: #232f3e !important;
    background-size: 28px !important;
    background-position: center !important ;
    box-shadow: 0 0 7px rgb(0 0 0 / 19%);
}
/* ===== Media CSS Start here ===== */

@media (max-width:1500px){
.Grid_Description{
    height:auto
 }
}

@media (min-width:992px){
    



}


@media (max-width:1199px){
    .blue_btn{
        max-width:125px;
    }
    .pagination_box {
        padding: 10px 0;
    }
    .button_box .coman_btn {
        padding:10px 5px;
        font-size: 11px;
    }
    .box_row .box_col{
        text-align:center;
    }
    .Grid.List:before{
        display:none;
    }
}

@media (max-width:991px){
    .button_box .coman_btn{
        height:34px;
        font-size: 10px;
    }
    .Sub_menu .tooltip_box .tooltiptext_box {
        left: 5px;
    }
    .Form_box .button_box .coman_btn, .Form_box .form-control {
        font-size: 12px;
    }
    .blue_btn {
        max-width: 98px;
    }
    .tooltip_box .tooltiptext_box::after {
        left: 0;
        right: 0;
        max-width: 5px;
        margin: 0 auto;
    }
    .dark_green .tooltiptext_box {
        left: auto !important;
        right: -10px;
    }
    .Red_box .tooltiptext_box.tooltip-top {
        left: auto !important;
        right: -25px;
    }
    .Ptxt_box .tooltiptext_box.tooltip-top{
        left:0 !important;
    }
    .Red_box .tooltiptext_box::after{
        left: auto;
        right: 25px;
    }
    .dark_green .tooltiptext_box::after{
        right: 10px;
        margin: 0 0 0 auto;
    }
    .Ptxt_box .tooltip_box .tooltiptext_box::after {
        left: 10px;
        margin: 0 auto 0 0;
    }
    .tooltip_box .tooltiptext_box{
        left: -75px;
    }
    .desc_section .Grid_Description .tooltip_box img {
        max-width: 30px;
    }
    .tooltiptext_box.tooltip-top {
        left: -100px !important;
    }
    .Form_box .button_box .coman_btn {
        padding: 17px 5px;
    }
    .tooltip_box .tooltip-bottom::after {
        left: auto;
        right:25px;
    }
    .tooltip_box .tooltip-bottom {
        left: auto;
        right: 0;
    }
    /*-----*/
    .modal-lg {
        max-width: 725px;
    }
}

@media (max-width:767px){
    .main_content {
        margin-top: 160px;
    }
    .blue_btn {
        max-width: 120px;
    }
    .navbar-form .search_btn{
        top:5px;
    }
    .Form_box .button_box .coman_btn, .Form_box .form-control {
        margin: 5px 0;
    }
    .pagination_box .prev .page-link, .pagination_box .next .page-link {
        padding: 16px;
        margin: 0 8px;
        background-size: 13px !important;
    }
    .pagination_box .next .page-link{
        background-size: 13px !important;
    }
    .pagination_box {
        padding: 0 10px;
    }
    .pagination_box .pagination li a{
        font-size:13px;
        padding: 7px 8px;
    }
    .pagination_box .first .page-link, .pagination_box .last .page-link{
        padding: 9px 15px;
        font-size: 12px;
    }
    .navbar-toggle {
        margin: 13px 10px;
        border:none;
        border-radius:5px;
    }
    .navbar-toggle .icon-bar{
        background: #636363;
    }
    .tooltip_box .tooltip-bottom {
        left: 0 !important;
    }
    .tooltip_box .tooltip-bottom::after {
        left: 10px;
        right: auto;
    }
/*-----*/
    .collapse_menu {
        margin-bottom: 5px;
    }
    .Categories_Popup .modal-title {
        font-size: 20px;
        padding: 5px 0;
    }
    .Categories_Popup .modal-header .close {
        font-size: 20px;
    }
    .panel-title>a {
        padding: 20px 15px;
    }
    .panel-title {
        font-size: 14px;
    }
    .shadow_box a{
        padding: 15px;
        margin: 4px 0;
        font-size: 12px;
    }
    .panel-body{
        padding:5px;
    }
    .Categories_Popup .modal-body {
        padding: 15px;
    }

.Form_holder form.navbar-form.search_fixed {
    position: fixed;
    top: 95px;
    z-index: 999;
    background: #fff;
    left: 0;
    right: 0;
    padding: 10px 14px;
}

.Example_Nav2 {
    height: auto !important;
    min-height: 20px;
    display: block;
}
.Example_Nav2 >.list-inline> li:first-child {
    display: none !important;
}
.Example_Nav2 li a {
    display: block;
    padding: 6px 0 !important;
}
.main_header .navbar-toggle {
    display: none !important;
}


}

@media (max-width:425px){
    .pagination_box .first .page-link, .pagination_box .last .page-link {
        padding: 9px 12px;
        font-size: 10px;
    }
    .pagination_box .prev .page-link, .pagination_box .next .page-link {
        padding: 15px;
        margin: 0 2px;
    }
    .main_content {
        margin-top:0px;
    }

    .sale_bar .disount_sec .discount_txt {
        right: 32px;
        width: 106px;
    }

}

@media (max-width:767px){
.main_header .list-inline>li {
    display: block;
}
.right_user .nav {
    margin-left: -5px;
    margin-right: -5px;
  }
}
@media (max-width:425px){

.right_user .nav li {
    width: 50%;
    float: left;
    }
}

@media (max-width: 767px){
    .main_header .navbar-header.fixed_head2 {
        background: #fff;
        position: fixed;
        left: 0;
        right: 0;
        z-index: 21;
        padding: 3px 15px;
        top: 40px !important;
    }
}

/* ===== Media CSS Closed here ===== */

.slider_wrapper .slider_body .slick-initialized .slick-slide {
    padding: 0px;
}

label{
    padding:0px;
    margin-bottom: 7px;
}