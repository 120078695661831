
.lp_shop .recommend_txt .recommend-icon {
    margin-top: -16%;
}
.cityBox {
    width: 25% !important;

}
.shopBackBtn {
    padding: 81px 0px 0px 109px!important;
}
.cityBody {
    height: 515px;
    padding-top: 10px;
}
.cityShopHead {
    padding-bottom: 38px;
}   
.cityBox_1 {
    width: 25% !important;
}
.cityIcon {
    height: auto;
    -webkit-text-fill-color: #7c8ca3;
    flex-direction: column;
}
.cityLogo {
    margin: 14px 0 -75px 20%;
}
.dart {
    max-width: 18%;
    margin: 0px 24px 75px -66px;
}
.dartt {
    max-width: 21%;
    margin: 0px 24px 75px -69px;
}

.numberCity {
    color: white;
    font-size: 22px;
}
.cityIconDart {
    height: auto;
    -webkit-text-fill-color: #7c8ca3;
    flex-direction: column;
    padding-bottom: 7px;
}
.heartIcon {
    color: #7c8ca3 !important;
    font-size: 100px;
    margin-top: -12px;

}
.dartIcon {
    padding-top: 30px;
}
.boldHeading {
    font-weight: bold;
    font-size: 27px;
}
.NewBox {
    background-color: #84644c;
    padding: 0px !important;
    width: auto;
    height: 229px;
}

@media (max-width: 767px){
.cityLogo {
    margin: auto;
}
.shopBackBtn {
    padding: 92px 0px 0px 0px!important;
}
.cityShopHead {
    padding-bottom: 15px;
} 
.boldHeading {
    margin-bottom: 12px;
    margin-top: 4px;
}
.cityBox {
    width: 50% !important;
    padding-right: calc(var(--bs-gutter-x) * .2) !important;
    padding-left: calc(var(--bs-gutter-x) * .4) !important;
}
.NewBox {
    width: auto;
    height: 210px;
}
.cityBox_1 {
    width: 50% !important;
     padding-right: calc(var(--bs-gutter-x) * .2) !important;
    padding-left: calc(var(--bs-gutter-x) * .4) !important;
}
.dart {
    max-width: 23%;
    margin: 0px 24px 75px -61px;
}
.dartt {
    max-width: 28%;
    margin: 0px 24px 75px -64px;
}
.cityIconDart {
    padding-bottom: 0px;
}
.cityBody {
    height: 845px;
}
}

@media (min-width:1224px) and (max-width:1980px){
 .mrgin_top{
 margin-top:-45px !important;
 }   
 
 

}