
/* section above buy , sell, action option css start here */
.below_hombann {
    padding: 15px 0;
    box-sizing: border-box;
    background: #abbbc4;
    box-shadow: 0 -2px 6px rgba(0, 0, 0, 0.17);
}

.SocialLogo{
    width: 20%!important;
}
.Socialright{
    width: 39%!important;
}
.SocialBtn{
   width: 41% !important; 
}
.first_slogn {
    position: relative;
    width: 50%
}
.footerContainer {
    width: ;
}
.sec_slogn {
    position: relative;
    width: 50%
}

.below_hombann .slogn_txt {

    font-size: 18px;
    font-weight: bold;
    color: #232f3e;
    text-transform: uppercase;
    text-shadow: 1px 1px 0px rgba(0, 0, 0, 0.16);
    padding: 12px 19px;
}
.first_slogn:after {
    content: '2';
    font-size: 0;
    position: absolute;
    top: 0;
    right: -1px;
    bottom: 0;
    width: 2px;
    background: #232f3e;
}

.first_slogndata{
    text-align: right;
}
/* =========== Footer sec css started here =========== */
.footer_mainlink .container {
    max-width: 804px;
}

.footer_mainlink {
    background: #84644c;
    text-align: center;
    padding: 30px 0 30px;
}
.main_fea {
    font-size: 28.469pt;
    font-weight: 400;
    color: #fff;
    padding: 0 20px;
    border-left: 1px solid #07b1c7;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    line-height: 30px;
    display: inline-block;
    margin-bottom: 25px;
    font-family: 'Play', sans-serif;
}
.foot_txt {
    color: #fff;
    font-size: 12pt;
    margin-top: -20px;
    font-family: 'Play', sans-serif;
    margin-bottom: 0;
}
.footer {
    background: #abbbc4;
    padding: 20px 0;
    color: #fff;
    border-top: 1px solid #28b5f6;
}

.footer_new {
    text-transform: uppercase;
    background: #84644c;
    padding: 20px 0;
    color: #fff;
    border-top: 1px solid #28b5f6;
}

.foot_link {
    font-size: 10pt;
    color: #232f3e;
    border-left: 2px solid #232f3e;
    display: inline-block;
    line-height: 11px;
    padding: 0 5px;
    float: left;
}
.linkbox .foot_link:first-child {
    border-left: 0px;
    padding-left: 0px;
}
.linkbox .foot_link:last-child {
    padding-right: 0px;
}
.linkbox .linkbox:hover {
    font-weight: 700;
}
.soacil_link {
    height: 20px;
    width: 20px;
}
.soacil_link {
    height: 24px;
    width: 24px;
    display: inline-block;
    border-radius: 2px;
    font-size: 17px;
    margin-right: 9.5px;
}

.soacil_link_new {
    height: 24px;
    width: 72px;
    display: inline-block;
    background: #fff;
    border-radius: 2px;
    font-size: 17px;
    margin-right: 9.5px;
}
.soacil_link:last-child {
    margin-right: 0px;
}
.foot_box {
    font-size: 10pt;
}
.foot_box:after {
    content: '2';
    font-size: 0px;
    display: block;
    width: 100%;
    height: 0px;
    clear: both;
}
.foot_box.linkbox {
    padding-top: 6px;
    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;
    color: #232f3e !important;
}
.copy_text {
    padding-top: 3px;
    font-size: 14px;
    font-weight: bold;
    color: #232f3e;
    text-transform: uppercase;
    margin-left: 33px;
}
.bdr_nn {
    border: none;
}
.social_box {
    margin-right: -15%;
}

.footer_mainlink .main_fea {
    border-left: none;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    position: relative;
}
.below_hombann .col-xs-6 {
    width: 50%;
}
.footer_mainlink .main_fea:after {
    content: "2";
    position: absolute;
    width: 1px;
    height: 100%;
    left: 0;
    top: 0;
    font-size: 0;
    background: -webkit-linear-gradient(#083673,#07b1c7,#083673);
}
.footer_mainlink .main_fea.bdr_nn:after, .footer_mainlink .main_fea:nth-child(5):after {
    display: none;
}
div#exampleModal .modal-header{
    text-align:left;
}
.modal-header .modal-title {
    display: inline-block;
}
div#exampleModal .modal-body {
    text-align: center;
    padding: 22px 15px;
}

/* =========== Footer sec css closed here =========== */


@media (max-width: 575px){
    .sidebar_head {
        font-size: 14px;
    }
    .tooltip_box .tooltiptext_box {
        left: -15px;
        max-width: 225px;
    }
    .ProductContent .tooltiptext_box.tooltip-top {
        left: -60px!important;
    }
    .ProductContent .table_box .tooltiptext_box.tooltip-top, .ProductContent .pro_sidebar .tooltiptext_box.tooltip-top {
        left: 0px!important;
    }
    .panel-body .list-unstyled .pull-right {
        float: none !important;
        margin-top: 10px;
    }
    .pull-right .tooltip_box .tooltiptext_box {
        left: 0;
    }
}


@media (min-width:767px) {
.footer_mainlink >div >a:nth-child(5) {
    border: none;
}

.social_con {
    padding: 0px 1px;
}
.SocialBtn {
    width: auto;
}
}


@media (min-width:768px) and (max-width:991px){
    .main_fea{
            margin-bottom: 15px;
    }
    .foot_txt{
            margin-top: 0px;    
    }
    
}
@media (max-width:991px) {

.social_box .soacil_link {
    margin-right: 0px;
}
}

@media (max-width:767px) {

.below_hombann .slogn_txt {
    padding: 12px 5px;
}
.footer_mainlink .container .main_fea:first-child {
    border: none;
}
.footer_mainlink .main_fea {
    font-size: 17.469pt;
    padding: 0 6px;
    line-height: 20px;
    margin-bottom: 10px;
}
.footer_mainlink .foot_txt {
    margin-top: 0;
}
.foot_box {
    padding-bottom: 4px;
    text-align: center;
    font-size: 10px !important;
    font-weight: 600 !important;
    text-transform: none !important;
}
.linkbox .foot_link {
    float: none;
}
.social_box {
    padding: 5px 0;
    margin-right: 0px;
}
.footer_mainlink .container {
    max-width: 440px;
}
.copy_text {
    padding-top: 6px;
    margin: 0;
    font-size: 14px !important;
}

}