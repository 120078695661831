
.lp_shop .recommend_txt .recommend-icon {
    margin-top: -16%;
}
.provinceBox {
    min-height: 210px;
    background-color: #84644c;
    padding: 0px !important;
    width: 220px;
    height: 210px;
}
.provinceHead {
    margin-top: -11px!important;
}
.provinceIcon {
    height: auto;
    -webkit-text-fill-color: #7c8ca3;
    flex-direction: column;
}
.popularBorder {
   margin: 35px auto 11px
}
.provinceLogo {
    margin: 14px 0 -75px 20%;
}
.provinceContainer {
    padding-top: 42px;
    width: 25% !important;
}




@media (min-width:1224px) and (max-width:1980px){
 .mrgin_top{
 margin-top:-45px;
 }   

}
@media (max-width:991px) {
.provinceBox{
    width: auto;
    height: 270px;
    margin-bottom: -18px;
}
.provinceContainer{
    width: 50%!important;
}
.provinceLogo {
    margin: 14px 0 -75px 12%;
}
.dartt {
    max-width: 32%;
    }
.dart{
    max-width: 30%;
}
a.GBX_logo{
    margin: 0 39%;
}

}
@media (max-width: 767px){
    .dart{
    max-width: 23%;
}
.dartt {
    max-width: 28%;
    }
.shop_head {
    width: 80%;
    max-width: 58%;
    margin-left: 30%;
}
.provinceContainer {
    padding-top: 0px;
    width: 50% !important;
}
.provinceLogo {
    margin: auto;
}
.provinceBox {
    width: auto;
    height: 210px;
    margin-left: -6px;
    margin-right: -6px;
    margin-bottom: 12px;
}
}