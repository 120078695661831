.header_fixed {
    position: fixed;
    width: 100%;
    z-index:9999999;
    top: 0px;
}
.main_header header {
    height: 60px;
    background: #abbbc4;
    box-shadow: 1px 0 6px rgba(0,0,0,.2);
}
.dealsLine {
    padding-right: 52px!important;
}
.dealsDropDown {
    font-weight: bold!important;
}
.main_header .Sub_menu li {
    display: block!important;
    padding: 0!important;
    width: 100%!important;
}
 .advancesearchmobile{
         text-align: center;
        padding-left: 38%;
        line-height: 1;
        margin: -46px;
    }

.tooltip{
  opacity: 1!important;
}

.caret {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 2px;
    vertical-align: middle;
}

.dropdown-menu{
    min-width: 263px;
}

.Header_add .header_fixed {
    top:0px !important;
}
.main_header .logo_box {
    padding: 0px 0;
}
.main_header .logo_box img {
    max-width: 101px;
    max-height: 59px;
}
.main_header .list-inline li a {
    font-size: 12.5px;
    font-weight:bold;
    color: #2d2828;
    line-height: 16.24px;
    letter-spacing: 0.4px;
    text-transform: uppercase;
    padding: 11px 0;
}
form.navbar-form.dataClass .input-group.add-on input#srch-term {
    margin: 0px;
}

.home_box {
    width: 63px;
    display: block;
    height: 60px;
    box-shadow: 0 2px 4px rgba(0, 27, 63, 0.33);
    text-align: center;
    background: #84644c;
    /*background: linear-gradient(#00d0bc, #009f90);
    background: -webkit-linear-gradient(#00d0bc, #009f90);
    background: -ms-linear-gradient(#00d0bc, #009f90);
    background: -moz-linear-gradient(#00d0bc, #009f90);*/
    padding: 15px;
}
.home_box i {
    font-size: 27px;
    color: #abbbc4;
    line-height: 28px;
}
.home_box i:hover{
    transform: scale(1.2);
    color: #abbbc4;
    line-height: 28px;
}
.main_header .list-unstyled .drop_Box {
    padding: 15px;
}
.main_header .list-unstyled li .active {
    color: #07326c;
    font-weight: 600;
}
.main_header .list-inline li a:hover {
    color: #03a9f5;
}
.main_header .Sub_menu  li {
    display: block !important;
    padding: 0 !important;
    width: 100% !important;
}
.triangle {
    width: 0;
    height: 0;
    border-left: 9px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #fff;
    border-radius: 2px;
    position: absolute;
    top: -9px;
    left: 10px;
}
.dropdown_box:hover .dropdown-menu {
    display: block;
}
.main_header .Sub_menu  li a{
    font-size: 13.17px;
    color: #596068;
    line-height: 19.24px;
    letter-spacing: 0.4px;
    font-weight: 500;
    padding: 10px;
    background: none !important;
    border-bottom: 1px solid #eee;
    text-transform: none;
}
.main_header .Sub_menu  li a:hover {
    color: #07326c;
}
.right_user .nav {
    /*display: table;
    width: 100%;*/
    justify-content: space-between;
}
.right_user .nav li {
   /* text-align: left;
    display: table-cell;
    padding: 0 5px;
    width: 50%;*/
}
.right_user .nav li .dropdown-toggle {
    text-align: left;
    font-size: 14px;
    letter-spacing: 0.6px;
    line-height: 19.24px;
    color: #5d6064;
    background: none !important;
    border: none;
    font-weight: 500;
    padding: 11px 15px 10px;
    display: block;
    min-width: 150px;
}
.right_user .dropdown-menu{
    width: 240px;
}
a[title="logo"] {
    display: block;
}

.main_header .list-inline {
    margin: 0;
}
.right_user .user_img {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.right_user .user_name {
    color: #232f3e;
    font-weight: bold;
    position: relative;
    vertical-align: middle;
    text-transform: uppercase;
}
.right_user .caret {
    position: absolute;
    right: 0;
    top: 28px;
    color: #232f3e;
    border-top: 6px dashed;
    border-right: 5px solid transparent;
    border-left: 5px solid transparent;
}
.right_user .user_name img {
    max-width: 20px;
    margin-left: 10px;
}
.OurRecomActive{
    color: #03a9f5!important;
}

.main_header .Sub_menu li a{
	display: block !important;
}
.dropdown-menu{
	padding:0px !important;
}
ul.dropdown-menu.Mobile_menu.Sub_menu {
        
}
ul.dropdown-menu.Mobile_menu.Sub_menu.show{
    position: absolute !important;
transform: translate3d(0px, 0px, 0px) !important;
top: 16px !important;
left: 6px !important;
}

/* ===== Media CSS Start here ===== */
@media (max-width:1199px){
    
}

@media (max-width:991px){
    .main_header .list-inline li a {
        font-size: 11px;
        letter-spacing: -0.2px;
    }
    
    .main_header .list-unstyled .drop_Box {
        padding: 15px 3px;
    }
    .right_user .user_img, .right_user .user_name{
        max-width: 80px;
    }
    .right_user .user_name {
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    .main_header .Sub_menu li a {
        font-size:12px;
        white-space: normal;
    }

}

@media (max-width:767px){
    .header_fixed {
        position: inherit;
        top:64px;
    }
    .headerGutter-x {
        width: 105%;
    }
    .text-end {
        padding-right: 0px!important;
    }
    .MainMenuBarRow{
        margin-right: 0px!important;
    }
    .advancesearchmobile{
        display: none;
       
    }
    .main_header header {
        height: 79px;
    }
    .main_header .list-inline li a {
        font-size: 12px;
        letter-spacing: 0.4px;
        margin:0 auto;
        text-align:center;
            border-bottom: 1px solid #e7e7e7;
    }
    .button_box .login_btn {
        max-width: 80px;
    }
    .home_box {
        width: 58px;
        height: 50px;
        padding: 10px 5px !important;
        margin-top: 5px;
        display: inline-block;
    }
    .main_header .list-unstyled .drop_Box {
        padding: 10px 3px;
    }
    .Mobile_menu .triangle{
        display:none;
    }
    .Example_Nav2 .list-unstyled {
        padding:8px 0;
    }
    .Example_Nav2 .list-unstyled li {
        display: block;
    }
    .dropdown-menu.Mobile_menu{
        position: absolute;
        margin-top:10px;
        float: none;
    }
    .right_user .nav li .dropdown-toggle{
            padding: 5px 0 5px;
    }
    .right_user .user_img, .right_user .user_name {
        max-width: 100%;
    }
    .main_header .navbar-toggle{
        margin: 13px 0;
        padding: 9px 4px;
    }
    .Example_Nav2{
        box-shadow: inset 0 1px 0 rgba(2, 2, 2, 0.1);
    }
    a.menu_header {
    padding: 19px 5px;
    max-width: 100px;
    text-align: center;
    line-height: 12px;
    text-transform: uppercase;
    color: #222;
    font-size: 12px;
    font-weight: 700;
   display: block;
    margin: 0 auto;
}
.main_header .navbar-header.fixed_head2 {
    background: #fff;
    position: fixed;
    left: 0;
    right: 0;
    z-index: 999999;
    padding: 3px 15px;
}

.right_user .nav li:first-child {
    border-right: 1px solid #e7e7e7;
}
.right_user .nav li:last-child{
    padding-right:0px !important;
}
.Form_holder .form-control {
    height: 32px !important;
}
.navbar-form .search_btn {
    top: 0px;
    height: 32px;
}
.Form_box .navbar-form .input-group-addon {
    border: none;
    background: none;
    margin-left: -1px;
}
.Form_box .button_box .coman_btn, .Form_box .form-control{
        margin: 2px 0;
}
.box_row .box_col i {
    font-size: 12px;
}
.Form_box {
    padding: 0px 0px 0px 23px !important;
    margin: 21px 0 0px 0px !important;
}
.navbar-form .search_btn {
    padding: 0px 15px !important;
        height: 32px !important;
}
.Form_box .button_box .coman_btn {
    padding: 11px 5px !important;
    height: auto !important;
}
form.navbar-form.dataClass .input-group.add-on {
    display: flex;
    align-items: center;
}
form.navbar-form.dataClass .input-group.add-on input#srch-term {
    margin: 0px 51px -1px 6px !important;
}
.header_fixed .navbar-header {

    top: 0 !important;
    position: fixed;
    width: 96.5% !important;
    justify-content: center;

}
}

@media (max-width:425px){
    .right_user .nav, .right_user .nav li{
        display: block;
        width: 100%;
    }
    .right_user .nav li .dropdown-toggle{
    font-size:11px;
    }

}

/* ===== Media CSS Closed here ===== */