.ImageBanner {
    width: 100%;
    height: 260px;
    vertical-align: middle;

}
.ImageData {
  width: 380px;
  height: 150px;
}
.centeredData {
    top: 6%;
    margin: 105px 0px;
    color: #fff;
    position: absolute;
    text-align: center;
    width: 100%;
    font-weight: 500;
}
.centered{
    text-align: center;
    margin: 100px 0px;
    width: 100%;
    color: #fff;
    position: absolute;
    top: 13%;
    font-weight: 500;
}
.centeredMain {
    top: 0;
    color: #1baff2;
    position: absolute;
    text-align: center;
    width: 100%;
    font-weight: 700;
}
.mainLogo {
    width: 188px;
    margin-top: 20px;
    margin-bottom: 15px;
    justify-content: center;
}
.homeHeadText {
    margin: 0 0 25px!important;
}
.main_heading {
    color: #1baff2;
    font-size: 36px;
    font-weight: 600;
    color: #03a9f5;
    letter-spacing: .7px;
    line-height: 19.24px;
    text-align: center;
    margin: 0px 0 0px;
    text-shadow: 2px 2px 2px rgb(0 0 0 / 50%);
    text-transform: uppercase;
}
.col-sm-4 {
    float: left;
    position: relative;
    min-height: 1px;
    padding-left: 15px;
    padding-right: 15px;
}

.contentCookies {
    margin-bottom: 0;
    margin-top: 11px ;
}
.home_midsec {
         padding:50px 0 55px;
    /*background: url(../images/logo-bg.jpg);*/
        margin-top: 52px;
    
    }
.landing_logo {
    max-width: 226px;
    box-sizing: border-box;
    width: 100%;
}
.main_heading_landing{
    font-family: 'Play', sans-serif;
    font-size:43.469pt;
    color:#03A9F5;
    font-weight:bold;
    margin:35px 0 0;
}

.categ_img.serIMG {
    bottom: 23px;
}
.categ_img{
    max-width: 206px;
    position:relative;
    transition:ease-in-out .5s;
}
.categ_img:hover{
    transform:scale(1.3, 1.3)   
}
.categ_boxes{
    padding-top: 0px;
    visibility: hidden;
    
}
.footer_label {
    position: fixed;
    left: 0;
    opacity: 1;
    z-index: 10;
    bottom: 0%;
    width: 100%;
    background-color: #232f3e;
    color: white;
    text-align: center;
    height: 137px;
}

.disclaimerText {
    color: #21E1E1 !important;
}

.AgreeBtn {
    background-color: #3CCF4E;
    display: inline-block;
    background: solid;
    margin-bottom: 0;
    font-weight: bold !important;
    opacity: 1 !important;
    text-align: center;
    vertical-align: middle;
    touch-action: manipulation;
    cursor: pointer;
    width: 120px;
    white-space: nowrap;
    margin-left: 230px;
    font-size: 14px;
    line-height: 1.428571429;
    border-radius: 4px;
    user-select: none;

}

.btn-primary {
    background-color: #3CCF4E;
    display: inline-block;
    background: solid;
    margin-bottom: 0;
    font-weight: 400;
    opacity: 1 !important;
    text-align: center;
    vertical-align: middle;
    touch-action: manipulation;
    cursor: pointer;
    width: 120px;
    white-space: nowrap;
    margin-left: 230px;
    font-size: 14px;
    line-height: 1.428571429;
    border-radius: 4px;
    user-select: none;

}
.btn-danger {
    width: 120px;
}
@media (max-width:767px){
 .main_heding_landing {
    font-size: 27.469pt;
}
.footer_label {
    height: 245px;
    bottom: 20%;
    width: 94%;
}
.homeMainHeading {
   
}
.AgreeBtn {
    margin-left: 40px;
    margin-top: 26px;

}
.DisagreeBtn {
    margin-left: 0px;
    margin-top: 27px ;
}
.top_bannerData {
    display: none;
}
}
