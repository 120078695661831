
.Top_Bar {
    width: 1349px;
    height: 56px;
    position: relative;
    background-color: #84644c;
    padding: 10px 0;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.36);
    width: 100%;
    z-index: 9999;
    top: 0px;
}
.offset-lg-1 {
    margin-left: 0!important;
}
.button_box .login_btn {
    background: linear-gradient(#21E1E1, #232f3e);
    /*background: -webkit-linear-gradient(#00bba8, #00bfac);
    background: -ms-linear-gradient(#00bba8, #00bfac);
    background: -moz-linear-gradient(#00bba8, #00bfac);*/
    max-width: 105px;
    margin-right: 4px;
}

.logged_btn .user_txt span {
    word-break: break-all;
}
.button_box .login_btn:hover {
    background: linear-gradient(#232f3e, #21E1E1);
    background: -webkit-linear-gradient(#00a292, #00d5c0);
    background: -ms-linear-gradient(#00a292, #00d5c0);
    background: -moz-linear-gradient(#00a292, #00d5c0);
    
}
.button_box i {
    font-size: 14px;
    padding-right: 3px;
    color: #fff;
    padding: 0;
}
.circle_btn{
    padding: 5px 8px 9px;
    border-radius: 100%;
    display: block;
    height: 36px;
    width: 36px;
    text-align: center;
    background: #119ddd;
    background: linear-gradient(#119ddd, #1baff2);
    background: -webkit-linear-gradient(#119ddd, #1baff2);
    background: -ms-linear-gradient(#119ddd, #1baff2);
    background: -moz-linear-gradient(#119ddd, #1baff2);
    box-shadow: 0 1px 4px rgba(0, 27, 63, 0.30);
}
.pink_circle {
    background: #11cee7;
    background: linear-gradient(#11cee7, #0fc8e1);
    background: -webkit-linear-gradient(#11cee7, #0fc8e1);
    background: -ms-linear-gradient(#11cee7, #0fc8e1);
    background: -moz-linear-gradient(#11cee7, #0fc8e1);
}
.blue_circle {
    background: #00baa7;
    background: linear-gradient(#00baa7, #00ccb8);
    background: -webkit-linear-gradient(#00baa7, #00ccb8);
    background: -ms-linear-gradient(#00baa7, #00ccb8);
    background: -moz-linear-gradient(#00baa7, #00ccb8);
}
.circle_btn img {
    max-width: 19.5px;
}
.Top_Bar .media-body, .Top_Bar .media-left, .Top_Bar .media-right {
    display: table-cell;
    vertical-align: middle;
    text-align: left;
}
.Top_Bar .media {
    margin-top: 0;
    display: block;
}
.Top_Bar .media:hover .media-body h3 {
    color: #fff;
    font-weight: 500;
}

.media-left, .media>.pull-left {
    padding-right: 10px;
}
.media-body {
    width: 10px;
}

*, :after, :before {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: 0;

}


.container {
    margin-right: auto;
    margin-left: auto;
    padding-left: 15px;
    padding-right: 15px;
}

.Top_Bar .media h3 {
    font-size: 12px;
    margin: 0;
    color: #e8ebef;
    letter-spacing: .5px;
    line-height: 14.24px;
}
.Top_Bar .active h3{
    color: #fff;
    font-weight: 500;
}

.list-inline>li {
    display: inline-block !important;
    padding-left: 5px;
    padding-right: 5px;
}


.Top_Bar .media:after {
    content: '2';
    position: absolute;
    width: 1px;
    height: 36px;
    right: 0;
    top: 0;
    font-size: 0;
    background: -webkit-linear-gradient(#07326c, #07b1c7, #07326c);
}
.Top_Bar .media_last:after {
    display: none;
}
.head_icons .list-unstyled {
    padding: 0;
}
.head_icons .Heart_icon {
    padding-right: 22px;
}
.head_icons .Heart_icon a {
    position: relative;
    display: inline-block;
    color: #fff;
    vertical-align: middle;
    font-size: 23px;
    margin: 0 9px;
    margin-bottom: -12px;
    padding: 0 4px 0;
}
.head_icons .Heart_icon a i {
    transition: ease-in-out .5s;
}
.head_icons .noti_spn {
    position: absolute;
    top: -8px;
    background: #dc1010;
    height: 24px;
    width: 24px;
    color: #fff;
    text-align: center;
    border-radius: 50px;
    font-size: 11px;
    font-weight: 500;
    right: -12px;
    box-shadow: 0 0 24px rgba(0, 0, 0, 0.37);
    padding: 5px 3px;
}
.head_icons .circle_box {
    padding: 0 2px;
}
.circle_box .circle_btn {
    border: 3px solid white;
    background: #7c8ca3;
    box-shadow: 0 0 13px rgba(0, 14, 34, 0.75);
    font-size: 13px;
    padding: 7px 5px 8px;
    font-weight: bold;
    color: white;
    text-transform: uppercase;
    line-height: 16.24px;
    opacity: 1;
}
.circle_box .circle_btn:hover {
    border: 3px solid #7c8ca3;
    color: #21E1E1;
    opacity: 1;
}
.circle_box .active {
    border: 3px solid #21E1E1;
    opacity: 1;
    color:  #21E1E1;
}

.Top_Bar .media h3.h3_active, .Top_Bar .media:hover h3 {
    color: #10b0fb !important;
}
.circle_box .tooltip_box .tooltiptext_box {
    left: -31px;
    width: 90px;
    top:33px;
}
.circle_box .tooltip_box .tooltiptext_box::after{
    left: 0;
    right: 0;
    max-width: 5px;
    margin: 0 auto;
}
.circle_box .tooltiptext_box p {
    color: #fff !important;
}
.Top_Bar .Sub_menu a {
    display: block;
    padding: 10px;
    white-space: nowrap;
    border-bottom:1px solid #eee;
    color: #232f3e !important;
    font-size: 12.8px;
    letter-spacing: 0.3px;
	font-weight: bold !important;
}
.Top_Bar .Sub_menu a i {
    padding-right: 5px;
    font-size: 16px;
    color: #596068;
}
.Top_Bar .Sub_menu a:hover i {
    color: #07326c;
}
.Top_Bar .Sub_menu a:focus, .Top_Bar .Sub_menu a:hover {
    color: #07326c;
}
.Top_Bar .dropdown-menu.Sub_menu {
    border-bottom: 3px solid #017fb9;
    border-radius: 6px;
    margin-top: 1px;
    top: auto;
    padding:0;
    margin-left: 0;
    left: 0;
}
.dropdown-menu {
    position: absolute;
    z-index: 999999;
    display: none;
    min-width: 10rem;
    padding: -0.5rem 0;
    margin: 0;
    font-size: 1rem;
    color: #212529;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0,0,0,.15);
    }
.Top_Bar .button_box .dropdown {
    display: inline;
}
.logged_btn {
    display: block !important;
    max-width:180px;
}
.logged_btn .circle_btn {
    display: inline-block;
    background: #144f9e;
    box-shadow: 0 2px 5px rgba(0, 27, 63, 0.58);
    background: -webkit-linear-gradient(#144f9e, #144f9e);
}
.logged_btn .user_txt {
    font-size: 12px;
    font-weight: 500;
    color: #fff;
    text-transform: uppercase;
    letter-spacing: 1.2px;
    padding-left: 10px;
}

.Top_Bar .blue_btn{
    max-width: 130px;
}

.home_box i.active {
    color: #21E1E1;
    font-size: 27px;
}


.offset-lg-1 {
    margin-left: 0!important;
}


/*--newly added by Jagdish--*/
.right_user ul.nav {
    flex-wrap: nowrap;
}
.right_user ul.nav span.user_img {
    color: #5d6064;
}
.right_user ul.nav span.user_img b {
    font-weight: bolder;
}
.right_user .user_name img{
    position: relative;
    top: -2px !important;
}

.container {
    width: 1148px !important;
    max-width: 100% !important;
    padding-left: 15px !important;
    padding-right: 15px !important;
}

.Top_Bar .blue_btn::after {
    top: 10px;
    position: static;
}

.head_icons ul.list-inline {
    margin-bottom: 0;
}

/* ===== Media CSS Start here ===== */

@media (max-width:1199px){
    .button_box .login_btn{
        max-width: 80px;
        margin-right:1px;
    }
    .button_box i {
        font-size: 12px;
    }
    .Top_Bar .media-left, .Top_Bar .media>.pull-left {
        padding-right: 5px;
    }
    .Top_Bar .media h3 {
        font-size: 11px;
    }
}

@media (max-width:991px){
    .button_box .login_btn {
        max-width:72px;
        margin-right: 1px;
    }
    .circle_btn {
        padding: 6px 6px 7px 7px;
        height: 34px;
        width: 34px;
    }
    .circle_box .circle_btn{
        font-size:11px;
    }
    .Top_Bar .media h3 {
        font-size: 10px;
    }
    .head_icons .Heart_icon {
        padding-right: 8px;
    }
    .Top_Bar .Top_column{
        padding: 0 5px;
    }
    .circle_box .tooltip_box .tooltiptext_box {
        left: -60px;
    }
    .circle_box .tooltip_box .tooltiptext_box::after{
        right: 10px;
        margin: 0 0 0 auto;
    }
    
    .Top_Bar .blue_btn {
        max-width: 97px;
    }
    
    
    
}
.right_user .nav li .dropdown-toggle:after{
    display:none;
}

@media (min-width:768px) and (max-width:991px){
    .Top_column nav {
        margin-right: -90px;
    }
    .Top_Bar{
        height: 50px;
        padding: 8px 10px !important;
    }
    .Top_Bar .Top_column ul.list-inline {
        margin-bottom: 0;
    }
    .main_header .list-inline {
        display: flex;
        align-items: center;
        line-height: 8px;
    }
    .recommend_drop {
        width: auto;
    }
}


@media (min-width:768px){
    .navbar-collapse.collapse {
    display: block!important;
    height: auto!important;
    padding-bottom: 0;
    overflow: visible!important;
    
 }
 
 .Top_column .navbar-header {
    display: none;
}
.navbar-header {
    margin-right: calc(var(--bs-gutter-x) * 0) !important;
}
 
 
}

@media (max-width:767px){
    .Top_Bar {
        padding:5px 5px;
    }

.list-inline>li {
    display: inline-block;
    padding-left: 5px;
    padding-right: 5px;
}
    .circle_box .circle_btn {
        font-size: 10px;
        height: 33px;
        width: 33px;
    }
    .Top_Bar .media {
        padding: 5px;
    }
    .Top_Bar .media:after{
        display:none;
    }
    .head_icons .circle_box {
        padding: 0;
    }
    .Top_Bar .navbar-toggle {
        border: none;
        position: absolute;
        left: 203px;
    }
    .Top_Bar .navbar-header {
        position: relative;
    }
    .Example_Nav1 .row {
        margin: 0;
    }
    .Example_Nav1{
        margin-top: 5px;
    }
    .Top_Bar .navbar-toggle .icon-bar {
        background: #fff;
    }
    .Top_Bar {
        height: auto;
        margin-bottom: 0;
    }
	.Top_Bar .col-sm-3.col-xs-5.text-right.Top_column {
		width: 82px;
		order: -1;
	}
	.Top_Bar .Top_column:first-child {
		width: calc(100vw - 112px);
		order: -1;
	}
	
	body .main_content {
		margin-top: 0 !important;
	}
.right_user ul.nav li.dropdown {
    width: 50%;
}
body .right_user .nav li:last-child{
	padding-left: 5px !important;
}
body .right_user ul.nav li.dropdown {
    padding-right: 10px !important;
}
body  .right_user .caret{
	right: 7px;
}

body .right_user .nav {
    margin: 0 !important;
}
	.Top_Bar .button_box .coman_btn{
		max-width: 105px;
		padding: 10px 4px !important;
	}

.Top_Bar .navbar-toggle {
    background: #fff !important;
    width: 20px;
    height: 1px !important;
    display: block;
    margin: 5px 0;
}
.Top_Bar .navbar-toggle{
	position: absolute;
    top: -29px !important;
    margin: 0;
    background: none;
    width: 22px;
    padding: 0 !important;
    left: 227px !important;
	background: none !important;
	height: auto !important;
}

.navbar-header {
    margin-right: calc(var(--bs-gutter-x) * 0) !important;
}
.head_icons  li.circle_box:last-child {
    margin-left: 3px;
}	
.Example_Nav1 div {
    padding: 0;
}
.Top_Bar .navbar-toggle .icon-bar {
    margin: 5px 0 !important;
    background: #fff;
    width: 22px;
    display: block;
    height: 1px !important;
}
.Example_Nav1 {
    margin-top: 0;
}



}

@media (max-width:425px){
.head_icons .Heart_icon a {
    font-size: 20px;
    margin: 0 1px 0 0;
    margin-bottom: -12px;
}
.Top_Bar .button_box a.btn.login_btn.coman_btn {
    max-width: 70px;
}
.navbar-header {
    margin-right: calc(var(--bs-gutter-x) * 0) !important;
}
.Top_Bar .navbar-toggle{
	left: 187px !important;
}
	
}


/* ===== Media CSS Closed here ===== */
